import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Circle,
  Polygon,
  Tooltip,
  Polyline,
  Marker,
  ZoomControl,
  LayersControl
} from 'react-leaflet'
import ReactHtmlParser from 'react-html-parser'
import { EditControl } from 'react-leaflet-draw'
import { addGeoFence, toggleAllGeofences, updateGeoFence } from './../../Actions/Devices'
import Layout from './../../Layout'
import L from 'leaflet'
import { MapPagination } from './../../Components/Maps/MapPagination'
import { convertSpeedUnits, errorHandler } from '../../Helpers'
import GeofenceModal from '../../Components/Geofence/GeofenceModal'
import TrackModal from '../../Components/Geofence/KMLTracksModal'
// import CustomMarker from './../../Components/Maps/CustomMarker'
import { MapTooltip } from './../../Components/Maps/MapTooltip'
import { MapTileLayers } from '../../Reducers/Maps'
import moment from "moment"
import instance from '../../axios'
import Notifications from 'react-notification-system-redux'
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';

//import { checkPrivileges } from '../../Helpers/index'
let geo = {}

const Geofences = (props) => {
  const [state, setState] = useState({
    minZoom: 3,
    lat: 25.097919,
    lng: 55.236683,
    zoom: 3,
    bounds: null,
    update: true,
    EditControlObject: {},
    newVector: {},
    updatedGeo: {},
    DrawControl: {},
    Drawing: '',
    EditMode: false,
    fitBounds: {},
    resourceList: 'geofences',
    linkResource: false,
    initFetch: false,
    currentPage: 1,
    pagesize: 50,
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 1,
      currentDevice: props.deviceId,
      hasNext: true,
      searchText: ''
    },
    geofenceModal: false,
    geofenceModalData: null,
    geofenceModalMaoData: {},
    currentLayer: null,
    kmlDataForm: {
      name: "",
      calendarId: 0,
      description: "",
      area: "",
      attributes: {
        color: "",
        imported: false,
        type: "kml"
      }
    },
    disableSubmit: true,
    markers: [],
    searchText:""
  })
const [isDeletedGeo,setIsDeletedGeo]=useState(false)
const [isCallBounds,setisCallBounds]=useState(false)
// const [newBounds,setnewBounds]=useState(null)


  const mapRef = useRef(null)
  // const map = mapRef.current;
  let reactECref = useRef({})

  // new work start from here 
 const handleScrollGeofencesIndex = (values) => {
    const { scrollTop, clientHeight, scrollHeight,zoomLevel } = values;
    const { hasNext } = state.itemPagination;
    let threshold;
    if (zoomLevel === 1) {
      threshold = 26; 
    } else if (zoomLevel < 1) {
      threshold = 26 -  zoomLevel
    } else {
      threshold = 26 +  zoomLevel; 
    }
    if (hasNext) {
      if (Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight)-threshold) {
          setState((prevState)=>{
            const nextPage=prevState.itemPagination.currentPage+1;
            return {
              ...prevState,
              itemPagination:{
                ...prevState.itemPagination,
                currentPage:nextPage
              }
          }})
        }
    }
  };

  useEffect(()=>{
    fetchData(props.logInUser.id,state.itemPagination.currentPage,50, false,state.isAllGeofencesCall);

  },[props.geoFence,state.isAllGeofencesCall,state.resourceList,state.itemPagination.currentPage])

  useEffect(() => {
    fetchData(props.logInUser.id,1,50, true,state.isAllGeofencesCall);
}, [state.searchText])

  // new work end here 
  useEffect(() => {
    return () => {
      setState((prevState) => ({
        ...prevState,
        minZoom: 3,
        lat: 25.097919,
        lng: 55.236683,
        zoom: 3,
        bounds: null,
        update: true,
        EditControlObject: {},
        newVector: {},
        updatedGeo: {},
        DrawControl: {},
        Drawing: '',
        EditMode: false,
        fitBounds: {},

        initFetch: false,
        currentPage: 1,
        pagesize: 50,
        itemPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          currentDevice: props.deviceId,
          hasNext: true,
          searchText: ''
        },
        geofenceModal: false,
        geofenceModalData: null,
        geofenceModalMaoData: {},
        enableEdit: false,
        isAllGeofencesCall:false
      }))
      if(mapRef.current){
        props.dispatch(toggleAllGeofences(false, "geofences", props.geoFence));
        mapRef.current=null
      }

    }
  }, [])

  useEffect(() => {
    if (props.ServerSetting && props.ServerSetting.zoom) {
      setState((prevState) => ({
        ...prevState,
        zoom: props.ServerSetting.zoom,
        lat: props.ServerSetting.latitude,
        lng: props.ServerSetting.longitude
      }))
    }
    if (props.logInUser && props.logInUser.zoom) {
      setState((prevState) => ({
        ...prevState,
        zoom: props.logInUser.zoom,
        lat: props.logInUser.latitude,
        lng: props.logInUser.longitude
      }))
    }
    if (
      props.logInUser &&
      props.logInUser.id &&
      props.geoFence.length &&
      state.initFetch === false
    ) {
      setState((prevState) => ({
        ...prevState,
        initFetch: true
      }))
    }

      props.dispatch(toggleAllGeofences(false, "geofences", props.geoFence));
    
  }, [props.logInUser.zoom, props.ServerSetting.zoom, state.initFetch])



  
  const isMapReady=(target)=>{
    if(target){

    const geocoder = L.Control.geocoder({
      position: 'topright',
      placeholder: 'Search location...',
      defaultMarkGeocode: false,
      expand: 'click',
      geocoder: L.Control.Geocoder.nominatim({
        htmlTemplate: r => {
          return `<span class="leaflet-control-geocoder-address-context">${r.display_name}<br>`;
        }
      })
    });
  
    geocoder.on('finishgeocode', e => {
      e.target._alts.childNodes.forEach(el => (el.innerHTML = 'hello'));
    });
  
    geocoder.on('markgeocode', e => {
      target.fitBounds([[e.geocode.center.lat, e.geocode.center.lng]]);
      setState((prevState) => ({
        ...prevState,
        pointer: (
          <Marker
            position={[e.geocode.center.lat, e.geocode.center.lng]}
            icon={L.icon({
              iconUrl: '/assets/images/location-pin.svg',
              iconSize: [79, 64],
              iconAnchor: [20, 64]
            })}
          />
        )
      }))
  
  
      if (props.getPostion && target) {
        props.getPostion(e.geocode.center, target.getZoom());
      }
    });
  
    geocoder.addTo(target)
  }

  }

  // showUnits
  useEffect(() => {
    const updateMarkers = () => {
      const { deviceRelatedData, devices, trailers, drivers, trackId, themecolors, logInUser, translate } = props;

      if (deviceRelatedData && Object.keys(deviceRelatedData).length) {
        const newMarkers = [];

        devices.data &&
          devices.data.forEach((deviceData) => {
            const position = deviceRelatedData[deviceData.id];
            const driver =
              drivers &&
              drivers.find(
                (dr) =>
                  position &&
                  position.exists &&
                  position.driverId &&
                  position.driverId === dr.id
              );
            const trailer =
              trailers &&
              trailers.find(
                (t) =>
                  position &&
                  position.exists &&
                  position.attributes.trailerUniqueId &&
                  position.attributes.trailerUniqueId === t.uniqueId
              );

            if (position && position.exists && deviceData && deviceData.visible === true) {
              newMarkers.push(
                <Marker
                key={deviceData.id}
                position={{ lat: position.latitude, lng: position.longitude, updated: moment(position.serverTime) }}
                rotationAngle={0}
                rotationOrigin='center'
                animationTime={0.5}
                tracking={trackId}
                icon={L.divIcon({
                  iconUrl:
                    '/assets/category/default/' +
                    (deviceData.category || 'default') +
                    'top.svg',
                  iconSize: [50, 50],
                  iconAnchor: [25, 25],
                  tooltipAnchor: [0, -20],
                  className: 'custom-marker',
                  html: `<img
                    style="transform: rotate(${deviceData.course}deg)"
                      src=
                        '/assets/category/default/${deviceData.category ||
                          'default'}top.svg'
                      
                      alt=''
                    />`
                })}
                iconSize={[50, 50]}
              >
                <Tooltip direction={'top'}>
                <MapTooltip
                      trailer={trailer}
                      themecolors={themecolors}





                      position={position}
                      driver={driver}
                      device={deviceData}
                      logInUser={logInUser}
                      translate={translate}
                      // updateVisible={updateVisible}
                    />
                </Tooltip>
              </Marker>
              );
            }
          });
        setState((prevState) => ({
          ...prevState,
          markers: newMarkers
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          markers: null
        }))
      }
    };
if(mapRef.current){
  updateMarkers();
}
  }, [props.deviceRelatedData, props.devices, props.drivers, props.trailers, props.trackId])

  useEffect(()=>{
    if(isDeletedGeo){
      fetchData(props.logInUser.id,state.itemPagination.currentPage,50, false,state.isAllGeofencesCall);
}
  },[isDeletedGeo,props.geoFence])

  useEffect(()=>{
    if(state.geofenceModal){
      if(mapRef.current){
        geofenceModalData={
         center: mapRef.current?.getCenter(),
         zoom:mapRef.current?.getZoom()
        }
      }

    }
  },[state.geofenceModal])

  useEffect(()=>{
    if(isCallBounds){
      let bounds = []
      props.geoFence.map(g => {
        if (g.visible === true && state.fitBounds[g.id]) {
          bounds.push(state.fitBounds[g.id])
        }
        return null
      })
      if(mapRef.current&&bounds){
        mapRef.current?.fitBounds(bounds)
      }
      if(!bounds.length) {
        geo = Object.assign({})
      }
      setisCallBounds(false)
    }


  },[isCallBounds,state.fitBounds,props.geoFence])

  useEffect(() => {
    props.geoFence?.forEach(obj => {
      if (obj.attributes.type === 'kmlFile' && obj.visible === true) {
        const coordinates = obj?.attributes?.latlng;
        if (coordinates) {
          if (coordinates.length) {
            mapRef.current?.fitBounds(coordinates);
          }
          // setnewBounds(coordinates);
        }
      }
    });


  }, [props.geoFence]);
  
  const setEditControlValue = (obj) => {
    if (obj && obj.id) {
      setState((prevState) => ({
        ...prevState,
        EditControlObject: { ...prevState.EditControlObject, [obj.id]: obj }
      }))
    }
  }



  // const setBoundOptions = () => {
  //   return {}
  // }





  const showResources = (type) => {
    setState((prevState) => ({
      ...prevState,
      resourceList: type
    }))
    props.dispatch(toggleAllGeofences(false, "geofences", props.geoFence));

  }

  const addResource = () => {
    setState((prevState) => ({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'addResource',
      selecteditem: '',
      driverAddress: '',
      driverLat: '',
      enableEdit: false,
      driverLon: ''
    }))
  }

  const onEditResource = (item) => {
    setState((prevState) => ({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'editResource',
      selecteditem: item,
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    }))
  }

  const onLinkResource = (item) => {
    setState((prevState) => ({
      ...prevState,
      linkResource: true,
      selecteditem: item,
    }))
    props.fetchNestedItems(item.id, 1)
  }

  const onCloseResource = () => {
    setState((prevState) => ({
      ...prevState,
      linkResource: false,
    }))

    onCloseModal();
  }
  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false
    }))
  }



  const fetchMoreItems = () => {
    fetchData(props.logInUser.id,state.itemPagination.currentPage,state.pageSize, false,state.isAllGeofencesCall);

  }
const getAllGeofences=()=>{
  setState((prevState) => ({
    ...prevState,
    searchText: ""
  }))
  fetchData(props.logInUser.id,state.itemPagination.currentPage,50, false,state.isAllGeofencesCall);
}
  const searchItems = text => {
    setState((prevState) => ({
      ...prevState,
      searchText: text
    }))
  }

  const updateAndFilterUniqueGeofences=(allgeofences)=> {
    const updatedGeofences = allgeofences.map(item => {
      const matchingGeofence = props.geoFence.find(gf => gf.id === item.id);
      if (matchingGeofence) {
        const hasChanges = Object.keys(matchingGeofence).some(
          key => item[key] !== matchingGeofence[key]
        );
        return hasChanges ? { ...item, ...matchingGeofence } : item;
      }
      return item;
    });
    const uniqueGeofences = [];
    const seenIds = new Set();
  
    updatedGeofences.forEach(item => {
      if (!seenIds.has(item.id)) {
        uniqueGeofences.push(item);
        seenIds.add(item.id);
      }
    });
  
    return uniqueGeofences;
  }

 const fetchData = (userId, page = 1, perPage = 50, reset = false, isFetchAll = false) => {
    let searchText = state.searchText;
    let items = props.geoFence.filter(e =>
      e.name.toLowerCase().includes((searchText || '').toLowerCase())
    );
    const showKmlFiles=state.resourceList === 'tracks';
    const allgeofencesIsFetch = items
      .map(e => ({
        ...e,
        name: ReactHtmlParser(e.name)[0],
        description: ReactHtmlParser(e.description)[0]
      }))
  
      const filteredGeofences = showKmlFiles 
      ? allgeofencesIsFetch.filter(item=>item.type==="kmlFile")
      : allgeofencesIsFetch.filter(item => item.type !== "kmlFile");
    
    if (isFetchAll) {
      setState((prevState)=>({
        ...prevState,
        itemPagination:{
          ...prevState.itemPagination,
          items: filteredGeofences,
          total: filteredGeofences.length,
          currentPage: 1,
          hasNext: false
        }
      }))
      return;
    }
  
    const startIndex = (page - 1) *perPage;
    const endIndex = startIndex +perPage;
    const paginatedItems = filteredGeofences?.slice(startIndex, endIndex);
  
    const allgeofences =reset?paginatedItems:[...state.itemPagination.items, ...paginatedItems];
    const updatedGeofences = updateAndFilterUniqueGeofences(allgeofences);
  
    setState((prevState) => {
      const hasNext = endIndex < filteredGeofences.length;
  
      return {
        ...prevState,
        itemPagination: {
          ...prevState.itemPagination,
          items: updatedGeofences,
          total: items.length,
          currentPage: page,
          hasNext: hasNext
        }
      };
    });
  };
  

const getChild=(id)=>{

  props.fetchNestedItems(id,1)
}

  const onDelete = (id) => {
    setIsDeletedGeo(true)
    if(id){
      getChild(id)
    }
  }

let geofenceModalData={
  center:null,
  zoom:null
}


  const openGeofenceModal = async (data) => {
    await onCloseResource();
   
   
      setState((prevState) => ({
        ...prevState,
        geofenceModal: true,
        geofenceModalData: data,
        enableEdit: data && data ? true : false,
        geofenceModalMaoData:geofenceModalData
      }))
    
  }

  const closeGeofenceModal = () => {
    setState((prevState) => ({
      ...prevState,
      geofenceModal: false,
      enableEdit: false,
      geofenceModalData: null,
      geofenceModalMaoData: {}
    }))
  }

  const addShape = (type) => {
    if (state.EditMode === false) {
      if (state.DrawControl) {
        setState((prevState) => ({
          ...prevState,
          Drawing: type
        }))

        if (type === 'circle') {
          state.DrawControl._toolbars.draw._toolbarContainer.children[2].click()
        } else if (type === 'polygon') {
          state.DrawControl._toolbars.draw._toolbarContainer.children[1].click()
        } else if (type === 'polyline') {
          state.DrawControl._toolbars.draw._toolbarContainer.children[0].click()
        }

      } else {
        alert('Please finish your modification first!')
      }
    }
  }

  const save = (obj) => {
    setState((prevState) => ({
      ...prevState,
      geo: obj
    }))

    if (
      state.EditControlObject[obj.id]._toolbars.edit._actionButtons[0]
    ) {
      state.EditControlObject[
        obj.id
      ]._toolbars.edit._actionButtons[0].button.click()
    }

  }

  const enableEditMap = (id) => {
    setTimeout(() => {
      if (state.EditControlObject[id]) {
        setState((prevState) => ({
          ...prevState,
          EditMode: true
        }))
        state.EditControlObject[
          id
        ]._toolbars.edit._toolbarContainer.children[0].click()
      }
    }, 100)
  }

  const cancelMapChanges = (id) => {
    if (state.EditControlObject[id]) {
      setState((prevState) => ({
        ...prevState,
        EditMode: false
      }))

      state.EditControlObject[
        id
      ]._toolbars.edit._actionButtons[1].button.click()

    }
  }

  const _onDrawControlReady = (reactECref) => {
    setState((prevState) => ({
      ...prevState,
      DrawControl: reactECref
    }))

  }

  const _onDrawStart = (e) => {
    setState((prevState) => ({
      ...prevState,
      Drawing: e.layerType
    }))
  }

  const _onDrawStop = (e) => {
    let currentShap = {}
    Object.keys(e.target._targets).map(id => {
      if (parseInt(id) !== e.target._containerId) {
        currentShap = e.target._targets[id]
      }
      return null
    })

    if (Object.keys(currentShap).length) {
      if (currentShap.options.newShap) {
        if (state.Drawing === 'circle') {
          setState((prevState) => ({
            ...prevState,
            Drawing: e.layerType
          }))
          setState((prevState) => {
            let updatedCircle = {
              ...prevState.newVector,
              name: 'New Circle',
              calendarId: 0,
              description: '',
              area:
                'CIRCLE (' +
                currentShap._latlng.lat +
                ' ' +
                currentShap._latlng.lng +
                ', ' +
                currentShap._mRadius +
                ')',
              attributes: {
                type: 'circle',
                radius: currentShap._mRadius,
                color: currentShap.options.color,
                latlng: [currentShap._latlng.lat, currentShap._latlng.lng]
              }
            }
            _saveShap(updatedCircle)
            return { ...prevState, newVector: updatedCircle }
          })
        } else if (state.Drawing === 'polygon') {
          if (currentShap._latlngs) {
            let prepare = currentShap._latlngs[0].map(pos => {
              return '' + pos.lat + ' ' + pos.lng + ''
            })
            setState((prevState) => {
              let updatedVector = {
                ...prevState.newVector,
                name: 'New Polygon',
                calendarId: 0,
                description: '',
                area: 'POLYGON((' + prepare.join(', ') + '))',
                attributes: {
                  type: 'polygon',
                  color: currentShap.options.color,
                  latlng: currentShap._latlngs[0]
                }
              }
              _saveShap(updatedVector)
              return { ...prevState, newVector: updatedVector }
            })
          }
        } else if (state.Drawing === 'polyline') {
          let distance = 0
          let prepare = currentShap._latlngs.map((pos, index) => {
            distance += pos.distanceTo(currentShap._latlngs[index - 1] || pos)
            return '' + pos.lat + ' ' + pos.lng + ''
          })
          setState((prevState) => {
            let updatedPolyline = {
              ...prevState.newVector,
              name: 'New Polyline',
              calendarId: 0,
              description: '',
              area: 'LINESTRING (' + prepare.join(', ') + ')',
              attributes: {
                type: 'polyline',
                color: currentShap.options.color,
                latlng: currentShap._latlngs,
                polylineDistance: distance / 1000
              }
            }
            _saveShap(updatedPolyline)
            return { ...prevState, newVector: updatedPolyline }
          })
        }
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        Drawing: ""
      }))
    }
  }

  const cancelDrawing = () => {
    if (state.Drawing === 'polygon' || state.Drawing === 'polyline') {
      state.DrawControl._toolbars.draw._actionButtons[2].button.click()
    }
    if (state.Drawing === 'circle') {
      state.DrawControl._toolbars.draw._actionButtons[0].button.click()
    }
    setState((prevState) => ({
      ...prevState,
      Drawing: ""
    }))
  }

  const saveDrawing = () => {
    if (state.Drawing === 'polygon' || state.Drawing === 'polyline') {
      state.DrawControl._toolbars.draw._actionButtons[0].button.click()
    }
  }

  const undoDrawing = () => {
    if (state.Drawing === 'polygon' || state.Drawing === 'polyline') {
      state.DrawControl._toolbars.draw._actionButtons[1].button.click()
    }
  }

  const _onEditControlReady = (reactECrefAr) => {
    setTimeout(() => {
      if (reactECrefAr) {
        let key = Object.keys(
          reactECrefAr._toolbars.edit.options.featureGroup._layers
        )
        if (key.length) {
          let id =
            reactECrefAr._toolbars.edit.options.featureGroup
              ._layers[key].options.id
          if (reactECref.current) {
            reactECref.current[id] = reactECrefAr
          }
          setState((prevState) => ({
            ...prevState,
            EditControlObject: { ...prevState.EditControlObject, [id]: reactECrefAr }
          }))
        }
      }
    }, 50)
  }

  const _onEditStart = (id) => {
    if (reactECref.current[id]) {
      setEditControlValue(state.EditControlObject)
      updateVisible()
    }
  }

  const _saveShap = (updatedItem) => {
    // fetch(`api/geofences`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ ...updatedItem })
    // })
    instance({
      url: `api/geofences`,
      method: `POST`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...updatedItem
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then(geofence => {
        dispatch(addGeoFence([{ ...geofence, visible: true }]))
        setState((prevState) => ({
          ...prevState,
          newVector: {},
          Drawing: "",
        }))
        state.DrawControl._toolbars.edit._toolbarContainer.children[1].click()
        if (
          state.DrawControl._toolbars.edit
            ._actionButtons &&
          state.DrawControl._toolbars.edit
            ._actionButtons[2]
        ) {
          state.DrawControl._toolbars.edit._actionButtons[2].button.click()
        }
        //   })
        // }
        // else{
        //   throw response
        // }
      }).catch(error => { errorHandler(error, dispatch) })
  }

  const _onEditPath = (id, e) => {
    if (reactECref.current[id]) {
      setState((prevState) => ({
        ...prevState,
        EditControlObject: { ...prevState.EditControlObject, [id]: reactECref.current[id] }
      }))

      let layers = e.layers
      let item = state.geo
      let attributes = item ? item.attributes : {}
      let updatedItem = state.geo || {}
      let jsonObj

      layers.eachLayer(layer => {
        jsonObj = layer.toGeoJSON()
        if (jsonObj.geometry.type === 'Point') {
          var latlng2 = jsonObj.geometry.coordinates
          updatedItem = {
            ...item,
            area:
              'CIRCLE (' +
              latlng2[1] +
              ' ' +
              latlng2[0] +
              ', ' +
              layer._mRadius +
              ')',
            attributes: {
              ...attributes,

              type: 'circle',
              latlng: [latlng2[1], latlng2[0]],
              radius: layer._mRadius
            }
          }
        } else if (jsonObj.geometry.type === 'Polygon') {
          item = state.geo
          var latlng22 = jsonObj.geometry.coordinates
          var p22 = []
          let prepare = latlng22[0].map(pos => {
            p22.push([pos[1], pos[0]])
            return '' + pos[1] + ' ' + pos[0] + ''
          })
          updatedItem = {
            ...item,
            area: 'POLYGON((' + prepare.join(', ') + '))',
            attributes: { ...attributes, type: 'polygon', latlng: p22 }
          }
        } else if (jsonObj.geometry.type === 'LineString') {
          item = state.geo
          var latlng23 = jsonObj.geometry.coordinates
          var p23 = []
          let prepare = latlng23.map(pos => {
            p23.push([pos[1], pos[0]])

            return '' + pos[1] + ' ' + pos[0] + ''
          })
          updatedItem = {
            ...item,
            area: 'LINESTRING (' + prepare.join(', ') + ')',
            attributes: { ...attributes, type: 'polyline', latlng: p23, imported: false, }
          }
        }
      })

      if (
        state.updatedGeo[id] === false ||
        state.updatedGeo[id] === undefined
      ) {
        if (updatedItem && updatedItem.visible !== undefined) {
          delete updatedItem.visible
        }
        if (
          updatedItem &&
          updatedItem.attributes &&
          updatedItem.attributes.speedLimit
        ) {
          updatedItem.attributes.speedLimit = convertSpeedUnits(
            updatedItem.attributes.speedLimit || 0,
            'kmh',
            'kn'
          )
        }
        setState((prevState) => ({
          ...prevState,
          updatedGeo: {
            [id]: true
          }
        }))


        // fetch(`api/geofences/${id}`, {
        //   method: 'PUT',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({ ...updatedItem })
        // })
        instance({
          url: `api/geofences/${id}`,
          method: `PUT`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data: {
            ...updatedItem
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(geofence => {
            setState((prevState) => ({
              ...prevState,
              updatedGeo: {
                [id]: false
              },
              EditMode: false
            }))
            dispatch(
              updateGeoFence({ ...geofence, visible: false })
            )
            dispatch(
              updateGeoFence({ ...geofence, visible: true })
            )
            //   })
            // }
            // else{
            //   throw response
            // }
          }).catch(error => { errorHandler(error, props.dispatch) })
      }
    }
  }
  const onFeatureGroupAdd = (e, b) => {
    geo[b] = e.target.getBounds();
    setState((prevState) => ({
      ...prevState,
      fitBounds: { ...geo }
    }))
    setisCallBounds(true)

  };





  const toggleGeofences = (checked,arr) => {
    if (!checked) {
      setState((prevState) => ({
        ...prevState,
        fitBounds: {}
      }))
      geo = {}
    }
    else {
      updateVisible(arr);
    }
    setState((prevState)=>({
      ...prevState,
      isAllGeofencesCall:true
    }))
    props.dispatch(toggleAllGeofences(checked,"geofences",arr));

  }

  const updateVisible = (arr) => {
    const geofences = arr || props.geoFence;
    setTimeout(() => {
      let bounds = []
      geofences.forEach(g => {
        if (g.visible === true && state.fitBounds[g.id]) {
          bounds.push(state.fitBounds[g.id])
        }
        return null
      })
      if (bounds.length && mapRef.current) {
        mapRef.current.fitBounds(bounds)
      }
    }, 50)
  }

  const changeBaseLayer = (event) => {
    if (mapRef.current) {
      const center = mapRef.current?.getCenter();
      mapRef.current.options.crs = ['yandexMap', 'yandexSat'].includes(event.layer.options && event.layer.options.id) ? L.CRS.EPSG3395 : L.CRS.EPSG3857;
      mapRef.current.setView(center);
    }
    setState((prevState) => ({
      ...prevState,
      currentLayer: event.layer.options
    }))

  }

  const getLatLngString = (layer) => {
    if (!layer || !layer.latLngs || !Array.isArray(layer.latLngs)) {
      return ''; // return empty string if layer object or latLngs array is missing
    }

    // Map through the latLngs array and extract latLng coordinates
    const latLngString = layer.latLngs.map(latLng => {
      if (latLng && latLng.lat && latLng.lng) {
        return `${latLng.lat} ${latLng.lng}`;
      } else {
        return ''; // return empty string if latLng object or lat/lng values are missing
      }
    }).join(', '); // join latLng strings with comma and spaces

    return latLngString;
  }

  const upload = (n) => {
    if (n.target.files) {
      let e = n.target.files[0],
        t = e.name.split('.'),
        i = e.size,
        ko = 50000000,
        r = ['geojson', 'json', 'gpx', 'kml', 'xml'],
        u = t[t.length - 1].toLowerCase()

      if (r.includes(u)) {
        if (i > ko) {
          return (
            'File size is too large. Max allowed size is ' +
            Math.round(ko / (1024 * 1000)) +
            'MB'
          )
        } else {
          let p = new FileReader()
          // const base64String = p.result;

          p.readAsText(e, 'UTF-8')
          p.onload = async o => {
            let n = o.target.result

            if ('kml' === u) {
              let t = new DOMParser().parseFromString(n, 'text/xml'),
                tag = t && t.documentElement && t.documentElement.nodeName
              if ('kml' !== tag && 'gpx' !== tag) {
                return 'File content is not valid.'
              }
              u = tag
            }

            const parser = new DOMParser()
            const kml = parser.parseFromString(n, 'text/xml')

            const layer = new L.KML(kml)
            const latLonString = getLatLngString(layer);

            // geofence work start from here...

            const geofences = [];
            // Object.values(layer._layers).map((l, i) =>  {

            //     let distance = 0
            //     let prepare = l._latlngs.map((pos, index) => {
            //       distance += pos.distanceTo(l._latlngs[index - 1] || pos)
            //       return '' + pos.lat + ' ' + pos.lng + ''
            //     })


            geofences.push({
              name: 'Test KML ' + (moment().format('YYYY-MM-DD') + ' ' + (i + 1)),
              calendarId: 0,
              description: 'Test KML',
              area: 'KML (' + latLonString + ')',
              attributes: {
                imported: true,
                // color: l.options.fillColor,
                // polylineDistance: distance / 1000
              }
            });
            // });
            // for(const geo of geofences) {

            //   const response = await Axios.post(`/api/geofences`, geo);
            //   console.log("response=====",response);

            // }
            setState((prevState) => ({
              ...prevState,
              kmlContent: kml,
              kmlType: u,
              kmlDataForm: {
                ...prevState.kmlDataForm,
                area: 'KML (' + latLonString + ')',
                attributes: {
                  ...prevState.kmlDataForm.attributes,
                  latlng: layer.latLngs
                }
              }
            }))
            return {
              name: e.name,
              size: Math.round(e.size / (1024 * 1000)) + 'MB'
            }
          }
        }
      } else {
        return 'Unsupported file type.'
      }
      return { name: e.name, size: (e.size / 1024).toFixed(2) + 'KB' }
    }
  }


  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      kmlDataForm: { ...prevState.kmlDataForm, [name]: value }
    }))
  }


  const submitKMLForm = async () => {
    const geo = state.kmlDataForm;
    instance({
      url: `/api/geofences`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...geo
      }
    })
      .then(geofence => {  // eslint-disable-line no-unused-vars
        setState((prevState) => ({
          ...prevState,
          disableSubmit: true
        }))
        closeGeofenceModal()
        dispatch(
          Notifications.success({
            message: props.translate('kmlTrackCreated'),
            autoDismiss: 10
          })
        )
      })
      .catch(error => { errorHandler(error, props.dispatch) })
  }




  const endMessage =
    state.itemPagination && state.itemPagination.total > 0 ? (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {' '}
        -- {props.translate('end')} --{' '}
      </p>
    ) : (
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        {' '}
        {props.translate('notFound')}{' '}
      </p>
    )
  if (props.mapLayer) {
    const settings = {
      rectangle: false,
      circle: false,
      circlemarker: false,
      marker: false,
      polyline: false,
      polygon: false
    }
    // let bounds;
    const geofences = props.geoFence.map(obj => {
      if (obj && obj.attributes.type === 'circle' && obj.visible === true) {
        return (
          <FeatureGroup
            // onAdd={e => onFeatureGroupAdd(e, obj.id)}
            // ref={_onFeatureGroupReady}
            eventHandlers={{
              add: (e) => {
                onFeatureGroupAdd(e, obj.id)
              },
            }}
            key={obj.id}
          >
            <EditControl
              onMounted={_onEditControlReady}
              position='bottomleft'
              draw={settings}
              onEdited={(e) => _onEditPath(obj.id, e)}
              onEditStart={(e) => _onEditStart(obj.id, e)}
            />
            <Circle
              id={obj.id}
              radius={obj.attributes.radius}
              center={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{ReactHtmlParser(obj.name)}</span>
                </div>
              </Tooltip>
            </Circle>
          </FeatureGroup>
        )
      } else if (
        obj &&
        obj.attributes.type === 'polygon' &&
        obj.visible === true
      ) {
        return (
          <FeatureGroup
            eventHandlers={{
              add: (e) => {
                onFeatureGroupAdd(e, obj.id)
              },
            }}
            // onAdd={e => onFeatureGroupAdd(e, obj.id)}
            // ref={_onFeatureGroupReady}
            key={obj.id}
          >
            <EditControl
              onMounted={_onEditControlReady}
              position='bottomleft'
              draw={settings}
              edit={{ remove: false }}
              onEdited={(e) => _onEditPath(obj.id, e)}
              onEditStart={(e) => _onEditStart(obj.id, e)}
            />
            <Polygon
              id={obj.id}
              key={obj.id + '__1'}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{ReactHtmlParser(obj.name)}</span>
                </div>
              </Tooltip>
            </Polygon>
          </FeatureGroup>
        )
      } else if (
        obj &&
        obj.attributes.type === 'polyline' &&
        obj.visible === true
      ) {
        return (
          <FeatureGroup
            // onAdd={e => onFeatureGroupAdd(e, obj.id)}
            // ref={_onFeatureGroupReady}
            eventHandlers={{
              add: (e) => {
                onFeatureGroupAdd(e, obj.id)
              },
            }}
            key={obj.id}
          >
            <EditControl
              onMounted={_onEditControlReady}
              position='bottomleft'
              draw={settings}
              edit={{ remove: false }}
              onEdited={(e) => _onEditPath(obj.id, e)}
              onEditStart={(e) => _onEditStart(obj.id, e)}
            />
            <Polyline
              id={obj.id}
              key={obj.id + '__1'}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{ReactHtmlParser(obj.name)}</span>
                </div>
              </Tooltip>
            </Polyline>
          </FeatureGroup>
        )
      } else if (
        obj &&
        obj.attributes.type === "kmlFile" &&
        obj.visible === true
      ) {
        const coordinates = obj?.attributes?.latlng;
        // bounds = coordinates;
        return (
          <FeatureGroup
            // onAdd={e => onFeatureGroupAdd(e, obj.id)}
            // ref={_onFeatureGroupReady}
            key={obj.id}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Render Polyline with the extracted coordinates */}
            <Polyline positions={coordinates} color={"red"} />
          </FeatureGroup>
        )
      }

      return ''
    })

    const position = [state.lat, state.lng]
    const drawOptions = {
      shapeOptions: {
        color: props.themecolors && props.themecolors.themeDarkColor,
        opacity: 1,
        newShap: true
      }
    }
    const drawSettings = {
      ...settings,
      circle: drawOptions,
      polygon: drawOptions,
      polyline: drawOptions
    }

    const body = [
      <>
        <FeatureGroup>
          <EditControl
            ref={_onDrawControlReady}
            draw={drawSettings}
            position='bottomleft'
            onDrawStop={_onDrawStop}
            onDrawStart={_onDrawStart}
          />
        </FeatureGroup>
        {/* start */}
        {props.logInUser.attributes &&
          props.logInUser.attributes.clusters ? (
            <MarkerClusterGroup key={1}>{state.markers}</MarkerClusterGroup>
          ) : (
            state.markers
          )}
        {/* end */}
        {geofences}
        <ZoomControl position={'bottomright'} />
        <MapPagination />
      </>
    ]

    const thisMap = [
      <MapContainer
        key={1}
        ref={mapRef}
        zoomControl={false}
        style={{ height: props.height, width: props.width }}
        center={position}
        zoom={state.zoom}
        onBaselayerchange={changeBaseLayer}
        maxZoom={props.mapLayer.maxZoom}
        whenReady={({target})=>isMapReady(target)}
        // bounds={newBounds && newBounds.length ? newBounds : null}


      >
        {state.pointer}
        {body}
        {state.geofenceModal && state.resourceList === 'tracks' && (
          <TrackModal
            enableEdit={state.enableEdit}
            geofence={state.geofenceModalData}
            {...state.geofenceModalMaoData}
            onClose={closeGeofenceModal}
            resourceList={state.resourceList}
            kmlFileUpload={upload}
            kmlDataForm={state.kmlDataForm}
            onChange={handleChange}
            disableSubmit={state.disableSubmit}
            submitKMLForm={submitKMLForm}
            translate={props.translate}
          />
        )}
        <LayersControl position="bottomright">
          {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === props.mapLayer.id} key={layer.id} name={layer.name}>
            <TileLayer {...layer} minZoom={state.minZoom} />
          </LayersControl.BaseLayer>)}
        </LayersControl>
      </MapContainer>
    ]
    // const coordinates = state?.itemPagination?.items?.map(coord => [coord.lat, coord.lng]);


    // const thisMap = [
    //     <Map  
    //       key={1}
    //       ref={mapReference}
    //       zoomControl={false}
    //       style={{ height: props.height, width: props.width }}
    //       center={position}
    //       zoom={state.zoom}
    //       onBaselayerchange={changeBaseLayer}
    //       maxZoom={props.mapLayer.maxZoom}>
    //       <TileLayer
    //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //       />
    //       {/* Render Polyline with the extracted coordinates */}
    //       <Polyline positions={coordinates} color={"red"} />
    //    </Map>
    //   ]
    return (
      <div className='no-map-controls no-widgets'>
        {state.geofenceModal && state.resourceList !== 'tracks' && (
          <GeofenceModal
            geofence={state.geofenceModalData}
            {...state.geofenceModalMaoData}
            onClose={closeGeofenceModal}
            resourceList={state.resourceList}
            translate={props.translate}
            logInUser={props.logInUser}
          />
        )}
        {/* {state.geofenceModal && state.resourceList === 'tracks' &&  (
                <TrackModal
                  enableEdit={state.enableEdit}
                  geofence={state.geofenceModalData}
                  {...state.geofenceModalMaoData}
                  onClose={closeGeofenceModal}
                  resourceList={state.resourceList}
                  kmlFileUpload={upload}
                  kmlDataForm={state.kmlDataForm}
                  onChange={handleChange}
                  disableSubmit={state.disableSubmit}
                  submitKMLForm={submitKMLForm}
                />
              )} */}
        <Layout
          {...props}
          kmlFileUpload={upload}
          enableEdit={state.enableEdit}
          geofenceData={{ ...state, updateVisible, save, enableEditMap, cancelMapChanges, cancelDrawing, saveDrawing, undoDrawing, toggleGeofences }}
          toggleGeofences={toggleGeofences}
          fetchMoreItems={fetchMoreItems}
          itemPagination={{ ...state.itemPagination, endMessage }}
          searchItems={searchItems}
          openGeofenceModal={openGeofenceModal}
          onDelete={onDelete}
          showResources={showResources}
          onEditResource={onEditResource}
          onLinkResource={onLinkResource}
          addResource={addResource}
          resourceList={state.resourceList}
          getAllGeofences={getAllGeofences}
          handleScrollGeofencesIndex={handleScrollGeofencesIndex}

        >
          {!['addResource', 'editResource'].includes(state.activeOperation) ? <div>
            {['osm', ''].includes(props.mapLayer.id) ? thisMap : null}
            {['carto'].includes(props.mapLayer.id) ? thisMap : null}
            {['googleTerrain'].includes(props.mapLayer.id)
              ? thisMap
              : null}
            {['googleSatellite'].includes(props.mapLayer.id)
              ? thisMap
              : null}
            {['googleHybrid'].includes(props.mapLayer.id) ? thisMap : null}
            {['googleRoad'].includes(props.mapLayer.id) ? thisMap : null}
            {['baidu'].includes(props.mapLayer.id) ? thisMap : null}
            {['yandexMap', 'yandexSat'].includes(props.mapLayer.id)
              ? thisMap
              : null}
          </div> : null}

          <button
            style={{
              position: 'absolute',
              left: '-10000px',
              top: '-10000px'
            }}
            onClick={() => addShape('circle')}
            id='addCircle'
          ></button>
          <button
            style={{
              position: 'absolute',
              left: '-10000px',
              top: '-10000px'
            }}
            onClick={() => addShape('polygon')}
            id='addPolygon'
          ></button>
          <button
            style={{
              position: 'absolute',
              left: '-10000px',
              top: '-10000px'
            }}
            onClick={() => addShape('polyline')}
            id='addPolyline'
          ></button>
          <div className="has-padding">
            <ResourceModal
              changeResource={props.changeResource}
              selectedResourse={state.selecteditem}
              activeOperation={state.activeOperation}
              onCloseResource={onCloseResource}
              itemPagination={state.itemPagination && state.itemPagination.items}
              assignItem={props.assignItem}
              unassignItem={props.unassignItem}
              fetchNestedItems={props.fetchNestedItems}
              nestedResources={props.nestedResources}
              translate={props.translate}
              editResource={state.editResource}
              linkResource={state.linkResource}
              themecolors={props.themecolors}
              itemType='Geofence'
            />
          </div>
        </Layout>
      </div>
    )
  } else {
    return null
  }
}
const mapStateToProps = state => ({
  geoFence: state.geoFence,
  deviceRelatedData: state.deviceRelatedData,
  devices: state.devices,
  mapLayer: state.mapLayer,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withLocalize(withResources(Geofences, 'Geofence')))