import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from './../common/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Dialog from '../../Components/common/Dialog'
import EventIcon from '@mui/icons-material/Event'
import SettingsIcon from '@mui/icons-material/Settings'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
// import { ReactComponent as TimemachineIcon } from '../../assets/nav/time-machine.svg'
import { ReactComponent as TracksIcon } from '../../assets/nav/tracks.svg'
// import { ReactComponent as NearestUnitsIcon } from '../../assets/nav/distancee-01.svg'
import { ReactComponent as NearestUnitsIcon } from '../../assets/nav/distance-icons.svg'

import { ReactComponent as CommandIcon } from '../../assets/nav/command.svg'
import { ReactComponent as StreetViewIcon } from '../../assets/nav/street-view.svg'
import { checkPrivileges, prepareStreeView } from '../../Helpers'
import Style from 'style-it'
import { SendCommand } from './../../Pages/Units/Components/sendCommand'
import { ShareLocation } from './../../Pages/Units/Components/ShareLocation'
// import Tracks from '../../Pages/Tracks'
import instance from '../../axios'
import CustomDialog from '../../Components/common/Dialog'
import Table from '../../Components/common/Table'
import NearMeIcon from '@mui/icons-material/NearMe';

import * as turf from '@turf/turf'
let win;



export default class UnitMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      commandModal: false,
      openTable:false,
      list: [],
      sortedDistances:"",
      locationModal: false

    }
  }

  onCloseModal = () => {
    this.setState(
      {
        commandModal: false,
        locationModal: false

      },
      () => {
        this.handleClose()
      }
    )
  }

  showModal = ()=> {
    this.fetchData(this.props.data.id)
    this.setState({
      commandModal: true
    })
  }
  showLocationModal = () => {
    this.setState({
      locationModal: true
    })
  }
  handleClose = () => {
    this.props.onClose()
  }

  removeDevice = () => {
    this.props.removeDevice(this.props.data)
  }

  editDevice = () => {
    this.props.editDevice(this.props.data)
  }

  handleDeviceSettings = () => {
    this.props.openDeviceSettings()
    this.handleClose()
  }

  componentWillReceiveProps (NextProps) {
    this.setState({ anchorEl: NextProps.anchorEl })
  }

  fetchData = id => {
    this.setState(
      { commandsLoading: true, typesLoading: true, list: [] },
      () => {
        // fetch(`/api/commands/send?deviceId=${id}`, {
        //   method: `GET`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/commands/send`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            deviceId: id
          }
          })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(commands => {
              const list = []
              if (commands.length) {
                commands.map(item => {
                  list.push({ ...item, key: item.id, name: item.description })
                  return null
                })
              }
              this.setState({
                list,
                commandsLoading: false
              })
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(() => {
          // errorHandler(error, this.props.dispatch)
        })
      }
    )
  }

  closePrevious = () => {
    win.close();
    win = null;
    this.showStreetView();
  }

  showStreetView = () => {
    if(win && win.closed === false) {
      this.closePrevious()
    }
    else {
      if(this.props.relatedData && this.props.relatedData.exists) {
        win = window.open(prepareStreeView(this.props.relatedData.latitude, this.props.relatedData.longitude),"newWindow");
      }
    }
  }

  onShowTable = ()=> this.setState({openTable:true})
  onCloseTable = ()=> this.setState({openTable:false})

  componentDidMount() {
    this.calculateDistances();
  }

  calculateDistances() {
    const relatedLatitude = this.props.relatedData && this.props.relatedData.latitude;
    const relatedLongitude = this.props.relatedData && this.props.relatedData.longitude;
    const distances = Object.values(this.props.deviceRelatedData || {}).map(device => {
      const deviceLatitude = device && device.latitude;
      const deviceLongitude = device && device.longitude;
      if (
        relatedLatitude !== "" && relatedLongitude !== "" &&
        deviceLatitude !== "" && deviceLongitude !== "" &&
        !isNaN(relatedLatitude) && !isNaN(relatedLongitude) &&
        !isNaN(deviceLatitude) && !isNaN(deviceLongitude) &&
        device.id !== this.props.relatedData.id // Check if device id is not the same as relatedData id
      ) {
        const point1 = turf.point([Number(relatedLatitude), Number(relatedLongitude)]);
        const point2 = turf.point([Number(deviceLatitude), Number(deviceLongitude)]);
        const distance = turf.distance(point2, point1);
        return {
          deviceId: device.id,
          distance: Math.ceil(distance),
          name: device.name,
          status: device.status,
        };
      } else {
        return null; // If latlng data is missing or not valid, set distance as null
      }
    });
    const validDistances = distances.filter(distance => distance !== null);
    const sortedDistances = validDistances.sort((a, b) => a.distance - b.distance);
    this.setState({ sortedDistances }); // Set sorted distances in the component state
  }
  render () {
    const styles = {
      ListItemIcon: {
        fontSize: 18,
        fill: 'currentColor'
      },
      ListItemIconWrap: {
        marginRight: 10,
        color: 'inherit',
        minWidth: 18
      }
    }
    return (
      <Style>
        {`
    .custom-menu-paper {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    `}
        <div>
          <Menu
            id={this.props.id}
            anchorEl={this.props.anchorEl}
            open={Boolean(this.props.anchorEl)}
            onClose={this.handleClose}
            classes={{ paper: 'custom-menu-paper' }}
          >
            {checkPrivileges('device') && (
              <MenuItem
                component={Link}
                to={'/events/' + this.props.data.id}
                onClick={this.handleClose}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <EventIcon style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('eventHistory')}
              </MenuItem>
            )}
            {checkPrivileges('track') && (
              <MenuItem
                component={Link}
                to={'/tracks/'+ this.props.data.id}
                onClick={this.handleClose}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <TracksIcon
                    width={14}
                    height={14} 
                    style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('Tracks')}
              </MenuItem>
            )}
              {/* {checkPrivileges('track') && ( */}
              <MenuItem
                component={Link}
                style={styles.MenuItem}
                // to={'/tracks/'+ this.props.data.id}
                to={"#"}
                onClick={this.onShowTable}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <NearestUnitsIcon
                    width={14}
                    height={14} 
                    
                    style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('Nearest Units')}
              </MenuItem>
            {/* )} */}
            {checkPrivileges('deviceUpdate') && (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.handleDeviceSettings}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <SettingsIcon style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('openDeviceSettings')}
              </MenuItem>
            )}
            {checkPrivileges('report') && (
              <MenuItem
                component={Link}
                to='/reports'
                style={styles.MenuItem}
                onClick={this.handleClose}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <InsertDriveFileIcon style={styles.ListItemIcon} />
                </ListItemIcon>
                {this.props.translate('openReports')}
              </MenuItem>
            )}
            {checkPrivileges('command') ? (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.showModal}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <CommandIcon
                    width={14}
                    height={14}
                    style={styles.ListItemIcon}
                  />
                </ListItemIcon>
                {this.props.translate('sendCommand')}
              </MenuItem>
            ) : null}
             {checkPrivileges('deviceLocSharing') ? (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.showLocationModal}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <NearMeIcon
                    width={14}
                    height={14}
                    style={styles.ListItemIcon}
                  />
                </ListItemIcon>
                {this.props.translate('shareLocation')}
              </MenuItem>
             ): null}
            {this.props.relatedData && this.props.relatedData.exists ? (
              <MenuItem
                component='li'
                style={styles.MenuItem}
                onClick={this.showStreetView}
              >
                <ListItemIcon style={styles.ListItemIconWrap}>
                  <StreetViewIcon
                    width={14}
                    height={14}
                    style={styles.ListItemIcon}
                  />
                </ListItemIcon>
                
                {this.props.translate('streetView')}
              </MenuItem>
            ) : null}
          </Menu>

          {this.state.openTable ? <>
            <CustomDialog
                // title={this.props.item?.name||""}
                title={this.props.translate('Devices Locations')}
                themecolors={this.props.themecolors}
                visable={true}
                onClose={this.onCloseTable}
                bodyPadding={10}
                isVisableBtn
                noFullScreen
              >
                <Table insideDialog={true}
                    title={this.props.translate('Devices Locations')}
                    rows={this.state.sortedDistances||[]}
                    rowsPerPage={10}
                    searchable={false}
                    themecolors={this.props.themecolors} 
                    rowDefinition={[
                        { id: 'name', numeric: false, disablePadding: false, label: this.props.translate('sharedName') },
                        { id: 'distance', type: "any", numeric: false, label: this.props.translate('distance') + ' (Km)' },
                        { id: 'status', type: "any", numeric: false, label: this.props.translate('status') },
                    ]} />
                      </CustomDialog>
        </>: null}
          {this.state.commandModal ? (
            <Dialog
              open
              // title={this.props.translate('sendCommand')}
              title={`${this.props.translate('sendCommand')} ${'  '}${this.props.data.name}`}

              themeColors={this.props.themecolors}
              disableFooter
              onClose={this.onCloseModal}
              dialogHeight={250}
              draggable
              bodyPadding={0}
            >
              <SendCommand
                id={this.props.id}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                descriptionList={this.state.list}
              />
            </Dialog>
          ) : null}
          {this.state.locationModal ? (
            <Dialog
              open
              // title={this.props.translate('sendCommand')}
              title={`${this.props.translate('shareLocation')}${'  '} ${this.props.translate('of')} ${'  '}  ${this.props.data.name}`}

              themeColors={this.props.themecolors}
              disableFooter
              onClose={this.onCloseModal}
              dialogHeight={250}
              draggable
              bodyPadding={0}
            >
              <ShareLocation
                device={this.props.data}
                id={this.props.id}
                ServerSetting={this.props.ServerSetting}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                descriptionList={this.state.list}
                logInUser={this.props.logInUser}
                copyToClipboard={this.props.copyToClipboard}s
              />
            </Dialog>
          ) : null}
        </div>
      </Style>
    )
  }
}
