import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
// import { makeStyles } from '@mui/styles'
import {withStyles } from '@mui/styles';
import { TableBody,Table } from '@mui/material';
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Checkbox from './Checkbox'
import Tooltip from './Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { lighten } from '@mui/system'
// import isEqual from 'react-fast-compare'
import styled from 'styled-components'
import { connect } from 'react-redux'
import TextField from './SearchField'
import Style from 'style-it'
import { setAttributeFormat } from '../../Helpers'
import moment from 'moment'

// let themeColors
const mapStateToProps = (state) => {
  // themeColors = state.themeColors
  return { themecolors: state.themeColors }
}

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function searchFilter (array, value, colums) {
  var dev = array.filter(item => {
    let exits = false
    colums.map(c => {
      if (
        item[c.id] &&
        typeof item[c.id] !== 'boolean' &&
        typeof item[c.id] !== 'number' &&
        typeof item[c.id] !== 'object' &&
        item[c.id].toLowerCase().indexOf(value) !== -1
      ) {
        exits = true
      }
      return null
    })
    return exits
  })
  return dev
}

function stableSort (array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting (order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

/*const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
];*/

// class EnhancedTableHead extends React.Component {
const EnhancedTableHead = (props) => {

  const createSortHandler = property => event => {
    props.onRequestSort(event, property)
  }

  // render () {
    const { order, orderBy, classes, isEditable } = props
    return (
      <TableHead>
        <TableRow
          className={classes.tableRow}
          style={{ background: props.themecolors&&props.themecolors.themeLightColor + '80' }}
        >
          {props.showCheckbox && (
            <TableCell
              style={{
                borderColor: props.themecolors.textColor,
                color: props.themecolors&&props.themecolors.themeLightInverse,
                padding: '0 10px'
              }}
            />
          )}
          {props.rowDefinition.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  borderColor: props.themecolors.textColor,
                  borderLeft: '1px solid ' + props.themecolors&&props.themecolors.themeLightColor,
                  padding: '0 10px',
                  color: props.themecolors&&props.themecolors.themeLightInverse,
                }}
              >
                <Tooltip
                  title='Sort'
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
          {isEditable && (
            <TableCell
              key={-5}
              width={60}
              style={{
                padding: '0 10px',
                borderColor: props.themecolors.textColor,
                borderLeft: '1px solid ' + props.themecolors&&props.themecolors.themeLightColor,
                color: 'inherit'
              }}
            />
          )}
        </TableRow>
      </TableHead>
    )
  // }/
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const toolbarStyles = theme => ({
  root: {
    //paddingRight: theme.spacing(1),
    minHeight: '44px',
    //paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1)
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1
  },
  highlight:
  theme.palette &&
    theme.palette.type &&
    theme.palette.type === 'light'
      ? {
          backgroundColor: lighten(theme.palette&&theme.palette.secondary&&theme.palette.secondary.light, 0.85)
        }
      : {
          backgroundColor: theme.palette&&theme.palette.secondary&&theme.palette.secondary.dark
        },
  spacer: {
    flex: '1 1 auto'
  },
  actions: {
    color: theme.palette&&theme.palette.text&&theme.palette.text.secondary
  },
  title: {
    fontFamily: 'inherit',
    flex: '0 0 auto',
    fontSize: 18,
    fontWeight: 500
  }
})

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    className,
    themecolors,
    column,
    selected,
    actionInHead
  } = props

  const ThemeIconButton = styled(IconButton)`
    background-color:${themecolors && themecolors.themeLightColor};
    border-radius: 0;
    color: ${themecolors&&themecolors.textColor}
    &:hover {
      cursor: pointer;
      background-color: ${themecolors&&themecolors.themeLightColor};
    }
  `

  return (
    <Toolbar
      className={
        classNames(classes.root, {
          [classes.highlight]: numSelected > 0
        }) +
        ' ' +
        className
      }
    >
      <div>
        {
          <Typography className={classes.title} id='tableTitle' color='inherit'>
            {props.title}{' '}
            {numSelected > 0 ? '(' + selected[0][column] + ')' : ''}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {actionInHead && (
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <Tooltip title='Delete'>
              <ThemeIconButton
                aria-label='Delete'
                color='inherit'
                onClick={props.onDelete}
              >
                <DeleteIcon fontSize='small' />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
          {numSelected === 1 ? (
            <Tooltip title='Edit'>
              <ThemeIconButton
                aria-label='Edit'
                onClick={props.onEdit}
                color='inherit'
              >
                <EditIcon fontSize='small' />
              </ThemeIconButton>
            </Tooltip>
          ) : null}
        </div>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
}

EnhancedTableToolbar =  (toolbarStyles)(EnhancedTableToolbar)
// const styles = theme => ({
//   root: {
//     width: '100%',
//     paddingTop: 44,
//     boxShadow: 'none'
//   },
//   table: {
//     minWidth: '100%'
//   },
//   tableWrapper: {
//     overflow: 'auto'
//   },
//   tableRow: {
//     height: 36,
//     '&:hover .actionButton': {
//       opacity: 1,
//       '&:hover': {
//         fill: themeColors&&themeColors.themeLightColor
//       }
//     },
//     '& .actionButton': {
//       fill: 'currentColor',
//       cursor: 'pointer',
//       marginLeft: 5,
//       opacity: 0
//     }
//   },
//   toolbar: {
//     root: {
//       position: 'absolute',
//       bottom: 0,
//       left: 0,
//       right: 0,
//       height: '44px',
//       minHeight: 'inherit'
//     },
//     toolbar: {
//       height: '44px',
//       minHeight: 'inherit'
//     }
//   }
// })
const styles = {
  root: {
    height: '44px',
    minHeight: 'inherit',
    overflow: 'hidden',
  },
  actions: {
    color: 'inherit',
  },
  selectIcon: {
    color: 'inherit',
  },
  toolbar: {
    height: '44px',
    minHeight: 'inherit',
    paddingRight: 1,
  },
};

const TablePaginationStyle = withStyles(styles)(TablePagination);

// const TablePaginationStyles =()=>({
//   root: {
//     height: '44px',
//     minHeight: 'inherit',
//     overflow: 'hidden'
//     //borderTop: "1px solid rgba(224, 224, 224, 1)"
//   },
//   actions: {
//     color: 'inherit'
//   },
//   selectIcon: {
//     color: 'inherit'
//   },
//   toolbar: {
//     height: '44px',
//     minHeight: 'inherit',
//     paddingRight: 1
//   }
// })
// export const TablePaginationStyle =  (TablePaginationStyles)(
//   TablePagination
// )

// class EnhancedTable extends React.Component {
const EnhancedTable = (props) => {

  const [state, setState] = useState({
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: props.rows,
      page: 0,
      rowsPerPage: props.rows && props.rows.length > 50 ? 50 : props.rows.length,
      searchValue: ''
    }
  )
  const makeSearch =(event) =>{
    setState((prevState) => ({...prevState, searchValue: (''+event.target.value).toLowerCase() }))
  }

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc'
    }

    setState((prevState) => ({...prevState, order, orderBy }))
  }

  const handleSelectAllClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      selected: event.target.checked ? prevState.data.map(n => n.id) : []
    }));
  };
  

  const handleClick = (event, item) => {
    let newSelected = []
    newSelected.push(item)
    if (props.onClick) {
      props.onClick(item)
    }
    setState((prevState) => ({...prevState, selected: newSelected }))
  }

  const handleChangePage = (event, page) => {
    setState((prevState) => ({...prevState, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState((prevState) => ({...prevState, rowsPerPage: event.target.value }))
  }

  // const isSelected = id =>{
  //   (state.selected[0] && state.selected[0].id === id) || false
  // }

  const onEdit  = () =>{
    props.onEdit(state.selected)
  }

  const onDelete = () =>{
    props.onDelete(state.selected)
    setState((prevState) => ({...prevState,selected: [] }))
  }

  const onClose  =()=> {
    props.onClose()
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, props) || !isEqual(nextState, state) // equals() is your implementation
  // }

  const drawValue = (value, type, valueType) => {

    if (type && type === 'any') {
      if (valueType && valueType === 'boolean') {
        if (value === true) {
          return 'Yes'
        }
        return 'No'
      } else {
        return value
      }
    } else if (type && type === 'boolean') {
      if (value === true) {
        return 'Yes'
      }
      return 'No'
    } else {
      return value
    }
  }

  // render () {
    const { classes } = props
    const { order, orderBy, selected, rowsPerPage, page } = state
    const { rows: dataRaw = [] } = props
    const data = dataRaw || []
    let serverTimeZoneName = 'Asia/Dubai'
    // let vehicle = 'N/A'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = props.logInUser.attributes.timezone
    }
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    const EnhancedTableToolbarStyled = styled(EnhancedTableToolbar)`
      background: ${props => props.themecolors.themeLightColor};
      color: ${props => props.themecolors.textColor};
      padding-right: ${props => {
        return props.insideDialog ? '44px' : '0'
      }};
      padding-left: ${props => {
        return props.insideDialog ? '44px' : 'def'
      }};
    `

    return (
      <Style>
        {`
          .custom-table tbody th,
          .custom-table tbody td {
            border-color: ${props.themecolors&&props.themecolors.themeLightColor};
            color: ${props.themecolors&&props.themecolors.themeLightInverse};
          }
          .custom-table2 tbody th,
          .custom-table2 tbody td {
            border-color: ${props.themecolors&&props.themecolors.themeLightColor};
            color: ${props.themecolors&&props.themecolors.themeLightInverse};
          }
        `}
        <Paper
          className={classes.root}
          style={{
            paddingTop: props.disableHead ? 0 : null,
            background: 'none'
          }}
        >
          {props.disableHead === undefined && (
            <EnhancedTableToolbarStyled
              actionInHead={props.actionInHead}
              column={props.showInHead}
              selected={state.selected}
              insideDialog={props.insideDialog}
              themecolors={props.themecolors}
              numSelected={selected.length}
              title={props.title}
              onEdit={onEdit}
              onDelete={onDelete}
              onClose={onClose}
            />
          )}
          <div className={classes.tableWrapper}>
            {props.searchable && (
              <div style={{ padding: '10px 10px 0', textAlign: 'right' }}>
                <TextField
                  fullWidth
                  label={props.translate('sharedSearch')}
                  onChange={makeSearch}
                />
              </div>
            )}
            <Table
              className={classes.table + ' custom-table'}
              aria-labelledby='tableTitle'
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                isEditable={props.isEditable}
                rowCount={data.length}
                showCheckbox={props.showCheckbox}
                themecolors={props.themecolors}
                rowDefinition={
                  props.rowDefinition || [
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: true,
                      label: 'Name'
                    }
                  ]
                }
                classes={classes}
              />
              <TableBody>
                {searchFilter(
                  stableSort(data, getSorting(order, orderBy)),
                  state.searchValue,
                  props.rowDefinition
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n, syCount) => {
                    const isSelected = isSelected(n.id)
                    return (
                      <TableRow
                        hover
                        onClick={
                          props.isRowSelectionEnable
                            ? event => handleClick(event, n)
                            : () => void 0
                        }
                        role='checkbox'
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        className={classes.tableRow}
                      >
                        {props.showCheckbox && (
                          <TableCell
                            component='th'
                            padding='checkbox'
                            width='28'
                            style={{
                              padding: 0,
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                              color: props.themecolors.textColor,
                              borderBottom:
                                '1px solid ' +
                                props.themecolors.textColor +
                                '80'
                            }}
                          >
                            <Checkbox
                              checked={n.check}
                              onChange={props.checkHandleChange.bind(
                                this,
                                n
                              )}
                            />
                          </TableCell>
                        )}
                        {props.rowDefinition.map((r, i) => {
                          return (
                            r.id==='bookingStatus' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  props.themecolors&&props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {                          
                                n.bookingStatus===1?
                                props.translate('Success'):
                                n.bookingStatus===0?
                                props.translate('Booking'):
                                props.translate('Fail')
                                // ):
                            }
                         </TableCell>):
                            r.id==='vehicleLicensePlate' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  props.themecolors&&props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  props.themecolors.textColor +
                                  '80',
                                  background: n &&n.attributes&&n.attributes.invalid&&n.attributes.invalid.includes(r.id) ? "red" : ''
                              }}
                          >
                            {n?.attributes?.vehicleLicensePlate || ''}
                         </TableCell>):
                          r.id==='status' ? (<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  props.themecolors&&props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {                          
                                n.status==='expired'?
                                props.translate('Overdue'):
                                n.status==='required'?
                                props.translate('Due'):
                                n.status==='completed'?
                                props.translate('completed'):
                                props.translate('Scheduled')
                                // ):
                            }
                         </TableCell>):
                            r.id==='cost' ?(<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  props.themecolors&&props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {n.cost + ' '+(props.logInUser&&props.logInUser.attributes&&props.logInUser.attributes.currencyLabel || 'AED')}
                         </TableCell>):
                            r.id==='expenseCollection' ?(<TableCell
                            key={i}
                            component='td'
                            scope='row'
                            style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  props.themecolors&&props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  props.themecolors.textColor +
                                  '80',
                                  background: ((n &&n.attributes?.valid_driverUniqueId === false )&& (r.id === 'driverUniqueId')) ? "red" : ''
                              }}
                          >
                            {n.expenseCollection&&n.expenseCollection.map((item)=>{
                              return(
                                <>
                                  {syCount === 0 ? (
                                    <TableHead>
                                       <TableRow
                                          className={classes.tableRow}
                                            style={{ background:'#009df980'}}
                                        >
                                        <TableCell
                                            key={'Service'}
                                            align={'left'}
                                            padding={ 'default'}
                                            sortDirection={false}
                                            style={{
                                            borderColor: props.themecolors.textColor,
                                            borderLeft: '1px solid ' + props.themecolors&&props.themecolors.themeLightColor,
                                            padding: '0 10px',
                                            color: props.themecolors&&props.themecolors.themeLightInverse,
                                            }}
                                        >
                                                {'Service'}
                                            </TableCell>
                                        <TableCell
                                            key={'SubType'}
                                            align={'left'}
                                            padding={ 'default'}
                                            sortDirection={false}
                                            style={{
                                            borderColor: props.themecolors.textColor,
                                            borderLeft: '1px solid ' + props.themecolors&&props.themecolors.themeLightColor,
                                            padding: '0 10px',
                                            color: props.themecolors&&props.themecolors.themeLightInverse,
                                            }}
                                        >
                                                {'SubType'}
                                            </TableCell>
                                      </TableRow>
                                    </TableHead>
                                   ) : null}

                                  {item&& item.map((it,index)=>{
                                      return(
                                          <>
                                          <TableRow
                                              hover
                                              role='checkbox'
                                              aria-checked={isSelected}
                                              tabIndex={-1}
                                              key={n.id}
                                              selected={isSelected}
                                              className={classes.tableRow}
                                          >
                                          <TableCell key={index}
                                              component='td'
                                              scope='row'
                                              style={{
                                                  padding: '0 10px',
                                                  whiteSpace: 'nowrap',
                                                  color: props.themecolors.textColor,
                                                  borderLeft:
                                                  '1px solid ' +
                                                  props.themecolors&&props.themecolors.themeLightColor +
                                                  '66',
                                                  borderBottom:
                                                  '1px solid ' +
                                                  props.themecolors.textColor +
                                                  '80',
                                                  background: it&&it.attributes&&it.attributes.invalid.includes('expenseTypeLabel') ? "red" : ''                                                }} >
                                                      {it?.expenseTypeLabel || ''}
                                              </TableCell>  
                                              <TableCell 
                                                      key={index}
                                                      component='td'
                                                      scope='row'   style={{
                                                      padding: '0 10px',
                                                      whiteSpace: 'nowrap',
                                                      color: props.themecolors.textColor,
                                                      borderLeft:
                                                      '1px solid ' +
                                                      props.themecolors&&props.themecolors.themeLightColor +
                                                      '66',
                                                      borderBottom:
                                                      '1px solid ' +
                                                      props.themecolors.textColor +
                                                      '80',
                                                      background: it&&it.attributes&&it.attributes.invalid.includes('expenseSubTypeLabel') ? "red" : ''
                                                      }}>
                                                      {it?.expenseSubTypeLabel || ''}
                                                  </TableCell>  
                                          </TableRow>
                                          </>
                                      )
                                  })}
                                </>
                              )
                              })
                            }
                         </TableCell>):
                            <TableCell
                              key={i}
                              component='td'
                              scope='row'
                              style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: props.themecolors.textColor,
                                borderLeft:
                                  '1px solid ' +
                                  props.themecolors&&props.themecolors.themeLightColor +
                                  '66',
                                borderBottom:
                                  '1px solid ' +
                                  props.themecolors.textColor +
                                  '80',
                                  background: n &&n.attributes&&n.attributes.invalid&&n.attributes.invalid.includes(r.id) ? "red" : ''
                              }}
                            >
                            {r.id===('odometer')?( setAttributeFormat ('mileage', n.odometer)
                            ):
                            r.id===('engineHours')?((n.engineHours/(1000*3600)).toFixed(2) + ' hours'
                            ):
                            r.id === ('serviceTime') ? moment(n.serviceTime).tz(serverTimeZoneName).format('DD-MM-YYYY')
                             : drawValue(n[r.id], r.type, n.valueType, n.attributes)}
                            </TableCell>
                          )
                        })}
                        {props.isEditable && (
                          <TableCell
                            component='th'
                            scope='row'
                            style={{
                              padding: '0 10px',
                              whiteSpace: 'nowrap',
                              color: props.themecolors.textColor,
                              borderLeft:
                                '1px solid ' +
                                props.themecolors&&props.themecolors.themeLightColor +
                                '66',
                              borderBottom:
                                '1px solid ' +
                                props.themecolors.textColor +
                                '80'
                            }}
                          >
                            {props.canUpdate && (
                              <Tooltip title='Edit'>
                                <EditIcon
                                  className='actionButton'
                                  fontSize='small'
                                  onClick={() => props.onEdit([n])}
                                />
                              </Tooltip>
                            )}
                            {props.canDelete && (
                              <Tooltip title='Delete'>
                                <DeleteIcon
                                  className='actionButton'
                                  fontSize='small'
                                  onClick={() => props.onDelete([n])}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 36 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePaginationStyle
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            style={{color: props.themecolors&&props.themecolors.themeLightInverse}}
            count={
              searchFilter(
                data,
                state.searchValue,
                props.rowDefinition
              ).length
            }
            sx={{
              '.MuiSelect-icon': {
                color:props.themecolors.textColor + '!important',  // Use theme color dynamically
              },
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
              style: {
                padding: 6
              }
            }}
            height={44}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
              style: {
                padding: 6
              }
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Style>
    )
  // }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(
  withLocalize(EnhancedTable)
)
