import React, { useEffect, useState } from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { getNotificationType } from '../../../Actions/Notifications'
import Stepper from './../../../Components/common/Stepper'
import Button from './../../../Components/common/Button'
import Checkbox from './../../../Components/common/Checkbox'
import TextField from './../../../Components/common/SearchField'
import MenuItem from './../../../Components/common/MenuItem'
import Grid from '@mui/material/Grid'
import DateRangePicker from './../../../Components/common/DateRangeHistoryPicker'
import Loader from '../../Loader'
import Scrollbar from 'react-scrollbars-custom'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
// import GroupSelector from '../../../Components/Devices/GroupSelector'
import { connect } from 'react-redux'
import DriverSelector from '../../../Components/Devices/DriverSelector'
import NotificationSelector from '../../../Components/Devices/NotificationSelector'
import NotificationTypeSelector from '../../../Components/Devices/NotificationTypeSelector'
import Slider from '@mui/material/Slider'
import moment from 'moment'
import GeofenceSelector from '../../../Components/Devices/GeofenceSelector'
import Autocomplete from '../../../Components/common/Autocomplete'
import { Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ItemGroupSelector from '../../../Components/Devices/ItemGroupSelector'
import { RemoteItems } from '../../../Hooks/useRemoteItems'
import VehicleSelector from '../../../Components/Devices/VehicleSelector'
import instance from '../../../axios'
// import { errorHandler } from '../../../Helpers'
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
  color: '#0A83A1',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    // lineHeight: 1.2,
    fontSize: 10,
    background: 'unset',
    padding: 0,
    width: 30,
    height: 30,
    marginTop:10,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#0A83A1',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

// class ReportsSidebar extends Component {
const ReportsSidebar = (props) => {

  // constructor (props) {
  //   super(props)
  //   reportDetail = reportDetail.bind(this)
  // }

  // const reportDetail = (e)=> {
  //   props.reportDetail(e.target.checked)
  // }

  // const createReport = type => {
  //   props.collapseSidebar()
  //   props.createReport(type)
  // }

  // const openReportGenerator = () => {
  //   props.collapseSidebar()
  //   props.openReportGenerator()
  // }


  useEffect(() => {
    if (!props.notificationType.length) {
      instance({
        url: `/api/notifications/types/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(notifications => {
              props.dispatch(getNotificationType(notifications))
            })
        .catch(() => {
          // errorHandler(error, props.dispatch)
        })
    }
  }, [])
  


  // const getNotificationType = () => {
  //   let array = []
  //   if (props.notificationType.length) {
  //     props.notificationType.map((item) => {
  //       array.push({
  //         name: props.translate('notification.' + item.type),
  //         id: item.type
  //       })
  //       return null
  //     })
  //     return array
  //   } else {
  //     return array
  //   }
  // }

  const valuetext = value => {
    return moment('2016-03-13')
      .startOf('day')
      .add(value, 'minutes')
      .format('HH:mm')
  }

  const getStepContent = (id) => {

    let findTemplates= []
    props.templates.map((option) => {
      let checkKey = true
      let len = option.rpTmplTblWrappers.length;
      option.rpTmplTblWrappers.map((item, index)=>{
        if(item.rpTmplTbl.rpId !== 11 && checkKey){
              if(len === index+1){
                findTemplates.push(option)
              }
          }
          else{
            checkKey=false
          }
        })
      })
    
    switch (id) {
      case 1:
        return (<Grid container className='form-group'>
      <Grid item xs={12}>
      <TextField
          select
          label={props.translate('selectTemplate')}
          value={props.reportType || ''}
          variant='outlined'
          onChange={props.reportTypeChange}
          margin='dense'
          fullWidth
        >
              {findTemplates&&findTemplates.map(option => (
                (option.rpTmpl.active ? <MenuItem key={option.rpTmpl.id} value={option.rpTmpl.id}>
                  {option.rpTmpl.name}
                </MenuItem>: null)
              ))}
        </TextField>
      </Grid>
      {props.allowFields.includes('driver') ||
      props.allowFields.includes('device') ||
      props.allowFields.includes('vehicle') ? (
        <Grid item xs={12} style={{ padding: '8px 0 0' }}>
          <div className='report-sidebar-switch'>
            {props.allowFields.includes('device') ? (
              <Button
                onClick={() =>
                  props.reportTypeSelection('device')
                }
                className={
                  (props.reportUnits === 'device'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {props.translate('trackers')}
              </Button>
            ) : null}
            {props.allowFields.includes('vehicle') ? (
              <Button
                onClick={() =>
                  props.reportTypeSelection('vehicle')
                }
                className={
                  (props.reportUnits === 'vehicle'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {props.translate('sharedVehicle')}
              </Button>
            ) : null}
            {props.allowFields.includes('driver') ? (
              <Button
                onClick={() =>
                  props.reportTypeSelection('driver')
                }
                className={
                  (props.reportUnits === 'driver'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {props.translate('sharedDrivers')}
              </Button>
            ) : null}
          </div>
          <div className='report-sidebar-switch'>
            <a className={"btn-link "+(props.groupView === 1 ? 'active': '')} href={null} onClick={() => props.switchGroupView(1)}>
              {props.reportUnits === 'device' ? props.translate('trackers') : props.reportUnits === 'driver' ? props.translate('driver') : props.reportUnits === 'vehicle' ? props.translate('sharedVehicle') : ''}
            </a>
            <a className={"btn-link "+(props.groupView === 2 ? 'active': '')} href={null} onClick={() => props.switchGroupView(2)}>
              {props.reportUnits === 'device' ? props.translate('groups') : props.reportUnits === 'driver' ? props.translate('driverGroups') : props.reportUnits === 'vehicle' ? props.translate('vehicleGroups') : ''}
            </a>
          </div>
        </Grid>
      ) : null}
      {props.reportUnits === 'device' &&
      props.allowFields.includes('device') ? (
        <div>
          {props.groupView === 2 ? 
            <RemoteItems type="Device">
              {({data, isLoaded}) => isLoaded ? <ItemGroupSelector
              groupList={data}
              value={props.groupId}
              onChange={props.selectedGroups}
              rows={8}
            /> : <Loader defaultStyle />}
            </RemoteItems>
            :   
            <DeviceSelector
              value={props.deviceId}
              onChange={props.selectedDevices}
              rows={8}
            />}
        </div>
      ) : props.reportUnits === 'driver' && props.allowFields.includes('driver') ? (
        <div>
          {props.groupView === 2 ? 
            <RemoteItems type="Driver">
              {({data, isLoaded}) => isLoaded ? <ItemGroupSelector
                groupList={data}
                value={props.groupId}
                onChange={props.selectedGroups}
                rows={8}
              /> : <Loader defaultStyle />}
            </RemoteItems>
            : <DriverSelector
            value={props.driverId}
            disableModal={true}
            onChange={props.selectedDrivers}
            rows={8}
          />}
        </div>
        
      ) : props.reportUnits === 'vehicle' && props.allowFields.includes('vehicle') ? (
        <div>
          {props.groupView === 2 ? 
            <RemoteItems type="Vehicle">
              {({data, isLoaded}) => isLoaded ? <ItemGroupSelector
                groupList={data}
                value={props.groupId}
                onChange={props.selectedGroups}
                rows={8}
              /> : <Loader defaultStyle />}
            </RemoteItems>
            : <VehicleSelector
            value={props.vehicleId}
            onChange={props.selectedVehicles}
            rows={8}
          />}
        </div>
        
      ) : null }
    </Grid>)
    case 2: 
    return (
      <Grid container className='form-group'>
      <Grid item xs={12}>
        <DateRangePicker
          fill
          todayOnly={props.reportType ===21 ? true : false}

          error={
            props.timeError
          }
          selectedDate={props.selectedDate}
          label={
            props.translate('reportFrom') +
            ' ' +
            props.translate('reportTo')
          }
          reportsRanges
          onEvent={props.showDates}
        />
      </Grid>
      <Grid item xs={12}>
        <h4 style={{ marginTop: 15, marginBottom: 10 }}>
          {props.translate('reportForDays')}
        </h4>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            border: `1px solid ${props.themecolors.textColor}15`,
            display: 'inline-flex'
          }}
        >
          {day.map(option => (
            <div
              key={option.id}
              style={{
                display: 'inline-flex',
                textTransform: 'uppercase',
                fontWeight: 600,
                marginRight: option.id === '7' ? 0 : 1,

                backgroundColor: props.controlDays.includes(
                  parseInt(option.id)
                )
                  ? props.themecolors.themeLightColor
                  : 'transparent',
                color: props.controlDays.includes(
                  parseInt(option.id)
                )
                  ? props.themecolors.themeInverse : props.themecolors.textColor
              }}
            >
              <label
                style={{
                  padding: 5,
                  minWidth: 30,
                  cursor: 'pointer'
                }}
              >
                <input
                  type='checkbox'
                  key={option.id}
                  checked={props.controlDays.includes(
                    parseInt(option.id)
                  )}
                  onChange={e =>
                    props.onDayChange(e.target.checked, option)
                  }
                  style={{ display: 'none' }}
                />
                {props.translate(option.name)}
              </label>
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <h4 style={{ marginBottom: 30 }}>
          {props.translate('reportBetweenTime')}
        </h4>
      </Grid>
      <Grid item xs={12}>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <PrettoSlider
            value={props.controlTime}
            onChange={props.handleTimeControl}
            valueLabelDisplay='on'
            // aria-labelledby='range-slider'
            valueLabelFormat={valuetext}
            aria-label="pretto slider"
            max={1439}
            step={5}
          />
        </div>
      </Grid>
    </Grid>
    )
    case 3: 
      return (<Grid container className='form-group'>
                
    {props.allowFields.includes('eventType') ||
    props.allowFields.includes('notification') ? (
      <Grid item xs={12}>
        <div className='report-sidebar-switch'>
          {props.allowFields.includes('notification') ? (
            <Button
              onClick={() =>
                props.handleChangeType('notification')
              }
              className={
                props.eventType === 'notification'
                  ? ''
                  : 'button-inactive'
              }
            >
              {props.translate("event")}
            </Button>
          ) : null}
          {props.allowFields.includes('eventType') ? (
            <Button
              onClick={() =>
                props.handleChangeType('eventType')
              }
              className={
                props.eventType === 'eventType'
                  ? ''
                  : 'button-inactive'
              }
            >
              {props.translate("type")}
            </Button>
          ) : null}
        </div>
        {props.eventType === 'eventType' &&
        props.allowFields.includes('eventType') ? (
          <NotificationTypeSelector
            onChange={props.selectedNotifications}
          />
        ) : null}
        {props.eventType === 'notification' &&
        props.allowFields.includes('notification') ? (
          <NotificationSelector
            onChange={props.selectedEvents}
          />
        ) : null}
      </Grid>
    ) : null}
    </Grid>)

    case 4: 
      return (<Grid container className='form-group' spacing={2}>
        {props.allowFields.includes('geofence') ? <>
        <Grid item xs={12}><GeofenceSelector
          value={props.geofenceId}
          onChange={props.selectedGeofences}
          rows={8}
        />
        </Grid>
        <Grid item xs={12}>
          <TextField type='number' onChange={e => props.handleChange('geoTimeThreshold', e)} label={props.translate("geoTimeThreshold") +" ("+props.translate("sharedMinutes")+")"} value={props.geoTimeThreshold} fullWidth/>
        </Grid>
        </> : null}
      </Grid>)
    
    case 5: 
      return (<Grid container className='form-group'>
        {props.allowFields.includes('sensor') || props.allowFields.includes('digitalSensor') ? 
          <SensorSelector digital={props.allowFields.includes('digitalSensor')} allSensors={props.allSensors} value={props.sensorValues} handleChange={props.selectedSensors} translate={props.translate} />
        : null}
      </Grid>)

    
    default:
        return null
    }
  }

  // render () {

    
      const viewScreen = (
        <form style={{ padding: "16px 0 16px 10px" }}>
          {props.loading ? (
            <Loader />
          ) : (
            <>
              <h3 className='page-title'>
                {props.translate('reportForm')}
              </h3>
              <Stepper download emails translate={props.translate} activeStep={props.activeStep} reportType={props.reportType} getStepContent={getStepContent} steps={props.steps} handleNext={props.goToNextStep} handleBack={props.goToBackStep} themecolors={props.themecolors}/>
            </>
          )}
        </form>
      );

      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <Scrollbar>{viewScreen}</Scrollbar>
        </aside>
      )
  // }
}
const mapStateToProps = (state) => {
  return {
    notificationType: state.notificationType
  }
}

const day = [
  {
    id: '1',
    name: 'su'
  },
  {
    id: '2',
    name: 'mo'
  },
  {
    id: '3',
    name: 'tu'
  },
  {
    id: '4',
    name: 'we'
  },
  {
    id: '5',
    name: 'th'
  },
  {
    id: '6',
    name: 'fr'
  },
  {
    id: '7',
    name: 'sa'
  }
]

export default connect(mapStateToProps)(ReportsSidebar)


const SensorSelector = (props) => {
  const [scale, setScale] = useState(props.value)

  const addMore = () => {
      const newscale = [...scale]
      newscale.push({ input: "", index: "", value: ""})
      setScale(newscale)
  }

  const deleteRow = (index) => {
    let newscale = [...scale]
    newscale.splice(index, 1)
    setScale(newscale)

    props.handleChange(newscale)
  }
  
  const handleChange = (name, value, row) => {
    if(name === 'allSensors') {
      if(value.target.checked) {
        const reset = [{input: '', index: '', value: 'allSensors'}];
        setScale(reset)
        props.handleChange(reset)
      }
      else {
        const reset = [{input: '', index: '', value: ''}];
        setScale(reset)
        props.handleChange(reset)
      }
    } else {
      row[name] = value
      row.value = row.input+(row.index !== undefined ? row.index : "")

      const newscale = [...scale];
      setScale(newscale);

      props.handleChange(newscale)
    }
   
  }

  const suggestions = props.digital ? [
    'ignition',
    'panicButton',
    'externalPower',
    'enginStatus',
    'carAlarm',
    'doors',
    'customDiscrete'
    ] : [
    "driverUniqueId",
    "passengerUniqueId",
    "binUniqueId",
    "trailerUniqueId",
    "ignition",
    "panicButton",
    "externalPower",
    "enginStatus",
    "carAlarm",
    "doors",
    "fuel",
    "temperature",
    "rpm",
    "boardPower",
    "mileage",
    "speed",
    "counter",
    "averagFuelConsumption",
    "instantFuelConsumption",
    "flowMeter",
    "humidity",
    "custom",
    "weight"
  ];
  
  return <div style={{marginBottom: 16, width: '100%'}}>
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 0, marginTop: -22 }}>
        <div className="pull-right" style={{display: 'inline-flex', alignItems: 'center'}}>
          {!props.allSensors && <Tooltip title={props.translate('addMore')} classes={{
            popper: 'menu-popper',
            tooltip: 'menu-popper-tooltip'
          }}>
          <a
            href={null}
            onClick={addMore}
            style={{ color: 'inherit' }}
          >
            <AddBoxIcon/>
          </a>
        </Tooltip>}
        <Checkbox checked={props.allSensors} onChange={e => handleChange('allSensors', e)} label={props.translate('selectAll')} />
        </div>
        
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
      {!props.allSensors && scale.map((row, index) => (
          <Grid
            key={index}
            container
            spacing={0}
            className='sensors-rows'
          >
            <Grid item xs={scale.length > 1 ? 11 : 12}>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Autocomplete
                    value={row.input || ''}
                    suggestions={suggestions}
                    handleChange={(e, v) => handleChange(e, v, row)}
                    fieldName='input'
                    label={props.translate('sensorType')}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    type='number'
                    value={row.index}
                    onChange={e => handleChange('index', e.target.value, row)}
                    variant='outlined'
                    margin='dense'
                    label={props.translate('index')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {scale.length > 1 ? (
              <Grid
                item
                xs={1}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <DeleteIcon
                  onClick={() => deleteRow(index)}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </div>
}