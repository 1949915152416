
import React, { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography'
// import { makeStyles, createStyles } from '@mui/styles';
import { DeviceModal } from '../../Components/Devices/deviceModal'
import CustomDialog from '../../Components/common/Dialog'
import { connect, useDispatch } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import IconForm from './Components/IconForm'
import { SendCommand } from './Components/sendCommand'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import {
  checkPrivileges,
  errorHandler,
  postionAttributesTypeComp,
  selectAll
} from '../../Helpers'
import {
  // removedvehicle,
  updatevehicle,
  addvehicle
} from '../../Actions/Vehicles'
// import Button from '../../Components/common/Button'
import Sensors from './Components/sensors'
import Accumulators from './Components/accumulators'
import AdvanceForm from './Components/AdvanceForm'
import UnitSelectionForm from './Components/UnitSelectionForm'
import isEqual from 'react-fast-compare'
import Notifications from 'react-notification-system-redux'
// import MarkerMap from '../../Components/Maps'
import { ComputedAttributes } from './Components/computedAttributes'
import ReactHtmlParser from 'react-html-parser'
// import Style from 'style-it'
// import axios from 'axios'
// import { DriversModal } from '../../Components/Drivers/driversModal'

/* ACTIONS */
import {
  updateDevice
} from '../../Actions/Devices'
import { removeUser, addUser } from '../../Actions/Users'
import {
  driverDeviceWiseSelectedList,
  addNotification,
  userDeviceWiseSelectedList,
  removedUserDeviceWiseSelectedList,
  updateUserDeviceWiseSelectedList,
  addUserDeviceWiseSelectedList,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  notificationDeviceWiseSelectedList,
  getNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
  getNotification
} from '../../Actions/Notifications'
import {
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  getComputedAttributeDeviceWise,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList,
  getComputedAttributes
} from '../../Actions/ComputedAttributes'
import {
  addCommands,
  getCommandsDeviceWise,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList,
  getCommands
} from '../../Actions/Commands'
import {
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList
} from '../../Actions/Maintenance'
import { removeDevice } from './../../Actions/Devices'
/* ACTIONS END */

// common sections
import Notification from './Components/notification'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import SavedCommands from './Components/savedCommands'
import MaintenanceForm from './Components/maintenance'
import RawAttributes from './Components/rawAttributes'
import Users from './Components/users'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EcoDriving from './Components/EcoDriving'
import instance from '../../axios'
import FuelSettings from './Components/fuelSettings'


const VerticalTabs = () => {
  // const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('allAttribute');

  const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical';

  const handleChangeTab = (event, newTab) => {
    // Handle tab change here
    // For example, update state or perform other actions
    setCurrentTab(newTab);
  };

  return (
    <Tabs
      orientation={orientation}
      variant='scrollable'
      scrollButtons='auto'
      value={currentTab}
      onChange={handleChangeTab}
      aria-label='Vertical tabs example'
      className='custom-tabs'
      textColor='#FFFFFF'
    >
      {/* Add your tabs here */}
      <Tab
        value='allAttribute'
        label='All Attributes'
      />
      <Tab
        value='basic'
        label='Basic'
      />
      {/* Add more tabs as needed */}
    </Tabs>
  );
}

VerticalTabs.propTypes = {
  // Add any propTypes needed for the VerticalTabs component
};
const formDefault = {
  label: '',
  garage: '',
  model: '',
  vehicleType: '',
  expirationTime: '',
  vehicleLicensePlate: '',
  attributes: {},//vehicleLicensePlate: '',
  fuelType: 0
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ color: 'inherit', ...other.style }}
      className='custom-unit-tabpanel-container'
    >
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const vehicleDataFormat = (data, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...data.attributes },
  deviceId: data.deviceId ? data.deviceId : 0,
  label: data.label ? data.label : '',
  model: data.model ? data.model : '',
  garage: data.garage ? data.garage : '',
  vehicleType: data.vehicleType ? data.vehicleType : '',
  // vin:data.vin ? data.vin : '',
  vehicleLicensePlate: data.vehicleLicensePlate ? data.vehicleLicensePlate : '',
  fuelType: data.fuelType ? data.fuelType : 0,
  expirationTime: data.expirationTime ? data.expirationTime : '',
  areaId: data && data.areaId ? data.areaId.id : 0,
})
// --> parking form
const checkMinimalTripDistance = (val) => {
  return val
}

const maintenanceDataFormat = {
  attributes: { status: true },
  name: '',
  type: '',
  start: '',
  period: ''
}
const checkSpeedThreshold = (val, convertForApi) => {
  let initVal =
    (localStorage.getItem('userInfoFront') &&
      JSON.parse(localStorage.getItem('userInfoFront')).attributes.speedUnit) ||
    ''
  let value = val
  let nVal = 0
  if (convertForApi) {
    if (initVal && initVal !== 'kn') {
      if (initVal === 'kmh') {
        value = value * 0.539957
      } else if (initVal === 'mph') {
        value = value * 0.868976
      }
    }
    nVal = value
  } else {
    if (initVal && initVal !== 'kn') {
      if (initVal === 'kmh') {
        value = value * 1.852
      } else if (initVal === 'mph') {
        value = value * 1.15078
      }
    }
    nVal = parseFloat(Math.round(value).toFixed(5))
  }
  return nVal
}
const unitParkingAttFormat = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: checkMinimalTripDistance(100),
  speedThreshold: checkSpeedThreshold(1.61987),
  processInvalidPositions: false,
  useIgnition: 2,
  storeTime: 10,
  minimalFuelFillingVolume: 10,
  minimalFuelDrainVolume: 10,
  consecutiveFuelFillingTimeout: 120,
  consecutiveFuelDrainTimeout: 120,
  fuelAccuracy: 5,
  fuelCounter: "standard",
  basicL100Consumption: 10,
  stopTimeDurationForInterval: 1
}
const NotificationDataFormat = {
  always: '',
  attributes: {},
  calendarId: '',
  id: '',
  notificators: '', //eslint-disable-line no-duplicate-case
  type: ''
}
const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: '',
  attributes: { copyFromLast: false }
}
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: '',
  id: '',
  description: '',
  type: 'custom',
  textChannel: ''
}
let sensorDeviceids = []
const locations = [
  '#allAttribute',
  '#basic',
  '#vehicle',
  '#icon',
  '#sensors',
  '#sharedDeviceAccumulators',
  '#Advance',
  '#sharedDrivers',
  '#Access',
  '#sharedGeofences',
  '#sharedNotifications',
  '#sharedComputedAttributes',
  '#sharedSavedCommand',
  '#sharedMaintenance',
  '#ecoDriving',
  '#fuelSettings'
]

const MainUnitModal = (props) => {
  const [state, setState] = useState({
    currentTab: 'allAttribute',
    currentId: 0,
    selecteditem: null,
    activeOperation: '',
    driverObj: '',
    address: '',
    lat: '',
    lng: '',
    deviceModal: false,
    openDriverModal: false,
    unitParkingAttributesform: { ...unitParkingAttFormat },
    notificationForm: { ...NotificationDataFormat },
    computedAttForm: { ...ComputedAttributesDataFormat },
    savedCommandsForm: { ...savedCommandsDataFormat },
    sendCommandsForm: { ...savedCommandsDataFormat },
    maintenanceForm: { ...maintenanceDataFormat },
    isVisableASOneBtn: false,
    visableAttribute: false,
    visableNotification: false,
    visableSavedCommands: false,
    visableMaintenance: false,
    visableGeofence: false,
    isDisableFuelBtn: true,
    visableListNotification: true,
    visableListGeofence: true,
    visableListAttribute: true,
    visableListSavedCommands: true,
    visableListMaintenance: true,
    isVisableParkingAttBtn:false,
    notificationCallStatus: '',
    attributeCallStatus: '',
    commandsCallStatus: '',
    maintenanceCallStatus: '',

    suggestions: [],
    isAdvSettingEnable: false,

    geofenceModal: false,
    geofenceModalData: null,
    geofenceModalMapData: null,
    rowsPerPage: 5,
    copySensor: {},
    allDevices: [],
    showDialg: false,
    openResponseDialg: false,
    selectedvehicle: {},
    copiedData: [],
    vehiclesList: [],
    enableAssingBtn: false,
    isVisableUserBtn: false,
    editOption: false,
    form: { ...formDefault },
    onDeleteConfirmationNotification:false,
    onDeleteConfirmationUser:false
  })
const [isFormSubmit,setIsFormSubmit] = useState(false)


  const dispatchRedux = useDispatch()


  //useffect 2
  useEffect(() => {
    const { vehicles } = props;
    if (state.selecteditem) {
      const selectedvehicle = vehicles.find(vehicle => vehicle.deviceId === state.selecteditem.id);
      setState((prevState) => ({
        ...prevState,
        selectedvehicle,
        form: { ...selectedvehicle, deviceId: state.selecteditem.id }
      }))
    }
  }, [state.selecteditem])

  //useffect 3
  useEffect(() => {
    const { vehicles } = props;
    setState((prevState) => ({
      ...prevState,
      vehiclesList: vehicles.map((vehicle) =>
      ({
        valueType: 'string',
        vehicleLP: vehicle.vehicleLicensePlate,
        value: vehicle.id,
        label: vehicle.label,
        id: vehicle.id,
        deviceId: vehicle.deviceId
      }))

    }))
  }, [])
  //useffect 4
  useEffect(() => {
    if (props.id) {
      let suggestions = false;
      if (props.positions.length) {
        props.positions.forEach(p => {
          if (parseInt(props.id) === parseInt(p.deviceId)) {
            let inputs = Object.keys(p.attributes);
            if (p.protocol === 'bce') {
              for (let i = 1; i <= 16; i++) {
                inputs.push('input' + i);
              }
            }
            suggestions = true;
            setState((prevState) => ({
              ...prevState,
              suggestions: inputs
            }))
          }
        });
      }

      if (!props.disableHash && props.hash) {
        let hashIndex = locations.indexOf(props.hash);
        if (hashIndex !== -1) {
          setState((prevState) => ({
            ...prevState, currentTab: props.hash.substring(1, props.hash.length)
          }))
        }
      }

      if (!suggestions) {
        setState((prevState) => ({
          ...prevState, suggestions: []
        }))
      }

      if (props.id && props.device3?.length) {
        const currentId = parseInt(props.id)
        if (currentId !== state.currentId) {
          setState((prevState) => ({ ...prevState, currentId }))
          // getCurrent(currentId)
        }

      }
    }
  }, [props.id, props.hash, state.currentId]);

  useEffect(() => {
    let currentId = parseInt(props.id)
    getCurrent(currentId)
  }, [props.id])

  
  //useffect 5
  useEffect(() => {
    if (state.selectedvehicle) {
      setState((prevState) => ({
        ...prevState,
        editOption: true
      }))
    }
    setState((prevState) => ({
      ...prevState,
      currentId:
        props.devices3 && props.devices3.length
          ? parseInt(props.id)
          : 0
    })
    )

    if (props.disableHash && props.hash) {
      let hash = locations.indexOf(props.hash)
      if (hash !== -1) {
        setState((prevState) => ({
          ...prevState,
          currentTab: props.hash.substring(1, props.hash.length)
        }))
      }
    }
  }, [])

  useEffect(()=>{
    if (state.savedCommandsForm) {
      checkRequiredFields('Commands')
    }
  },[state.savedCommandsForm])

  useEffect(()=>{
    if (state.computedAttForm) {
      checkRequiredFields('computedAtt')
    }
  },[state.computedAttForm])

  useEffect(()=>{
    if (state.notificationCallStatus==="DELETE") {
      addNotifications()
    }
  },[state.notificationCallStatus])

  useEffect(()=>{
  if (state.commandsCallStatus==="DELETE") {
      addSavedCommands()
    }
  },[state.commandsCallStatus])

  useEffect(()=>{
    if (state.attributeCallStatus==="DELETE") {
      addComputedAttributes()
      }
    },[state.attributeCallStatus])

    useEffect(()=>{
      if (state.userCallStatus==="DELETE") {
        addUsers()
      }
      },[state.userCallStatus])

  //useffect 6
  useEffect(() => {
    if (state.notificationForm) {
      checkRequiredFields('notification')
    }
  else if (state.maintenanceForm) {
      checkRequiredFields('maintenance')
    }

    else if (state.maintenanceCallStatus) {
      addMaintenances()
    }



    else if (state.sendCommandsForm) {
      checkSendCommandRequiredFields()
    }
    else if (state.form) {
      checkRequiredFieldsChange()
    }
    // else if (state.unitParkingAttributesform) {
    //   isButtonVisable('parkingAttForm')
    // }
  }, [
    state.notificationForm,
    state.maintenanceForm,
    state.driverCallStatus,
    state.maintenanceCallStatus,
    state.sendCommandsForm, state.form, state.unitParkingAttributesform,
    state.resFormData
  ]);
  useEffect(()=>{
      isButtonVisable('parkingAttForm')
  },[state.resFormData,state.unitParkingAttributesform])

  useEffect(()=>{
    isButtonVisable('aFormOne')
},[state.aOneform])

  //useffect 1
  useEffect(() => {
    let { selectedvehicle } = state
    const filteredVehicle = props.vehicles?.find(vehicle => vehicle?.id === selectedvehicle?.id);

    if (state.form !== filteredVehicle) {
      if (state.editOption) {
        setState((prevState) => ({
          ...prevState,
          isVisableUserBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isVisableUserBtn: false
        }))
      }
    }
    checkRequiredFieldsChange()
  }, [state.form])

  const uc_submit = (disableWaslCall) => {
    let id = state?.selectedvehicle ? state.selectedvehicle.id : '';
    const { waslEnabled } = props?.serversettings?.attributes || {};
    if (waslEnabled) {
      setState((prevState) => ({
        ...prevState,
        waslStatus: <span className="loading-status-text"> <LoadingIndicator /> Processing</span>,
      }))
      setState((prevState) => ({
        ...prevState,
        vehicleStatus: <span className="loading-status-text"><LoadingIndicator /> Processing</span>,
      }))
    }
    const obj = vehicleDataFormat(state.form, id)
    setState((prevState) => ({
      ...prevState,
      isVisableUserBtn: false
    }))

    const data = {
      url: id ? `/api/vehicles/${id}` : `/api/vehicles/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `vehicleIsUpdated` : `vehicleIsAdded`,
    };
    instance({
      url: data.url,
      method: data.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(d => {
        dispatchRedux(id ? updatevehicle(d) : addvehicle(d))
        if (!disableWaslCall && waslEnabled && state.waslLinked) {
          submitWasl(obj, d);
        }
        else {
          handleAfterResponse();
        }
        dispatchRedux(
          Notifications.success({
            message: props.translate(data.successMsg),
            autoDismiss: 10
          })
        )
      })
      .catch(error => {
        errorHandler(error, dispatchRedux)
      })
  }

  const handleChangeLicenseExpDate = name => event => {
    if (name === 'expirationTime') {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          [name]: event.toISOString()
        }
      }))
    }
  }
  const setExpirationTime = e => {
    e.stopPropagation()
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        expirationTime: null
      }
    }))
  }

  const handleChange = (name, event) => {
    const { target } = event;
    let newFormState;

    if (target && target.type === 'checkbox') {
      newFormState = { [name]: target.checked };
    } else if (name === 'areaId') {
      newFormState = { [name]: event };
    } else if (name === "fuelType") {
      let value = target.value;
      newFormState = { [name]: parseInt(value) };
    } else {
      let value = target.value.toString();
      newFormState = { [name]: value };
    }
    setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        ...newFormState,
        attributes: { ...prevState.form.attributes }
      }
    }))
  }

  const handleChangeForAttributesValues = (name, event) => {
    let { attributes } = state.form
    const target = name === 'trasmissionType' ? event : event.target
    let value = name === 'trasmissionType' ? event : target.value.toString()
    if (value) {
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: { ...prevState.form.attributes, [name]: value }
        }
      }))
      if (state.editOption) {
        setState((prevState) => ({
          ...prevState,
          isVisableUserBtn: true
        }))
      }
    } else {
      delete attributes[name]
      setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          attributes: { ...attributes }
        }
      }))
      if (state.editOption) {
        setState((prevState) => ({
          ...prevState,
          isVisableUserBtn: true,
          waslLinked: false
        }))
      }
    }
  }


  const checkRequiredFieldsChange = () => {
    let { selectedvehicle } = state
    const filteredVehicle = props.vehicles?.find(vehicle => vehicle?.id === selectedvehicle?.id);
    const { label, vehicleLicensePlate } = state.form;
    let value = true;
    if (state.editOption) {
      value = !isEqual(state.form, filteredVehicle);
    }
    if (label && label.trim() && vehicleLicensePlate && vehicleLicensePlate.trim() && value) {
      setState((prevState) => ({
        ...prevState,
        isVisableUserBtn: true
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        isVisableUserBtn: false
      }))
    }
  }

  const getCurrent = (id) => {
    if (id) {
      let d = props.devices3.find(
        device => parseInt(device.id) === parseInt(id)
      )

      if (d && d?.id) {
        setState((prevState) => ({
          ...prevState,
          selecteditem: {
            ...d,
            attributes: {
              ...d.attributes,
              storeTime: d.attributes.storeTime || 10
            }
          },
          isVisableParkingAttBtn: false
        }))

        if (state.selecteditem) {
          setState((prevState) => ({
            ...prevState,
            deviceModal: prevState.selecteditem.attributes?.protocol === "teltonika" ? true : false
          }))
        }
      }
      // for driver
      /* if (checkPrivileges('driver')) {
        // fetch(`/api/drivers?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
    instance({
      url: `/api/drivers`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        deviceId: id,
        all: true
      }
    })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(driver => {
              setState(
                {
                  mulitDriversSelect: driver,
                  isDriverRecived: true
                },
                () => {
                  updateSelectedDrivers()
                }
              )
            })
        //   }
        // })
      } */
      // for users
      if (checkPrivileges('user')) {
        // fetch(`/api/users/access?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/users/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            deviceId: id,
            all: true
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(users => {
            setState((prevState) => ({
              ...prevState,
              mulitUsersSelect: users,
              isUsersRecived: true
            }))
            let allData = props.users
            if (users.length) {
              allData.map(item => {
                item.check = false
                item.indeterminate = false
                item.readonly = false

                users.map(main => {
                  if (item.id && main.userId && item.id === main.userId) {
                    // for parent
                    if (main.parent) {
                      item.check = true
                      main.check = true

                      item.readonly = true
                      main.readonly = true
                    } else {
                      if (main.directAccess) {
                        item.readonly = false
                        main.readonly = false
                        item.check = true
                        main.check = true
                      } else {
                        item.readonly = false
                        main.readonly = false
                        item.check = false
                        main.check = false
                        item.indeterminate = true
                      }
                    }
                  }
                  return null
                })
                return null
              })
            } else {
              allData.map(n => (n.check = false))
            }
            dispatchRedux(userDeviceWiseSelectedList(allData))
          })
          //   }
          //   else{
          //     throw response
          //   }
          // })
          .catch(() => {
            // errorHandler(error,dispatchRedux)
          })
      }

      // for geofences
      /* if (checkPrivileges('geofence')) {
        // fetch(`/api/geofences?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/geofences`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            deviceId: id,
            all: true
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(geofences => {
                  setState((prevState)=>({
          ...prevState,
                multiGeofencesSelect: geofences,
                isGroRecived: true
  }))
   
              let allData = props.geofences
              if (geofences.length) {
                allData.map(item => {
                  item.check = false
                  geofences.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              dispatchRedux(getGeofenceDeviceWise(geofences))
              dispatchRedux(geofenceDeviceWiseSelectedList(allData))
            })
        //   }
        // })
      } */
      // for Notification
      if (checkPrivileges('notification')) {
        // fetch(`/api/notifications?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })

        instance({
          method: 'GET',
          url: `/api/notifications/get?limit=${-1}`
        })
          .then(response => {
            // if(response && response.status === 200) {
            dispatchRedux(getNotification(response.data))
            // }
            instance({
              url: `/api/notifications`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                deviceId: id,
                all: true
              }
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then(notifications => {
                let allData = props.notification
                if (notifications.length) {
                  allData.map(item => {
                    item.check = false
                    notifications.map(main => {
                      if (item.id && main.id && item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }
                dispatchRedux(getNotificationDeviceWise(notifications))
                dispatchRedux(notificationDeviceWiseSelectedList(allData))
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch(() => {
                // errorHandler(error,dispatchRedux)
              })
          }).catch(() => {
            // errorHandler(error, dispatchRedux)
          })

      }

      // for ComputedAttributes
      if (checkPrivileges('attribute') && (props.logInUser && props.logInUser.id)) {
        instance({
          method: 'GET',
          url: `/api/attributes/computed/get?userId=${props.logInUser.id}&category=elogic&all=true&page=1&limit=-1`,
        })
          .then(response => {
            // if(response && response.status === 200) {
            dispatchRedux(getComputedAttributes(response.data))
            // }
            instance({
              url: `/api/attributes/computed`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                deviceId: id,
                category: 'all',
                all: true
              }
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then(attributes => {
                let allData = props.computedAttributes
                if (attributes.length) {
                  allData.map(item => {
                    item.check = false
                    attributes.map(main => {
                      if (item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }
                dispatchRedux(getComputedAttributeDeviceWise(attributes))
                dispatchRedux(
                  computedAttributeDeviceWiseSelectedList(allData)
                )
                setState((prevState) => ({
                  ...prevState,
                  isComputedAttributesRecived: true
                }))
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch(() => {
                // errorHandler(error,dispatchRedux)
              })

          }).catch(() => {
            // errorHandler(error, dispatchRedux)
          })
      }
      // for SavedCommands
      if (checkPrivileges('command') && (props.logInUser && props.logInUser.id)) {
        // fetch(`/api/commands?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })

        instance({
          method: 'GET',
          url: `/api/commands/get?userId=${props.logInUser.id}&limit=${-1}&all=${true}`,
        })
          .then(response => {
            // if(response && response.status === 200) {
            dispatchRedux(getCommands(response.data))
            // }
            instance({
              url: `/api/commands`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params: {
                deviceId: id,
                all: true
              }
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then(commands => {
                let data = commands
                data.map(item => {
                  item.textChannel = item.textChannel ? 'Yes' : 'No'
                  return null
                })
                let allData = props.commands
                if (commands.length) {
                  allData.map(item => {
                    item.check = false
                    commands.map(main => {
                      if (item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                } else {
                  allData.map(n => (n.check = false))
                }

                dispatchRedux(getCommandsDeviceWise(data))
                dispatchRedux(commandsDeviceWiseSelectedList(allData))
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch(() => {
                // errorHandler(error,dispatchRedux)
              })
          }).catch(() => {
            // errorHandler(error, dispatchRedux)
          })
      }
      // for Maintenance
      if (checkPrivileges('maintenance')) {
        // fetch(`/api/maintenance/get?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/maintenance/get`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            deviceId: id,
            all: true
          }
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then(data => {
            // let allData = props.maintenance
            if (data.data.length) {
              // allData.map(item => {
              //   item.check = false
              //   delete item.progress
              //   let main = data.data.find(main => item.id === main.id)
              //   if (main && main.attributes) {
              data.data.map(item => {

                item.check = true
                // main.check = true
                item.progress = {
                  value: item.attributes.progress,
                  bar: true
                }
              })
              // }
              //   return null
              // })
            }
            // else {
            //   allData.map(n => {
            //     n.check = false
            //     delete n.progress
            //   })
            // }
            dispatchRedux(getMaintenanceDeviceWise(data.data))
            dispatchRedux(maintenanceDeviceWiseSelectedList(data.data))
          })
          //   }
          //   else{
          //     throw response
          //   }
          // })
          .catch(() => {
            // errorHandler(error,dispatchRedux)
          })
      }

      setState((prevState) => ({
        ...prevState,
        gOneform: {},
        aOneform: {},
        resFormData: {},
        mulitDriversSelect: '',
        mulitUsersSelect: '',
        multiGeofencesSelect: '',
        isDriverRecived: false,
        isGroRecived: false,
        unitParkingAttributesform: { ...unitParkingAttFormat },
        isHaveAccess: 'attributeCreate'
      }))

      if (d && d?.id) {
        let { name, uniqueId, groupId, phone, attributes, model } = d
        if (model && model === 'FX Tracker') {
          tabChange('', 'allAttribute')
        }
        let { category } = d
        let f_3 = { category }
        let att = []
        if (attributes) {
          att = Object.keys(attributes).reduce((array, key) => {
            return [...array, [key, attributes[key]]]
          }, [])
        }
        let devicePassword = ''
        let mtime = { minimalTripDuration: 60 },
          mt = { minimalTripDistance: checkMinimalTripDistance(100) },
          st = { speedThreshold: checkSpeedThreshold(1.61987) }
        att.map(item => {
          if (item[0] === 'minimalTripDuration') {
            mtime.minimalTripDuration = Math.round(item[1])
          }
          if (item[0] === 'minimalTripDistance') {
            mt.minimalTripDistance = checkMinimalTripDistance(item[1])
          }
          if (item[0] === 'speedThreshold') {
            st.speedThreshold = checkSpeedThreshold(item[1])
          }
          return null
        })
        let f_1 = { name, uniqueId, groupId, phone, devicePassword }
        attributes = { ...unitParkingAttFormat, ...d.attributes }
        setState((prevState) => ({
          ...prevState,
          gOneform: { ...f_1 },
          aOneform: { ...f_3 },
          unitParkingAttributesform: {
            ...attributes,
            ...mtime,
            ...st,
            ...mt
          },
          resFormData: { ...d, attributes }
        }))
      }
      return { d }
    }
  }

  const advenceSectionOneformSubmit = (category) => {
    onFormSubmit(category)
  }

  const onFormSubmit = (targetForm) => {
    setState((prevState) => ({
      ...prevState,
      resFormData: { ...prevState.resFormData, ...targetForm }
    }))
    formSubmit()
  }

  const tabChange = (event, currentTab) => {
    setState((prevState) => ({
      ...prevState,
      driverObj: '',
      openDriverModal: false,
      activeOperation: ''
    }))
    if (props.disableHash) {
      setState((prevState) => ({
        ...prevState,
        currentTab
      }))
    } else {
      props.history.push('/units/' + props.id + '#' + currentTab)
    }
  }

  // const openDriverModal = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     openDriverModal: true,
  //     activeOperation: 'add',
  //     driverObj: ''
  //   }))
  // }


  const onCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      activeOperation: '',
      driverObj: '',
      openDriverModal: false,
      isSendCommandModal: false
    }))
    updateSelectedDrivers()
  }

  const addNewGroups = () => {
    setState((prevState) => ({
      ...prevState,
      isaddGroupVisable: !prevState.isaddGroupVisable
    }))

  }

  // const changeAttribute2 = (name, event) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     selecteditem: { ...prevState.selecteditem }
  //   }))
  //   setForm((prevForm) => ({
  //     ...prevForm,
  //     attributes: {
  //       ...state.selecteditem.attributes,
  //       [name]: event.target.value
  //     }
  //   }))
  // }

  const logo_handelChanges = (item) => {
    setState((prevState) => ({
      ...prevState,
      aOneform: {
        category: item.name
      },
      isVisableASOneBtn: !isEqual(item.name, prevState.selecteditem?.category),
      selecteditem: {
        ...prevState.selecteditem,
        category: item.name
      }
    }))
  }

  const onListNotification = () => {
    setState((prevState) => ({
      ...prevState,
      visableListNotification: true
    }))
  }

  const onListComputedAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      visableListAttribute: true
    }))
  }

  const onListSavedCommands = () => {
    setState((prevState) => ({
      ...prevState,
      visableListSavedCommands: true
    }))
  }

  const onListMaintenance = () => {
    setState((prevState) => ({
      ...prevState,
      visableListMaintenance: true
    }))
  }

  const onCreateNewNotification = () => {
    props?.history.push('/notificationCreate')
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      notificationCallStatus: 'POST',
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'notificationCreate'
    }))

  }

  const onCreateNewAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      attributeCallStatus: 'POST',
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'attributeCreate'
    }))
  }

  const onCreateNewSavedCommands = () => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      commandsCallStatus: 'POST',
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'commandCreate'
    }))

    instance({
      url: `/api/commands/types`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: {
        deviceId: state.currentId,
        textChannel: false
      }
    })
      .then(commands => {
        let commandsList = []
        if (commands?.length) {
          commands?.map(item => {
            commandsList.push({ key: item.type, name: item.type })
            return null
          })
          setState((prevState) => ({
            ...prevState,
            formType: commandsList
          }))
        }

      })
      .catch(() => {
        // errorHandler(error,dispatchRedux)
      })

  }

  const onCreateNewMaintenance = () => {
    props?.history.push('/maintenanceCreate')
  }

  const notification_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...props.selectednotification]
    let obj = {
      deviceId: props?.id,
      notificationId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          dispatchRedux(updateNotificationDeviceWiseSelectedList(item))
          dispatchRedux(RemoveNotificationDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate(
                'notificationUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          dispatchRedux(updateNotificationDeviceWiseSelectedList(item))
          dispatchRedux(addNotificationDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate('notificationAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
        // }
      })
      .catch(error => { errorHandler(error, dispatchRedux) })
  }

  const user_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...props.selecteduser]
    let obj = {
      userId: item.id,
      deviceId: parseInt(props?.id)
    }
    if (value) {
      method = 'POST'
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData?.map(dt => {
            if (dt.id === item.userId) {
              dt.check = false
            }
            return null
          })

          dispatchRedux(
            Notifications.success({
              message: props.translate('userUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData?.map(dt => {
            if (dt.id === item.userId) {
              dt.check = true
            }
            return null
          })
          dispatchRedux(
            Notifications.success({
              message: props.translate('userAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
        dispatchRedux(userDeviceWiseSelectedList(allData))
        // } else {
        //   throw response
        // }
      })
      .catch(error => { errorHandler(error, dispatchRedux) })

  }

  const computedAttributes_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = props.computedAttributes
    let obj = {
      deviceId: props?.id,
      attributeId: item.id
    }
    if (value) {
      method = 'POST'
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          dispatchRedux(
            updateComputedAttributeDeviceWiseSelectedList(item)
          )
          dispatchRedux(RemoveComputedAttributeDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate(
                'computedAttributeUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          dispatchRedux(
            updateComputedAttributeDeviceWiseSelectedList(item)
          )
          dispatchRedux(addComputedAttributeDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate(
                'computedAttributeAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        }
      })
      .catch(() => {
        // if (e && e.text) {
        //   e.text().then(err => {
        dispatchRedux(
          Notifications.error({
            message:
              'Invalid Parameter. You cannot assign ' +
              item.description +
              ' parameter because device is not sending ' +
              item.attribute +
              ' data.',
            autoDismiss: 10
          })
        )
        setState((prevState) => ({
          ...prevState, visableListAttribute: false
        }))
        setState((prevState) => ({
          ...prevState, visableListAttribute: true
        }))
      })

  }

  const savedCommands_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = props?.commands
    let obj = {
      deviceId: props?.id,
      commandId: item.id
    }
    if (value) {
      method = 'POST'
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          dispatchRedux(updateCommandsDeviceWiseSelectedList(item))
          dispatchRedux(RemoveCommandsDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate(
                'savedCommandsUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          dispatchRedux(updateCommandsDeviceWiseSelectedList(item))
          dispatchRedux(addCommandsDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate(
                'savedCommandsAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        }
        // }
      })
      .catch(error => { errorHandler(error, dispatchRedux) })
  }

  const maintenance_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = maintenance
    let obj = {
      deviceId: props?.id,
      maintenanceId: item.id
    }
    if (value) {
      method = 'POST'
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          dispatchRedux(updateMaintenanceDeviceWiseSelectedList(item))
          dispatchRedux(RemoveMaintenanceDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate(
                'maintenanceUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData?.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          dispatchRedux(updateMaintenanceDeviceWiseSelectedList(item))
          dispatchRedux(addMaintenanceDeviceWise(item))
          dispatchRedux(
            Notifications.success({
              message: props.translate('maintenanceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
        // }
      })
      .catch(error => { errorHandler(error, dispatchRedux) })
  }
  const Devices_Selection = (checked, item) => {
    let allData = props.devices2
    item.check = checked
    allData?.map(dt => {
      if (dt.id === item.id) {
        dt.check = checked
      }
    })
    sensorDeviceids.push(item.id)
    setState((prevState) => ({
      ...prevState,
      sensorDeviceids: sensorDeviceids,
      allDevices: allData
    }))
  }

  const selectAllCopySensors = (e) => {

    let allData = props.devices2
    allData?.map(dt => {
      dt.check = e ? true : false
      sensorDeviceids.push(dt.id)
    })
    if (e) {
      setState((prevState) => ({
        ...prevState,
        sensorDeviceids: sensorDeviceids,
        allDevices: allData
      }))
    }
    else {
      sensorDeviceids = []
      setState((prevState) => ({
        ...prevState,
        sensorDeviceids: [],
        allDevices: allData
      }))
    }
  }

  const closeDialog = () => {
    let allData = props.devices2
    allData?.map(dt => {
      dt.check = false
    })
    setState((prevState) => ({
      ...prevState,
      showDialg: false,
      openResponseDialg: false,
      sensorDeviceids: [],
      allDevices: allData
    }))
    sensorDeviceids = []
  }

  const onCopy = (item) => {
    setState((prevState) => ({
      ...prevState,
      showDialg: true
    }))
    selectedCopySensor(item)
  }

  const getIdsfromChild = (ids) => {
    sensorDeviceids = ids
    setState((prevState) => ({
      ...prevState,
      sensorDeviceids: sensorDeviceids,
    }))
  }

  const submitCopySenors = () => {
    let obj = []
    instance({
      url: `/api/sensors/copy/${state.copySensor.id}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { deviceIds: sensorDeviceids }
    })
      .then(response => {
        let allData = props.devices2
        setState((prevState) => ({
          ...prevState,
          showDialg: false,
          openResponseDialg: true
        }))
        for (const [key, value] of Object.entries(response)) {
          allData?.map(dt => {
            if (dt.id == key) {
              dt.message = value
              obj.push(dt)
            }
          })
        }
        setState((prevState) => ({
          ...prevState,
          copiedData: obj
        }))
        dispatchRedux(
          Notifications.success({
            message: props.translate('sensorCopied'),
            autoDismiss: 10
          })
        )
        // }
      })
      .catch(error => { errorHandler(error, dispatchRedux) })
  }

  const selectedCopySensor = (item) => {
    setState((prevState) => ({
      ...prevState,
      copySensor: item
    }))
  }

  const notificationHandle = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      notificationForm: {
        ...prevState.notificationForm,
        [name]: value.id
      }
    }))
  }

  const attributeHandle = (name, value) => {
    let selectedValue = postionAttributesTypeComp
      .filter(m => m.key === value)
      ?.map(m => m.valueType)
    if (name === 'copyFromLast') {
      setState((prevState) => ({
        ...prevState,
        computedAttForm: {
          ...prevState.computedAttForm,
          attributes: {
            [name]: value
          }
        }
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        computedAttForm: {
          ...prevState.computedAttForm,
          [name]: value,
          type: selectedValue[0] ? selectedValue[0] : 'string'
        }
      }))
    }
  }

  const savedCommandsHandle = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      savedCommandsForm: {
        ...prevState.savedCommandsForm,
        type: value.id
      }
    }))
  }

  const maintenancesHandle = (name, value) => {
    let obj = { ...state.maintenanceForm, [name]: value.id }

    if (state.visableMaintenance && name === 'type') {
      let pos = props?.positions?.find(
        p => p.deviceId === parseInt(props?.id)
      )
      if (pos) {
        if (value.id === 'totalDistance') {
          obj.start = parseFloat(
            (pos.attributes.totalDistance / 1000).toFixed(2)
          )
        } else if (value.id === 'hours') {
          obj.start = parseFloat(
            (pos.attributes.hours / (1000 * 3600)).toFixed(2)
          )
        } else if (value.id === 'date') {
          obj.start = 1
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...obj
      }
    }))
  }

  const saveToParent = (obj) => {
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        attributes: {
          ...prevState.maintenanceForm.attributes,
          ...obj
        }
      }
    }))
  }

  // 5th
  const addNotifications = () => {
    let obj = { ...state.notificationForm }
    if (
      (state.notificationCallStatus === 'PUT' ||
        state.notificationCallStatus === 'POST') &&
      obj.notificators &&
      obj.notificators?.length
    ) {
      let notificatorsArr = []
      obj.notificators?.map(v => {
        notificatorsArr.push(v.value)
        return v
      })
      let notificators = notificatorsArr.join(',')
      obj.notificators = notificators
    }
    let params = ''
    if (
      state.notificationCallStatus === 'PUT' ||
      state.notificationCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      instance({
        url: `/api/notifications/${params}`,
        method: `${state.notificationCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: {
          ...obj
        }
      })
        .then(notifications => {
          // if (response.ok) {
          if (state.notificationCallStatus === 'DELETE') {
            dispatchRedux(removeNotificationDeviceWiseSelectedList(obj))
            dispatchRedux(removedNotification(obj))
            dispatchRedux(
              Notifications.success({
                message: props.translate('notificationIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationNotification: false,
              attributeChangesMessage: ''
            }))
          } else {

            setState((prevState) => ({
              ...prevState,
              visableNotification: false
            }))
            if (state.notificationCallStatus === 'PUT') {
              let noti = { ...notifications, check }
              dispatchRedux(
                updateNotificationDeviceWiseSelectedList(noti)
              )
              dispatchRedux(addNotification(noti))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('notificationIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              notification_Selection(true, notifications)
              let noti = { ...notifications, check: true }
              dispatchRedux(addNotificationDeviceWiseSelectedList(noti))
              dispatchRedux(addNotification(noti))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('notificationIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            }
          }
          setState((prevState) => ({
            ...prevState,
            notificationCallStatus: ''
          }))
        })
        .catch(error => { errorHandler(error, dispatchRedux) })
    }
  }

  const addComputedAttributes = () => {
    let obj = state.computedAttForm
    let params = ''
    if (
      state.attributeCallStatus === 'PUT' ||
      state.attributeCallStatus === 'DELETE'
    ) {
      params = obj.id
      delete obj.deviceId
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      if(obj.copyFromLast){
        delete obj.copyFromLast
      }
      // console.log("obj",obj)
      // fetch(`/api/attributes/computed/${params}`, {
      //   method: `${state.attributeCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })

      setIsLoading(true)
      instance({
        url: `/api/attributes/computed/${params}`,
        method: `${state.attributeCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(Attribute => {
          // if (response.ok) {
          if (state.attributeCallStatus === 'DELETE') {
            dispatchRedux(
              removedComputedAttributeDeviceWiseSelectedList(obj)
            )
            dispatchRedux(removedComputedAttribute(obj))
            dispatchRedux(
              Notifications.success({
                message: props.translate('computedAttributeIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationAttributes: false
            }))
          } else {
            setState((prevState) => ({
              ...prevState,
              visableAttribute: false
            }))
            if (state.attributeCallStatus === 'PUT') {
              dispatchRedux(
                updateComputedAttributeDeviceWiseSelectedList(Attribute)
              )
              dispatchRedux(addComputedAttribute(Attribute))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('computedAttributeIsUpdated'),
                  autoDismiss: 10
                })
              )
            } else {
              computedAttributes_Selection(true, Attribute)
              dispatchRedux(
                addComputedAttributeDeviceWiseSelectedList(Attribute)
              )
              dispatchRedux(addComputedAttribute(Attribute))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('computedAttributeIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                visableListAttribute: false
              }))
            }
            // })
          }
          setState((prevState)=>({
            ...prevState,
            attributeCallStatus:""
          }))
      setIsLoading(false)

          // }
          // else{
          //   throw response
          // }
        })
        .catch(error => { errorHandler(error, dispatchRedux) })
    }
  }

  const addSavedCommands = () => {
    let {
      attributes,
      id,
      description,
      type,
      textChannel
    } = state.savedCommandsForm
    attributes = attributes || {}
    id = id || -1
    let obj = { attributes, id, description, type, textChannel }
    obj.deviceId = 0
    let params = ''
    if (
      state.commandsCallStatus === 'PUT' ||
      state.commandsCallStatus === 'DELETE'
    ) {
      params = obj.id
    }

    if (obj) {
      setIsLoading(true)
      instance({
        url: `/api/commands/${params}`,
        method: `${state.commandsCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(commands => {
          // if (response.ok) {
          if (state.commandsCallStatus === 'DELETE') {
            dispatchRedux(removedCommandsDeviceWiseSelectedList(obj))
            dispatchRedux(removedCommands(obj))
            dispatchRedux(
              Notifications.success({
                message: props.translate('savedCommandsIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationCommand: false
            }))


          } else {
            setState((prevState) => ({
              ...prevState,
              visableSavedCommands: false
            }))
            if (state.commandsCallStatus === 'PUT') {
              dispatchRedux(
                updateCommandsDeviceWiseSelectedList(commands)
              )
              dispatchRedux(addCommands(commands))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('savedCommandsIsUpdated'),
                  autoDismiss: 10
                })
              )
            } else {
              savedCommands_Selection(true, commands)
              dispatchRedux(addCommandsDeviceWiseSelectedList(commands))
              dispatchRedux(addCommands(commands))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('savedCommandsIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                visableListSavedCommands: false
              }))
            }
            // })
          }
          setState((prevState)=>({
            ...prevState,
            commandsCallStatus:""
          }))
      setIsLoading(false)

          // }
          // else{
          //   throw response
          // }
        })
        .catch(error => { 
      setIsLoading(false)
          
          errorHandler(error, dispatchRedux) })
    }
  }

  const addMaintenances = () => {
    let obj = state.maintenanceForm
    if (state.maintenanceForm.type === 'totalDistance') {
      obj.start = parseFloat(state.maintenanceForm.start * 1000)
      obj.period = parseFloat(state.maintenanceForm.period * 1000)
    } else if (state.maintenanceForm.type === 'hours') {
      obj.start = parseFloat(state.maintenanceForm.start * (1000 * 3600))
      obj.period = parseFloat(state.maintenanceForm.period * (1000 * 3600))
    } else if (state.maintenanceForm.type === 'date') {
      obj.start = 1
    }

    let params = ''
    if (
      state.maintenanceCallStatus === 'PUT' ||
      state.maintenanceCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let c = false
      let p = 0
      if (obj.check !== undefined) {
        c = obj.check
        p = obj.progress
        delete obj.check
      }

      instance({
        url: `/api/maintenance/${params}`,
        method: `${state.maintenanceCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(Maintenance => {
          // if (response.ok) {
          if (state.maintenanceCallStatus === 'DELETE') {
            dispatchRedux(removedMaintenanceDeviceWiseSelectedList(obj))
            dispatchRedux(removedMaintenance(obj))
            dispatchRedux(
              Notifications.success({
                message: props.translate('maintenanceIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationMaintenance: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(Maintenance => {
            if (state.maintenanceCallStatus === 'PUT') {
              dispatchRedux(
                updateMaintenanceDeviceWiseSelectedList({
                  ...Maintenance,
                  check: c,
                  progress: p
                })
              )
              dispatchRedux(
                addMaintenance({ ...Maintenance, check: c })
              )

              dispatchRedux(
                Notifications.success({
                  message: props.translate('maintenanceIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))

            } else {
              maintenance_Selection(true, Maintenance)
              dispatchRedux(
                addMaintenanceDeviceWiseSelectedList(Maintenance)
              )
              dispatchRedux(addMaintenance(Maintenance))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('MaintenanceIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: '',
                visableListMaintenance: false
              }))

            }
            setState((prevState) => ({
              ...prevState,
              visableMaintenance: false,
              attributeChangesMessage: ''
            }))
          }
        })
        .catch(error => { errorHandler(error, dispatchRedux) })
    }
  }

  const addUsers = () => {
    let obj = state.userForm
    let params = ''
    if (
      state.userCallStatus === 'PUT' ||
      state.userCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      instance({
        url: `/api/users/${params}`,
        method: `${state.userCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
        .then(User => {
          if (state.userCallStatus === 'DELETE') {
            dispatchRedux(removedUserDeviceWiseSelectedList(obj))
            dispatchRedux(removeUser(obj))
            dispatchRedux(
              Notifications.success({
                message: props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmationUser: false,
              attributeChangesMessage: ''
            }))
          } else {
            // response.json().then(User => {
            if (state.maintenanceCallStatus === 'PUT') {
              dispatchRedux(updateUserDeviceWiseSelectedList(User))
              dispatchRedux(addUser(User))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('maintenanceIsUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: ''
              }))
            } else {
              maintenance_Selection(true, User)
              dispatchRedux(addUserDeviceWiseSelectedList(User))
              dispatchRedux(addUser(User))
              dispatchRedux(
                Notifications.success({
                  message: props.translate('UserIsCreated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({
                ...prevState,
                attributeChangesMessage: '',
                visableListUser: false
              }))


            }
            setState((prevState) => ({
              ...prevState,
              visableUser: false,
              attributeChangesMessage: ''
            }))
          }
setState((prevState)=>({
  ...prevState,
  userCallStatus:""
}))
        })
        .catch(error => {
          setState((prevState) => ({
            ...prevState,
            onDeleteConfirmationUser: false,
  userCallStatus:""
          }))
          errorHandler(error, dispatchRedux)
         })
    }
  }

  const editNotification = (item) => {
    props?.history.push(`/notificationUpdate/${item[0].id}`)
    let a =
      item && item[0] && item[0].notificators
        ? item[0].notificators.split(',')
        : []
    let notificators = a?.length
      ? a?.map(e => ({ label: props.translate(e), value: e }))
      : []
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      notificationCallStatus: 'PUT',
      visableNotification: true,
      notificationForm: { ...item[0], notificators },
      addButtonStatus: 'sharedUpdate',
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      attributeChangesMessage: '',
      itemAttributes: item[0].attributes,
      isHaveAccess: 'notificationUpdate'
    }))
  }

  const editComputedAttributes = (item) => {
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: item[0],
      attributeCallStatus: 'PUT',
      visableAttribute: true,
      computedAttForm: {
        ...item[0],
        expression: ReactHtmlParser(item[0].expression)[0]
      },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'attributeUpdate'
    }))
  }

  const editCommands = (item) => {
    let commands = JSON.parse(JSON.stringify(item[0]))
    setState((prevState) => ({
      ...prevState,
      isAddVisableBtn: false,
      selecteItem: commands,
      commandsCallStatus: 'PUT',
      visableSavedCommands: true,
      savedCommandsForm: {
        ...commands,
        textChannel: commands.textChannel === 'Yes' ? true : false
      },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'commandUpdate'
    }))
  }


  const editMaintenance = (item) => {
    props?.history.push(`/maintenanceUpdate/${item[0].id}`)
  }

  const addMaintenanceAttributes = () => {
    setState((prevState) => ({
      ...prevState,
      isAddAttributes: true,
      componentType: 'Maintenance',
      itemAttributes: state.maintenanceForm.attributes
    }))
  }

  const notification_handleChange2 = (value, option) => {
    if (option && option.name === 'calendarId') {
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [option.name]: value.value
        }
      }))
    } else if (typeof option === 'object' && Object.keys(option).length) {
      // for select
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [option.name]: value
        }
      }))
    } else if (value === 'type') {
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          [value]: option
        }
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        notificationForm: {
          ...prevState.notificationForm,
          attributes: {
            ...prevState.notificationForm.attributes,
            [value]: option
          }
        }
      }))
    }
  }

  const notification_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    setState((prevState) => ({
      ...prevState,
      notificationForm: {
        ...prevState.notificationForm,
        [name]: value
      }
    }))
  }

  // const geofence_handleChange = name => event => {
  //   const { target } = event
  //   let value = target.type === 'checkbox' ? target.checked : target.value
  //   setState((prevState) => ({
  //     ...prevState,
  //     geofenceForm: {
  //       ...prevState.geofenceForm,
  //       [name]: value
  //     }

  //   }))
  //   checkRequiredFields('geofence')
  // }

  // const driver_handleChange = name => event => {
  //   const { target } = event
  //   let value = target.type === 'checkbox' ? target.checked : target.value
  //   setState((prevState) => ({
  //     ...prevState,
  //     driverForm: {
  //       ...prevState.driverForm,
  //       [name]: value
  //     }

  //   }))
  //   checkRequiredFields('driver')
  // }

  const C_Att_handleChange = name => event => {
    const { target } = event
    setState((prevState) => ({
      ...prevState,
      computedAttForm: {
        ...prevState.computedAttForm,
        [name]: target.value
      }
    }))
  }


  const savedCommands_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (name === 'attributes') {
      let att = state.savedCommandsForm.attributes
      let newAtt = { [target.name]: target.value }
      att = { ...att, ...newAtt }
      value = att
      setState((prevState) => ({
        ...prevState,
        savedCommandsForm: {
          ...prevState.savedCommandsForm,
          [target.name]:
            target.type === 'checkbox' ? target.checked : target.value
        }
      }))
    }
    setState((prevState) => ({
      ...prevState,
      savedCommandsForm: {
        ...prevState.savedCommandsForm,
        [name]: value
      }
    }))
  }

  const maintenance_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        [name]: value
      }
    }))
  }

  const maintenance_handleChange_attr = name => date => {
    const value =
      name === 'repeat' || name === 'status' ? date.target.checked : date
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: {
        ...prevState.maintenanceForm,
        attributes: {
          ...prevState.maintenanceForm.attributes,
          [name]: value
        }
      }
    }))
  }

  const sendCommandsModal = () => {
    //fetch command list data
    let Data1 = [],
      Data2 = [],
      isRecived1 = false,
      isRecived2 = false
    if (state.currentId) {

      instance({
        url: `/api/commands/send`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          deviceId: state.currentId,
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then(commands => {
          if (commands?.length) {
            commands?.map(item => {
              Data1.push({ ...item, key: item.id, name: item.description })
              return null
            })
          }
          isRecived1 = true
          setState((prevState) => ({
            ...prevState,
            descriptionList: Data1
          }))
          if (isRecived2) {
            setState((prevState) => ({
              ...prevState,
              isSendCommandModal: true,
              sendCommandsForm: { ...savedCommandsDataFormat }
            }))
          }

        })
        .catch(() => {
        })
      instance({
        url: `/api/commands/types`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          deviceId: state.currentId,
          textChannel: false
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then(commands => {
          if (commands?.length) {
            commands?.map(item => {
              Data2.push({ key: item.type, name: item.type })
              return null
            })
          }
          isRecived2 = true
          setState((prevState) => ({
            ...prevState,
            typeList: Data2
          }))
          if (isRecived1) {
            setState((prevState) => ({
              ...prevState,
              isSendCommandModal: true,
              sendCommandsForm: { ...savedCommandsDataFormat }
            }))
          }
        })
        .catch(() => {
        })
    } else {
      dispatchRedux(
        Notifications.success({
          message: props.translate('componentIsLoadingTryAgain'),
          autoDismiss: 10
        })
      )
    }
  }

  // const sendCommands_handleChange = (name, event) => {
  //   if (name === 'description') {
  //     const { attributes, ...others } = event
  //     setState((prevState) => ({
  //       ...prevState,
  //       sendCommandsForm: {
  //         ...prevState.sendCommandsForm,
  //         attributes: {
  //           ...prevState.sendCommandsForm.attributes,
  //           data: attributes ? attributes.data : ''
  //         },
  //         [name]: others
  //       }
  //     }))
  //     checkSendCommandRequiredFields()
  //   } else {
  //     const { target } = event
  //     let value = target.type === 'checkbox' ? target.checked : target.value
  //     if (name === 'attributes') {
  //       let { attributes } = { ...state.sendCommandsForm }
  //       attributes = { ...attributes, [target.name]: value }
  //       if (!value) {
  //         delete attributes[target.name]
  //       }
  //       setState((prevState) => ({
  //         ...prevState,
  //         sendCommandsForm: {
  //           ...prevState.sendCommandsForm,
  //           attributes
  //         }
  //       }))
  //       checkSendCommandRequiredFields()
  //     } else {
  //       setState((prevState) => ({
  //         ...prevState,
  //         sendCommandsForm: {
  //           ...prevState.sendCommandsForm,
  //           [name]: value
  //         }
  //       }))
  //       checkSendCommandRequiredFields()
  //       if (name === "textChannel") {
  //         loadItemOfCommandType(value)
  //       }
  //     }
  //   }
  // }

  // const checkSendCommandRequiredFields = () => {
  //   let { description, type, attributes } = state.sendCommandsForm
  //   if (description && type) {
  //     if (
  //       type === 'custom' ||
  //       type === 'silenceTime' ||
  //       type === 'setPhonebook' ||
  //       type === 'voiceMessage' ||
  //       type === 'outputControl' ||
  //       type === 'setIndicator' ||
  //       type === 'configuration' ||
  //       type === 'setOdometer' ||
  //       type === 'alarmClock' ||
  //       type === 'alarmSpeed' ||
  //       type === 'alarmVibration' ||
  //       type === 'timezone' ||
  //       type === 'sendSms' ||
  //       type === 'sendUssd' ||
  //       type === 'sosNumber' ||
  //       type === 'message' ||
  //       type === 'alarmFall' ||
  //       type === 'alarmRemove' ||
  //       type === 'alarmBattery' ||
  //       type === 'modeDeepSleep' ||
  //       type === 'modePowerSaving' ||
  //       type === 'setAgps' ||
  //       type === 'voiceMonitoring' ||
  //       type === 'novementAlarm'
  //     ) {
  //       if (attributes && Object.keys(attributes).length > 0) {
  //         setState((prevState) => ({
  //           ...prevState,
  //           sendCommandBtnState: true
  //         }))
  //       } else {
  //         setState((prevState) => ({
  //           ...prevState,
  //           sendCommandBtnState: false
  //         }))
  //       }
  //     } else {
  //       setState((prevState) => ({
  //         ...prevState,
  //         sendCommandBtnState: true
  //       }))
  //     }
  //   } else {
  //     setState((prevState) => ({
  //       ...prevState,
  //       sendCommandBtnState: false
  //     }))
  //   }
  // }

  // const loadItemOfCommandType = status => {
  //   let Data = []

  //   instance({
  //     url: `/api/commands/types`,
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     params: {
  //       deviceId: state.currentId,
  //       textChannel: status
  //     }
  //   })
  //     // .then(response => {
  //     //   if (response.ok) {
  //     //     response.json()
  //     .then(commands => {
  //       if (commands.length) {
  //         commands?.map(item => {
  //           Data.push({ key: item.type, name: item.type })
  //           return null
  //         })
  //       }
  //       setState((prevState) => ({
  //         ...prevState,
  //         typeList: Data
  //       }))
  //     })

  //     .catch(() => {

  //     })
  // }

  // const sendCommandSubmit = () => {
  //   let { sendCommandsForm, currentId } = state
  //   let obj = {
  //     id: 0,
  //     deviceId: currentId,
  //     description: sendCommandsForm.description.key,
  //     type: sendCommandsForm.type,
  //     attributes: { ...sendCommandsForm.attributes }
  //   }

  //   instance({
  //     url: `/api/commands/send`,
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data: {
  //       ...obj
  //     }
  //   })
  //     .then(response => {
  //       onCloseModal()
  //       if (response.status === 200) {
  //         dispatchRedux(
  //           Notifications.success({
  //             message: props.translate('commandSent'),
  //             autoDismiss: 10
  //           })
  //         )
  //       } else if (response.status === 202) {
  //         dispatchRedux(
  //           Notifications.success({
  //             message: props.translate('commandQueued'),
  //             autoDismiss: 10
  //           })
  //         )
  //       } else {
  //         dispatchRedux(
  //           Notifications.success({
  //             message: props.translate('error'),
  //             autoDismiss: 10
  //           })
  //         )
  //       }
  //     })
  //     .catch(error => { errorHandler(error, dispatchRedux) })
  // }
  // remove

  const removeNotification = () => {
    setState(prevState => ({
      ...prevState,
      notificationCallStatus: 'DELETE'
    }))
  }

  const removeMaintenance = () => {
    setState(prevState => ({
      ...prevState,
      maintenanceCallStatus: 'DELETE'
    }))
  }

  const removeUsers = () => {
    setState(prevState => ({
      ...prevState,
      userCallStatus: 'DELETE'
    }))
  }

  const removeComputedAttributes = () => {
    setState(prevState => ({
      ...prevState,
      attributeCallStatus: 'DELETE'
    }))
  }

  const onRemovedNotification = item => {
    setState((prevState) => ({
      ...prevState,
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true
    }))
  }

  // const onRemovedGeofence = item => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     geofenceForm: { ...item[0] },
  //     onDeleteConfirmationGeofence: true
  //   }))
  // }

  // const onRemovedDriver = item => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     driverForm: { ...item[0] },
  //     onDeleteConfirmationDriver: true
  //   }))

  // }

  const onRemovedAttribute = item => {
    setState((prevState) => ({
      ...prevState,
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true
    }))
  }

  const onRemovedCommand = item => {
    setState((prevState) => ({
      ...prevState,
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true
    }))
  }

  const onRemovedMaintenance = item => {
    setState((prevState) => ({
      ...prevState,
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true
    }))
  }

  const onRemovedUser = item => {
    setState((prevState) => ({
      ...prevState,
      userForm: { ...item[0] },
      onDeleteConfirmationUser: true
    }))
  }

  // const onRemovedDevice = (e, item) => {
  //   e.preventDefault()
  //   setState((prevState) => ({
  //     ...prevState,
  //     slectedUnits: item,
  //     onDeleteConfirmationUnits: true
  //   }))
  //   return false
  // }

  const removeCommands = () => {
    setState(prevState => ({
      ...prevState,
      commandsCallStatus: 'DELETE'
    }))

  }

  const removeDevices = () => {
    let { slectedUnits } = state

    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {// eslint-disable-line no-unused-vars
        dispatchRedux(removeDevice(slectedUnits.id))
        props?.history.push('/units')
        setState((prevState) => ({
          ...prevState,
          onDeleteConfirmationUnits: false
        }))
      })
      .catch(error => { errorHandler(error, dispatchRedux) })
  }

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationUser: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false
    }))
  }

  // comman for all
  const onCloseC_Att_Modal = () => {
    setState((prevState) => ({
      ...prevState,
      visableAttribute: false,
      visableNotification: false,
      visableGeofence: false,
      visableMaintenance: false,
      visableSavedCommands: false,
      attributeChangesMessage: '',
      defaultAttributes: {}
    }))
  }

  // check for required field
  const checkRequiredFields = (computedAtt) => {
    // changed attribute work is in pendding
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm
    } = state
    if (computedAtt === 'computedAtt') {
      let changed_comAt = !isEqual(selecteItem, computedAttForm)
      let { attribute, expression } = state.computedAttForm
      if (
        attribute &&
        expression &&
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    } else if (computedAtt === 'Commands') {
      let changed_comm = !isEqual(selecteItem, savedCommandsForm)
      let { type, description } = state.savedCommandsForm
      if (type && description && changed_comm) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    } else if (computedAtt === 'notification') {
      // attChangedValue is for attributes changing value
      let attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      )
      if (attChangedValue) {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: 'Change in attributes is pending'
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: ''
        }))
      }
      let changed_noti = !isEqual(selecteItem, notificationForm)
      let { type, notificators } = state.notificationForm
      if (type && notificators && changed_noti) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    } else if (computedAtt === 'maintenance') {
      let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes)
      if (Attvalue) {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: 'Change in attributes is pending'
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          attributeChangesMessage: ''
        }))
      }
      let changed_main = !isEqual(selecteItem, maintenanceForm)
      let { type, start, period, attributes } = state.maintenanceForm
      if (type && period && (start || attributes.startDate) && changed_main) {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: true
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          isAddVisableBtn: false
        }))
      }
    }
  }

  const isButtonVisable = (target) => {
    if (target === 'parkingAttForm') {
      // const { attributes } = state.resFormData
      let form = { ...state.unitParkingAttributesform }

      form['speedThreshold'] = checkSpeedThreshold(form.speedThreshold, true)

      if (form.minimalFuelFillingVolume < 1 || form.minimalFuelDrainVolume < 1 || isNaN(form.minimalFuelFillingVolume) || isNaN(form.minimalFuelDrainVolume)) {
        setState((prevState) => ({
          ...prevState,
          isVisableParkingAttBtn: false,
          errorInFuelFilling: form.minimalFuelFillingVolume < 1 || isNaN(form.minimalFuelFillingVolume) ? true : false,
          errorInFuelDrain: form.minimalFuelDrainVolume < 1 || isNaN(form.minimalFuelDrainVolume) ? true : false,
        }))
      }
      else {
        setState((prevState) => ({
          ...prevState,
          isVisableParkingAttBtn: !isEqual(state.resFormData?.attributes, form),
          errorInFuelDrain: false,
          errorInFuelFilling: false
        }))
      }
    }
    if (target === 'aFormOne') {
      // const { category } = state.resFormData
      setState((prevState) => ({
        ...prevState,
        isVisableASOneBtn: !isEqual(state.resFormData?.category, state.selecteditem?.category)
      }))
    }
  }

  const handleChangeForParkingAtt = name => event => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if (event.target.type !== 'checkbox' && name !== "fuelCounter") {
      value = parseFloat(value)
    }

    if (name === "fuelCounter") {
      if (value === "basic") {
        setState((prevState) => ({
          ...prevState,
          unitParkingAttributesform: {
            ...prevState.unitParkingAttributesform,
            [name]: (value),
            basicL100Consumption: prevState.unitParkingAttributesform?.basicL100Consumption || 10,
          }
        }))
      } else if (value === 'advance') {
        setState((prevState) => ({
          ...prevState,
          unitParkingAttributesform: {
            ...prevState.unitParkingAttributesform,
            [name]: value,
            // basicL100Consumption:"",
            advL100CityConsumption: prevState.unitParkingAttributesform?.advL100CityConsumption || 0,
            advL100HighwayConsumption: prevState.unitParkingAttributesform?.advL100HighwayConsumption || 0,
            advL100AverageConsumption: prevState.unitParkingAttributesform?.advL100AverageConsumption || 0,
            advCitySpeed: prevState.unitParkingAttributesform?.advCitySpeed || 30,
            advHighwaySpeed: prevState.unitParkingAttributesform?.advHighwaySpeed || 90,
            advAverageSpeed: prevState.unitParkingAttributesform?.advAverageSpeed || 60,
            advCorrectionCoefficient: prevState.unitParkingAttributesform?.advCorrectionCoefficient || 1,
            advIdlLHFuelConsumption: prevState.unitParkingAttributesform?.advIdlLHFuelConsumption || 1,
            advHighSpeedAddonPercent: prevState.unitParkingAttributesform?.advHighSpeedAddonPercent || 20,
            advEveryKmhHighwayConsumption: prevState.unitParkingAttributesform?.advEveryKmhHighwayConsumption || 50
          }
        }))

      }
      else if (value === 'standard') {
        setState((prevState) => ({
          ...prevState,
          unitParkingAttributesform: {
            ...prevState.unitParkingAttributesform,
            [name]: value,
          }
        }))

      }
      else {
        setState((prevState) => ({
          ...prevState,
          unitParkingAttributesform: {
            ...prevState.unitParkingAttributesform,
            [name]: (value)
          }
        }))

      }
    }
    else if (name === 'consecutiveFuelFillingTimeout' || name === 'consecutiveFuelDrainTimeout' || name === 'consecutiveFuelDrainTimeout') {
      setState((prevState) => ({
        ...prevState,
        unitParkingAttributesform: {
          ...prevState.unitParkingAttributesform,
          [name]: parseInt(value)
        }
      }))

    }
    else {
      setState((prevState) => ({
        ...prevState,
        unitParkingAttributesform: {
          ...prevState.unitParkingAttributesform,
          [name]: value
        }
      }))
    }
  }
  const handleChangeForLanguageAtt = (name, event) => {
    setState((prevState) => ({
      ...prevState,
      unitParkingAttributesform: {
        ...prevState.unitParkingAttributesform,
        [name]: event.value
      }
    }))
  }

  const parkingformSubmit = () => {
    let { selecteditem, unitParkingAttributesform } = state;
    if (selecteditem) {
      let attributes = {
        ...unitParkingAttributesform,
        minimalTripDuration: state.unitParkingAttributesform.minimalTripDuration,
        speedThreshold: checkSpeedThreshold(
          state.unitParkingAttributesform.speedThreshold,
          true
        ),
        minimalTripDistance: checkMinimalTripDistance(
          state.unitParkingAttributesform.minimalTripDistance,
          true
        )
      };
      selecteditem = {
        ...selecteditem,
        attributes: attributes
      };
      setState((prevState) => ({
        ...prevState,
        selecteditem
      }))
      setIsFormSubmit(true)
    }
  };

  useEffect(()=>{
    if(isFormSubmit)
   { formSubmit()
    setIsFormSubmit(false)
   }
  },[isFormSubmit])

const [isLoading,setIsLoading]=useState(false)
const [isLoading2,setIsLoading2]=useState(false)


  const formSubmit = () => {
    // const { fuelcounter } = state;
    let data = state.selecteditem
    let check = data.check
    delete data.visible
    delete data.check
    delete data.indeterminate
    delete data.parent

    if (state.unitParkingAttributesform.fuelCounter === "basic") {
      delete data.attributes.advL100CityConsumption
      delete data.attributes.advL100HighwayConsumption
      delete data.attributes.advL100AverageConsumption
      delete data.attributes.advCitySpeed
      delete data.attributes.advHighwaySpeed
      delete data.attributes.advAverageSpeed
      delete data.attributes.advCorrectionCoefficient
      delete data.attributes.advIdlLHFuelConsumption
      delete data.attributes.advHighSpeedAddonPercent
      delete data.attributes.advEveryKmhHighwayConsumption
    }
    else if (state.unitParkingAttributesform.fuelCounter === "standard") {
      delete data.attributes.basicL100Consumption
      delete data.attributes.advL100CityConsumption
      delete data.attributes.advL100HighwayConsumption
      delete data.attributes.advL100AverageConsumption
      delete data.attributes.advCitySpeed
      delete data.attributes.advHighwaySpeed
      delete data.attributes.advAverageSpeed
      delete data.attributes.advCorrectionCoefficient
      delete data.attributes.advIdlLHFuelConsumption
      delete data.attributes.advHighSpeedAddonPercent
      delete data.attributes.advEveryKmhHighwayConsumption

    }
    else if (state.unitParkingAttributesform.fuelCounter === "advance") {
      delete data.attributes.basicL100Consumption

    }
    delete data.attributes.userOfDevice
setIsLoading(true)

    instance({
      url: `/api/devices/${parseInt(props?.id)}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...data
      }
    })
      .then(device => {
        dispatchRedux(updateDevice({ ...device, check }))
        //props?.enqueueSnackbar(props.translate('trackersIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        dispatchRedux(
          Notifications.success({
            message: props.translate('trackersIsUpdated'),
            autoDismiss: 10
          })
        )
        setState((prevState) => ({
          ...prevState,
          isVisableParkingAttBtn: false,
          isVisableASOneBtn: false
        }))

        setIsLoading(false)
      })

      .catch(error => {
        setIsLoading(false)
         errorHandler(error, dispatchRedux)
        })

  }

  const updateSelectedDrivers = () => {
    let driver = state.mulitDriversSelect
    let allData = props.drivers
    if (driver?.length) {
      allData?.map(item => {
        item.check = false
        driver?.map(main => {
          if (item.id && main.id && item.id === main.id) {
            item.check = true
            main.check = true
          }
          return null
        })
        return null
      })
    } else {
      allData?.map(n => (n.check = false))
    }
    dispatchRedux(driverDeviceWiseSelectedList(allData))
  }





  const updateSelectedItem = (selecteditem) => {
    setState((prevState) => ({
      ...prevState,
      selecteditem
    }))
  }

  const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      isAdvSettingEnable: !prevState.isAdvSettingEnable
    }))
  }

  // const getPostion = (address, latlng) => {
  //   if (address) {
  //     setState((prevState) => ({
  //       ...prevState,
  //       address: address,
  //       lat: latlng.lat,
  //       lng: latlng.lng
  //     }))
  //   }
  // }

  // const selectDriver = item => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     driverObj: item[0],
  //     activeOperation: 'edit',
  //     openDriverModal: true
  //   }))
  // }

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage
    }))
  }

  //  const fetchMoreItems = () => { }

  const handleFuelChange = (name, event) => {
    setState((prevState) => ({
      ...prevState,
      selecteditem: {
        ...prevState.selecteditem,
        attributes: {
          ...prevState.selecteditem.attributes,
          [name]: event.target.value
        }
      }
    }))
    checkReqFeulChange()
  }

  const checkReqFeulChange = () => {
    const dd = state.selecteditem
    const { fuelSensor1, fuelSensor2 } = dd.attributes
    if (fuelSensor1 && fuelSensor2) {
      setState((prevState) => ({
        ...prevState,
        isDisableFuelBtn: false
      }))
    }
  }

  const fuelSave = () => {
    if (state.selecteditem && state.selecteditem.id) {
      let dt = state.selecteditem
      delete dt.visible
      setIsLoading2(true)
      instance({
        url: `/api/devices/${parseInt(state.selecteditem.id)}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...dt
        }
      })
        .then(device => {
          setState((prevState) => ({
            ...prevState,
            isDisableFuelBtn: true
          }))
          setIsLoading2(false)
          dispatchRedux(updateDevice({ ...device }))
          dispatchRedux(
            Notifications.success({
              message: props.translate('deviceUpdated'),
              autoDismiss: 10
            }))
        })
        .catch(err => { console.error('err====', err) })
    }
  }
  const submitCopyEcoDriving = () => {
    let obj = []
    instance({
      url: `/api/ecodriving/copy/${state.currentId}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { deviceIds: state.sensorDeviceids }
    })
      .then(response => {
        let allData = props.devices2
        setState((prevState) => ({
          ...prevState,
          showDialg: false,
          openResponseDialg: true
        }))
        for (const [key, value] of Object.entries(response)) {
          allData?.map(dt => {
            if (dt.id == key) {
              dt.message = value
              obj.push(dt)
            }
          })
        }
        setState((prevState) => ({
          ...prevState,
          copiedData: obj
        }))
        dispatchRedux(
          Notifications.success({
            message: props.translate('ecoDrivingCopied'),
            autoDismiss: 10
          })
        )
        // }
      })
      .catch(error => { errorHandler(error, dispatchRedux) })
  }

  const handleChangeVehicle = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      selectedvehicle: value,
    }))
    if (state.selectedvehicle) {
      setState((prevState) => ({
        ...prevState, enableAssingBtn: true
      }))
    } else {
      setState((prevState) => ({
        ...prevState, enableAssingBtn: true
      }))
    }
  }

  // const assignVehicle = () => {
  //   let { selectedvehicle } = state
  //   const filteredVehicle = props?.vehicles?.find(vehicle => vehicle.id === selectedvehicle.id);
  //   let finalObj = {
  //     ...filteredVehicle,
  //     deviceId: state.selecteditem.id
  //   }

  //   const data = {
  //     url: selectedvehicle ? `/api/vehicles/${selectedvehicle.id}` : `/api/vehicles/`,
  //     method: selectedvehicle ? `PUT` : `POST`,
  //     successMsg: selectedvehicle ? `unitAssignedToVehicle` : `vehicleIsAdded`,
  //   };

  //   instance({
  //     url: data.url,
  //     method: data.method,
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data: {
  //       ...finalObj
  //     }
  //   })
  //     .then(d => {
  //       // if (d.status === 200 && d.data.status === 'success') {
  //         setState((prevState) => ({
  //           ...prevState,
  //           enableAssingBtn: false
  //         }))
  //       dispatchRedux(selectedvehicle ? updatevehicle(d) : addvehicle(d))
  //       dispatchRedux(
  //         Notifications.success({
  //           message: props.translate(data.successMsg),
  //           autoDismiss: 10
  //         })
  //       )
  //     })
  //     .catch(error => { errorHandler(error, dispatchRedux) })
  // }

  const handleAfterResponse = () => {
    setState((prevState) => ({
      ...prevState,
      vehicleStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>
    }))
  }


  const submitWasl = (vehicle) => {
    const { waslCompanyRefKey } = props?.serversettings?.attributes || {};
    if (waslCompanyRefKey) {

      const { number, leftLetter, rightLetter, plateType, middleLetter, sequenceNumber } = vehicle.attributes;
      const { uniqueId } = state.vehicleTracker;
      if (number && leftLetter && rightLetter && plateType && middleLetter && sequenceNumber && uniqueId) {
        instance.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {
          hook: 'registerVehicle',
          account_id: props?.serversettings.id,
          data: {
            uniqueId,
            sequenceNumber,
            activity: 'DEFAULT',
            attributes: {
              rightLetter,
              number,
              leftLetter,
              middleLetter,
              plateType,
            }
          },
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          setState((prevState) => ({
            ...prevState,
            waslStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
          }))

          if (response.result && response.result.referenceKey) {
            handleChangeForAttributesValues('WASLKey', { target: { value: response.result.referenceKey } });
            uc_submit(true);
          }
          // }
        }).catch(error => {
          let res = error?.response?.data || {}
          let check = res && res.data && res.data.result
          if (check && check.referenceKey) {
            handleChangeForAttributesValues('WASLKey', { target: { value: check.referenceKey } });
            uc_submit(true);
          }

          setState((prevState) => ({
            ...prevState,
            waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> invalid form</span>,
          }))
          handleAfterResponse()

        })
      }
      else {
        setState((prevState) => ({
          ...prevState,
          waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> incomplete form</span>,
        }))
        handleAfterResponse();
      }
    }
    else {
      handleAfterResponse();
    }
  }

  const { currentTab, selecteditem, unitParkingAttributesform } = state
  const { id } = props
  const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'


  return (

    <Fragment>
      <h4 className='tab-title'>
        {selecteditem ? selecteditem.name : 'Unit'}
      </h4>
      <div className={' main-unit-modal-bg clearfix'} style={{ display: "flex", flexWrap: "wrap", overflow: 'hidden' }}>
        {state.isSendCommandModal && (
          <CustomDialog
            title={props.translate('sendCommand')}
            themeColors={props.themecolors}
            disableFooter
            onClose={onCloseModal}
            dialogHeight={250}
            draggable
            bodyPadding={0}
          >
            <SendCommand
              id={props?.id}
              dispatch={dispatchRedux}
              translate={props.translate}
              themecolors={props.themecolors}
              descriptionList={state.descriptionList}
            />
          </CustomDialog>
        )}
        {state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDevices}
            title={props.translate('areYouWantToDelete')}
            children={state.slectedUnits.name}
          />
        )}
        {state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeNotification}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'notification.' + state.notificationForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationDriver && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDriver}
            title={props.translate('areYouWantToDelete')}
            children={state.driverForm.name}
          />
        )}
        {state.onDeleteConfirmationGeofence && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeGeofence}
            title={props.translate('areYouWantToDelete')}
            children={state.geofenceForm.name}
          />
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeCommands}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'commands.' + state.savedCommandsForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeMaintenance}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(state.maintenanceForm.type)}
          />
        )}
        {state.onDeleteConfirmationUser && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeUsers}
            title={props.translate('areYouWantToDelete')}
            children={state.userForm.name}
          />
        )}
        {state.onDeleteConfirmationUnits && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeDevices}
            title={props.translate('areYouWantToDelete')}
            children={state.slectedUnits.name}
          />
        )}
        {state.onDeleteConfirmationNotification && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeNotification}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'notification.' + state.notificationForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeComputedAttributes}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              state.computedAttForm.description
            )}
          />
        )}
        {state.onDeleteConfirmationCommand && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeCommands}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(
              'commands.' + state.savedCommandsForm.type
            )}
          />
        )}
        {state.onDeleteConfirmationMaintenance && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={removeMaintenance}
            title={props.translate('areYouWantToDelete')}
            children={props.translate(state.maintenanceForm.type)}
          />
        )}
        <Tabs
          orientation={orientation}
          variant='scrollable'
          scrollButtons='auto'
          value={currentTab}
          onChange={tabChange}
          aria-label='Vertical tabs example'
          className='custom-tabs'
          TabIndicatorProps={{ hidden: true }}
          textColor='#FFFFFF'
        >

          <Tab
            value='allAttribute'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('allAttributes')}</span>
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            value='basic'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('basic')}</span>
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            value='vehicle'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('vehicles')}</span>
              </span>
            }
            {...a11yProps(13)}
          />
          <Tab
            value='icon'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('icon')}</span>
              </span>
            }
            {...a11yProps(2)}
          />
          {/* selecteditem &&
          selecteditem.model &&
          selecteditem.model === 'FX Tracker' ? null : */ checkPrivileges(
            'sensor'
          ) ? (
              <Tab
                value='sensors'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    <ArrowRightIcon className='tab_menu_icon' />
                    <span className='menu_item_text_inner'>{props.translate('sensors')}</span>
                  </span>
                }
                {...a11yProps(3)}
              />
            ) : null}
          <Tab
            value='sharedDeviceAccumulators'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('sharedDeviceAccumulators')}</span>
              </span>
            }
            {...a11yProps(4)}
          />
          <Tab
            value='Advance'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('advance')}</span>
              </span>
            }
            {...a11yProps(5)}
          />
          <Tab
            value='fuelSettings'
            classes={{
              root: 'common-tab',
              selected: 'tab-selected',
              wrapper: 'tab-wrapper'
            }}
            label={
              <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon' />
                <span className='menu_item_text_inner'>{props.translate('fuelSettings')}</span>
              </span>
            }
            {...a11yProps(13)}
          />
          {/* )} */}
          {checkPrivileges('user') && (
            <Tab
              value='Access'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  <ArrowRightIcon className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{props.translate('access')}</span>
                </span>
              }
              {...a11yProps(7)}
            />
          )}
          {checkPrivileges('notification') && (
            <Tab
              value='sharedNotifications'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  <ArrowRightIcon className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{props.translate('sharedNotifications')}</span>
                </span>
              }
              {...a11yProps(9)}
            />
          )}
          {checkPrivileges('attribute') && (
            <Tab
              value='sharedComputedAttributes'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  <ArrowRightIcon className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{props.translate('sharedComputedAttributes')}</span>
                </span>
              }
              {...a11yProps(10)}
            />
          )}
          {checkPrivileges('command') && (
            <Tab
              value='sharedSavedCommand'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  <ArrowRightIcon className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{props.translate('sharedSavedCommand')}</span>
                </span>
              }
              {...a11yProps(11)}
            />
          )}
          {checkPrivileges('maintenance') && (
            <Tab
              value='sharedMaintenance'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  <ArrowRightIcon className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{props.translate('sharedMaintenance')}</span>
                </span>
              }
              {...a11yProps(12)}
            />
          )}
          {
            checkPrivileges('setting') && (<Tab
              value='ecoDriving'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  <ArrowRightIcon className='tab_menu_icon' />
                  <span className='menu_item_text_inner'>{props.translate('ecoDriving')}</span>
                </span>
              }
              {...a11yProps(12)}
            />)
          }
        </Tabs>
        {currentTab === 'allAttribute' && (
          <TabPanel value={currentTab} index='allAttribute'>
            {selecteditem && selecteditem.id && (
              <div>
                <RawAttributes
                  positions={props?.positions}
                  devices={props.devices3}
                  selectedDeviceId={props?.id}
                  translate={props.translate}
                />
              </div>
            )}
          </TabPanel>
        )}
        {currentTab === 'basic' && (
          <TabPanel value={currentTab} index='basic'>
            {selecteditem && (
              <DeviceModal
                id={props.id}
                activeLanguage={props.activeLanguage}
                onCloseModal={onCloseModal}
                activeOperation={'edit'}
                selecteditem={state.selecteditem}
                updateSelectedItem={updateSelectedItem}
                addNewGroups={addNewGroups}
                translate={props.translate}
              />
            )}
          </TabPanel>
        )}
        {currentTab === 'vehicle' && (
          <TabPanel value={currentTab} index='vehicle'>
            {selecteditem && (
              <UnitSelectionForm
                // {...state}
                form={state.form}
                areaList={props?.areaList}
                logInUser={props.logInUser}
                setExpirationTime={setExpirationTime}
                handleChangeForAttributesValues={handleChangeForAttributesValues}
                handleChangeLicenseExpDate={handleChangeLicenseExpDate}
                selectedvehicle={state.selectedvehicle}
                translate={props.translate}
                languages={props?.languages}
                vehicles={state.vehiclesList}
                handleChangeVehicle={handleChangeVehicle}
                handleChange={handleChange}
                // onSubmit={assignVehicle}
                enableAssingBtn={state.isVisableUserBtn}
                onSubmit={uc_submit}

              />
            )}

          </TabPanel>
        )}
        {currentTab === 'icon' && selecteditem && (
          <TabPanel value={currentTab} index='icon'>
            <IconForm
              selectedDevice={state.selecteditem}
              translate={props.translate}
              images={props?.category}
              themecolors={props.themecolors}
              isVisableASOneBtn={state.isVisableASOneBtn}
              logo_handelChanges={logo_handelChanges}
              isVisablBtn={checkPrivileges('deviceUpdate')}
              advenceSectionOneformSubmit={advenceSectionOneformSubmit}
              isLoading={isLoading}
            />
          </TabPanel>
        )}
        {/* selecteditem &&
        selecteditem.model &&
        selecteditem.model === 'FX Tracker' ? null :  */checkPrivileges(
          'sensor'
        ) ? (
            <>
              {currentTab === 'sensors' && (
                <TabPanel value={currentTab} index='sensors'>
                  <Sensors
                    {...state}
                    logInUser={props.logInUser}
                    getIdsfromChild={getIdsfromChild}
                    allDevices={state.sensorDeviceids && state.sensorDeviceids?.length ? state.allDevices : props.devices2}
                    deviceId={id}
                    deviceModal={state.deviceModal}
                    translate={props.translate}
                    suggestions={state.suggestions}
                    computedAttributesDeviceWise={props.computedAttributesDeviceWise
                    }
                    isVisablBtn={checkPrivileges('sensorCreate')}
                    selectedDeviceId={id}
                    rowsPerPage={state.rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    Devices_Selection={Devices_Selection}
                    submitCopySenors={submitCopySenors}
                    closeDialog={closeDialog}
                    selectedCopySensor={selectedCopySensor}
                    onCopy={onCopy}
                    selectAllCopySensors={(e) => selectAllCopySensors(e)}
                  />
                </TabPanel>
              )}{' '}
            </>
          ) : null}
        {currentTab === 'sharedDeviceAccumulators' && (
          <TabPanel value={currentTab} index='sharedDeviceAccumulators'>
            <Accumulators
              deviceId={id}
              translate={props.translate}
              suggestions={state.suggestions}
              computedAttributesDeviceWise={
                props.computedAttributesDeviceWise
              }
              selecteditem={state.selecteditem}
              computedAttributesData={props?.selectedComputedAttributes}
              isVisablBtn={checkPrivileges('deviceUpdate')}
            />
          </TabPanel>
        )}
        {currentTab === 'fuelSettings' && (
          <TabPanel value={currentTab} index='fuelSettings'>
            <FuelSettings
              translate={props.translate}
              languages={props?.languages}
              drivers={props.drivers}
              unitParkingAttributesform={unitParkingAttributesform}
              handleChange={handleChangeForParkingAtt}
              handleChange2={handleChangeForLanguageAtt}
              isVisableParkingAttBtn={state.isVisableParkingAttBtn}
              onSubmit={parkingformSubmit}
              isVisablBtn={checkPrivileges('deviceUpdate')}
              isAdvSettingEnable={state.isAdvSettingEnable}
              handleClick={handleClick}
              errorInFuelFilling={state.errorInFuelFilling}
              errorInFuelDrain={state.errorInFuelDrain}
              handleFuelChange={handleFuelChange}
              fuelSave={fuelSave}
              selecteditem={state.selecteditem}
              isDisableFuelBtn={state.isDisableFuelBtn}
              isLoading={isLoading}
              isLoading2={isLoading2}
              themecolors={props.themecolors}

            />
          </TabPanel>
        )}
        {currentTab === 'Advance' && (
          <TabPanel value={currentTab} index='Advance'>
            <AdvanceForm
              translate={props.translate}
              languages={props?.languages}
              drivers={props.drivers}
              unitParkingAttributesform={unitParkingAttributesform}
              handleChange={handleChangeForParkingAtt}
              handleChange2={handleChangeForLanguageAtt}
              isVisableParkingAttBtn={state.isVisableParkingAttBtn}
              onSubmit={parkingformSubmit}
              isVisablBtn={checkPrivileges('deviceUpdate')}
              isAdvSettingEnable={state.isAdvSettingEnable}
              handleClick={handleClick}
              errorInFuelFilling={state.errorInFuelFilling}
              errorInFuelDrain={state.errorInFuelDrain}
              trailers={props?.trailers || []}
              isLoading={isLoading}
            />
          </TabPanel>
        )}

        {checkPrivileges('user') && currentTab === 'Access' && (
          <TabPanel value={currentTab} index='Access'>
            <Users
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              selectAll={e =>
                selectAll(
                  e,
                  'userId',
                  'deviceId',
                  props?.id,
                  props.selecteduser,
                  props.users,
                  dispatchRedux,
                  'access',
                  props.translate
                )
              }
              parent={
                selecteditem && selecteditem.parentId
                  ? selecteditem.parentId
                  : 0
              }
              user_Selection={user_Selection}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              // user_handleChange={user_handleChange}
              // usernHandle={usernHandle}
              themecolors={props.themecolors}
              userData={props.selecteduser}
              roles={props?.roles}
              translate={props.translate}
              onDelete={onRemovedUser}
              canRemove={checkPrivileges('userUnlinkNotification')}
              canAssign={checkPrivileges('userLinkNotification')}
            />
          </TabPanel>
        )}

        {checkPrivileges('notification') &&
          currentTab === 'sharedNotifications' && (
            <TabPanel value={currentTab} index='sharedNotifications'>
              <Notification
                onListNotification={onListNotification}
                // onCloseC_Modal={onCloseC_Modal}
                onCreateNewNotification={onCreateNewNotification}
                notification_Selection={notification_Selection}
                onCloseC_Att_Modal={onCloseC_Att_Modal}
                addNotification={addNotifications}
                notification_handleChange={notification_handleChange}
                notification_handleChange2={notification_handleChange2}
                notificationHandle={notificationHandle}
                calendars={props?.calendars}
                notificationDeviceWise={props.notificationDeviceWise}
                themecolors={props.themecolors}
                notificationData={props.selectednotification}
                visableCreateNotification={state.visableNotification}
                notificationForm={state.notificationForm}
                notificationChannel={props.notificationChannel}
                notificationType={props.notificationType}
                translate={props.translate}
                onEdit={editNotification}
                onDelete={onRemovedNotification}
                addButtonStatus={state.addButtonStatus}
                // addAttributes={addNotificationAttributes}
                isAddVisableBtn={state.isAddVisableBtn}
                isHaveAccess={state.isHaveAccess}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'deviceId',
                    'notificationId',
                    props?.id,
                    props.selectednotification,
                    props.notification,
                    dispatchRedux,
                    'notification',
                    props.translate
                  )
                }
                canRemove={checkPrivileges('deviceUnlinkNotification')}
                canAssign={checkPrivileges('deviceLinkNotification')}
                attributeChangesMessage={state.attributeChangesMessage}
              />
            </TabPanel>
          )}
        {checkPrivileges('attribute') &&
          currentTab === 'sharedComputedAttributes' && (
            <TabPanel value={currentTab} index='sharedComputedAttributes'>
              <ComputedAttributes
                type="units"
                {...props}
                suggestions={state.suggestions}
                onListComputedAttributes={onListComputedAttributes}
                translate={props.translate}
                onCloseC_Att_Modal={onCloseC_Att_Modal}
                // onCloseC_Modal={onCloseC_Modal}
                themecolors={props.themecolors}
                computedAttributesDeviceWise={
                  props.computedAttributesDeviceWise
                }
                onCreateNewAttributes={onCreateNewAttributes}
                C_Att_handleChange={C_Att_handleChange}
                attributeHandle={attributeHandle}
                addComputedAttributes={addComputedAttributes}
                // computedAttributes_handleChange={
                //   computedAttributes_handleChange
                // }
                visableAttribute={state.visableAttribute}
                visableListAttribute={state.visableListAttribute}
                computedAttributesData={props?.selectedComputedAttributes}
                computedAttForm={state.computedAttForm}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'deviceId',
                    'attributeId',
                    props?.id,
                    props?.selectedComputedAttributes,
                    props.computedAttributes,
                    dispatchRedux,
                    'attribute',
                    props.translate
                  )
                }
                computedAttributes_Selection={
                  computedAttributes_Selection
                }
                onEdit={editComputedAttributes}
                onDelete={onRemovedAttribute}
                addButtonStatus={state.addButtonStatus}
                isAddVisableBtn={state.isAddVisableBtn}
                isHaveAccess={state.isHaveAccess}
                isLoading={isLoading}
                canRemove={checkPrivileges('deviceUnlinkAttribute')}
                canAssign={checkPrivileges('deviceLinkAttribute')}
              />
            </TabPanel>
          )}
        {checkPrivileges('command') && currentTab === 'sharedSavedCommand' && (
          <TabPanel value={currentTab} index='sharedSavedCommand'>
            <SavedCommands
              onListSavedCommands={onListSavedCommands}
              translate={props.translate}
              formType={state.formType}
              onCloseC_Att_Modal={onCloseC_Att_Modal}
              // onCloseC_Modal={onCloseC_Modal}
              themecolors={props.themecolors}
              commandsDeviceWise={props.commandsDeviceWise}
              onCreateNewSavedCommands={onCreateNewSavedCommands}
              visableAttribute={state.visableSavedCommands}
              addSavedCommands={addSavedCommands}
              savedCommands_handleChange={savedCommands_handleChange}
              savedCommandsHandle={savedCommandsHandle}
              visableListSavedCommands={state.visableListSavedCommands}
              visableSavedCommands={state.visableSavedCommands}
              commandsData={props.selectedcommands}
              savedCommandsForm={state.savedCommandsForm}
              savedCommands_Selection={savedCommands_Selection}
              commandsTypes={props.commandsTypes}
              onEdit={editCommands}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={state.rowsPerPage}
              selectAll={e =>
                selectAll(
                  e,
                  'deviceId',
                  'commandId',
                  props.id,
                  props.selectedcommands,
                  props.commands,
                  dispatchRedux,
                  'command',
                  props.translate
                )
              }
              onDelete={onRemovedCommand}
              addButtonStatus={state.addButtonStatus}
              isAddVisableBtn={state.isAddVisableBtn}
              isHaveAccess={state.isHaveAccess}
              canRemove={checkPrivileges('deviceUnlinkCommand')}
              canAssign={checkPrivileges('deviceLinkCommand')}
              sendCommandsModal={sendCommandsModal}
              sendCommand
              isLoading={isLoading}
            />
          </TabPanel>
        )}
        {checkPrivileges('maintenance') &&
          currentTab === 'sharedMaintenance' && (
            <TabPanel value={currentTab} index='sharedMaintenance'>
              <MaintenanceForm
                onListMaintenance={onListMaintenance}
                // onCloseC_Modal={onCloseC_Modal}
                onCreateNewMaintenance={onCreateNewMaintenance}
                maintenance_Selection={maintenance_Selection}
                saveToParent={saveToParent}
                onCloseC_Att_Modal={onCloseC_Att_Modal}
                addMaintenance={addMaintenance}
                maintenance_handleChange={maintenance_handleChange}
                maintenance_handleChange_attr={
                  maintenance_handleChange_attr
                }
                maintenancesHandle={maintenancesHandle}
                maintenanceDeviceWise={props.maintenanceDeviceWise}
                themecolors={props.themecolors}
                visableListMaintenance={state.visableListMaintenance}
                maintenanceData={props.selectedMaintenance}
                visableCreateMaintenance={state.visableMaintenance}
                maintenanceForm={state.maintenanceForm}
                translate={props.translate}
                onEdit={editMaintenance}
                onDelete={onRemovedMaintenance}
                addButtonStatus={state.addButtonStatus}
                addAttributes={addMaintenanceAttributes}
                isAddVisableBtn={state.isAddVisableBtn}
                isHaveAccess={state.isHaveAccess}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={state.rowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'deviceId',
                    'maintenanceId',
                    props.id,
                    props.selectedMaintenance,
                    props.maintenance,
                    dispatchRedux,
                    'maintenance',
                    props.translate
                  )
                }
                canRemove={checkPrivileges('deviceUnlinkMaintenance')}
                canAssign={checkPrivileges('deviceLinkMaintenance')}
                attributeChangesMessage={state.attributeChangesMessage}
              />
            </TabPanel>
          )}
        {checkPrivileges('setting') && currentTab === 'ecoDriving' && (
          <TabPanel value={currentTab} index='ecoDriving'>
            <EcoDriving
              selectItemId={props.id}
              translate={props.translate}
              Devices_Selection={Devices_Selection}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              sensorDeviceids={state.sensorDeviceids}
              rowsPerPage={state.rowsPerPage}
              submitCopyEcoDriving={submitCopyEcoDriving}
              copiedData={state.copiedData}
              openResponseDialg={state.openResponseDialg}
              closeDialog={closeDialog}
              selectAllCopySensors={e => (selectAllCopySensors(e))}
              allDevices={state.sensorDeviceids && state.sensorDeviceids.length ? state.allDevices : props.devices2}
            />
          </TabPanel>
        )}
      </div>
    </Fragment>
  )


}

MainUnitModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => ({
  logInUser: state.logInUsers,
  devices: state.devices.data,
  devices3: state.devices3,
  devices2: state.devices2,
  groups: state.groups,
  drivers: state.drivers,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selecteduser: state.selecteduser,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
  trailers: state.trailers,
  vehicles: state.vehicles,
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(
  (withLocalize(MainUnitModal))
)
const LoadingIndicator = () => {
  return <span className="status-icon">
    <span className="loading-dot" style={{ animationDelay: '0ms' }} />
    <span className="loading-dot" style={{ animationDelay: '160ms' }} />
    <span className="loading-dot" style={{ animationDelay: '320ms' }} />
  </span>
}

