import React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { withStyles } from '@mui/styles';

const styles = () => ({
  root: {
    background: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    padding: '2px 8px !important',
    display: 'flex',
    // alignItems: 'center',
    justifyContent:"space-between",
    marginTop: 8,
    marginBottom: 4,
  },
  input: {
    flex:1,
    color: 'white',
    fontSize: 12,
    width:"100%"
  },
  iconButton: {
    padding: 0,
    color: 'inherit',
  },
});

function CustomizedInputBase(props) {
  const { classes } = props;

  return (
    <form onSubmit={props.onSubmit} className={`${classes.root} theme-input theme-notchedOutline`}>
      <InputBase
        onChange={props.onChange}
        className={classes.input}
        placeholder="Add Custom Variables/Operators"
        value={props.value || ''}
        inputProps={{ 'aria-label': 'add custom variables/operators' }}
        style={{color:props.themecolors?.textColor}}
      />
      <IconButton type="submit" className={classes.iconButton} aria-label="directions">
        <SendIcon />
      </IconButton>
    </form>
  );
}

export default withStyles(styles)(CustomizedInputBase);
