import React  from 'react'
import Button from './../../../Components/common/Button'
import Loader from './../../Loader'
import Grid from '@mui/material/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import Style from 'style-it'
import { checkPrivileges } from '../../../Helpers'
import Scrollbar from 'react-scrollbars-custom'
import SearchDevices from '../../../Components/Devices/searchItems'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import moment from 'moment'
import TextField from '../../../Components/common/TextField'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const PrettoSlider = styled(Slider)({
  color: '#0A83A1',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    // lineHeight: 1.2,
    fontSize: 10,
    background: 'unset',
    padding: 0,
    width: 30,
    height: 30,
    marginTop: 10,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#0A83A1',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});


// export default class Timemachine extends Component {
const Timemachine = (props) => {
  // const [state, setState] = useState({
  //   tab: 'default',
  //   flag: true
  // })

  const createReport = type => {
    props.collapseSidebarWithButton()
    props.createReport(type)
  }
  // const goBack =()=> {
  //   props.openForm()
  // }

  const valuetext = value => {
    return moment('2016-03-13')
      .startOf('day')
      .add(value, 'minutes')
      .format('HH:mm')
  }
  // const selectTab = (type) => {
  //   if(type==="report"){
  //     props.setTimeShowLapse(true)
  //   }else{
  //     props.setTimeShowLapse(false)
  //   }
  //   setState((prevState) => ({ ...prevState, tab: type }))

  // }
  // render () {
  let viewScreen
  viewScreen = (
    <div>
      {checkPrivileges('geopointtimeLapse') && (
        <>
          <Button
            onClick={() => props.selectTab("default")}
            style={{
              backgroundColor: props.TabsTimeMachine === "default"?
                props.themecolors.menuBackgroundColor :
                props.themecolors.menuActiveBackground,
                color:props.TabsTimeMachine === "default"
                ? (props.themecolors?.id === 4 
                    ? props.themecolors.menuTextColor 
                    : "transparent")
                : props.themecolors.menuActiveColor,
                
              width: "50%",
            }}
          >
            {props.translate('timemachineForm')}
          </Button>
          <Button
            onClick={() =>props.selectTab("report")}
            style={{
              backgroundColor: props.TabsTimeMachine=== "report"?
                props.themecolors.menuBackgroundColor :
                props.themecolors.menuActiveBackground,
                color:props.TabsTimeMachine === "report"
                ? (props.themecolors?.id === 4 
                    ? props.themecolors.menuTextColor 
                    : "transparent")
                : props.themecolors.menuActiveColor,
              width: "50%",
            }}
          >
            {props.translate('REportForm')}
          </Button>
        </>)}
      <form style={{ padding: 20 }}>
        {!checkPrivileges('geopointtimeLapse') && (
          <h3 className='page-title'>
            {props.translate('timemachineForm')}
          </h3>)}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DateRangePicker
              fill
              {...props.timelineRanges}
              selectedDate={props.selectedDay}
              // selectedDate={props.selectedDate}

              label={
                props.translate('selectedDay')
              }
              onEvent={props.showDates}
              timeMachine
              datepickerOnly={false}
            />
          </Grid>

          <Grid item xs={12} style={{ margin: "25px 0px 5px 0px" }}>
            <PrettoSlider
              value={props.controlTime}
              onChange={props.handleTimeControl}
              valueLabelDisplay='on'
              // aria-labelledby='range-slider'
              valueLabelFormat={valuetext}
              max={1439}
              aria-label="pretto slider"
              // defaultValue={20}
              // classes={{
              //   root: 'theme-range-slider',
              //   thumb: 'theme-range-slider-thumb',
              //   track: 'theme-range-slider-track',
              //   rail: 'theme-range-slider-rail',
              //   valueLabel: 'theme-range-slider-label'
              // }}
              step={5}
            />

          </Grid>
    {/* showTimeLapse:true, */}
    {props.TabsTimeMachine === "report" && (
            <>
                         <Grid item xs={12} style={{ paddingLeft: "0px" }}>
                  <label className='checkbox' style={{ display: "inline-block" }}>
                    <input
                      type="checkbox" name={`raw`}
                      checked={props.isCheckRaw}
                      onChange={(event) =>props.handleChangeRaw(event)}
                    />
                    <span className='theme-input-checkbox' style={{ display: "flex", alignItems: "center" }}>
                      <CheckBoxIcon className='on-active material-icons' style={{ fontSize: 22, color: 'inherit' }} />
                      <CheckBoxOutlineBlankIcon className='on-inactive material-icons' style={{ fontSize: 22, color: 'inherit' }} />
                      <label style={{ marginLeft: "4px" }}>Add raw</label>
                    </span>
                  </label>
                </Grid>
              {props.isCheckRaw ?
                <Grid item xs={12} style={{ margin: "5px 0px 5px 0px" }}>
                  <TextField
                    id='raw'
                    required
                    label={props.translate('enterLat,Lng')}
                    type='text'
                    value={props.rawLatLng}
                    onChange={(e) => props.handleChange('rawLatLng', e.target.value)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> :
                <Grid item xs={12} style={{ margin: "5px 0px 5px 0px", display: "flex", }}>
                  <TextField
                    id='lat'
                    required
                    label={props.translate('lat')}
                    type='number'
                    value={props.lat || 0}
                    onChange={(e) => props.handleChange('lat', e.target.value)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                  <TextField
                    id='lon'
                    required
                    label={props.translate('lon')}
                    type='number'
                    value={props.lon || 0}
                    onChange={(e) => props.handleChange('lon', e.target.value)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    style={{ marginLeft: "5px" }}
                  />
                </Grid>
              }
              <Grid item xs={12} style={{ margin: "5px 0px 5px 0px" }}>
                <TextField
                  id='radius'
                  required
                  label={props.translate('radius')}
                  type='number'
                  value={props.radius || 0}
                  onChange={(e) => props.handleChange('radius', e.target.value)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid></>)}



        </Grid>
        {props.TabsTimeMachine=== "default" && (
          <SearchDevices
            isMulti={false}
            api='devices'
            isClearable
            onChange={props.selectedDevices}
            value={props.deviceId}
            placeholder={props.translate('searchUnits')}
          />)}
        <Button
          disabled={props.TabsTimeMachine === "default"&&!props.showTimeLapse ? !((props.selectedDate||props.selectedDay) && props.deviceId) : !props.validatedReportForm}
          style={{ marginTop: 20 }}
          variant='contained'
          onClick={() => createReport(props.TabsTimeMachine)}
        >
          {props.translate('submit')}
        </Button>
      </form>
    </div>
  )

  if (viewScreen) {
    return (
      <Style>
        {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
        <aside className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('track') && <Scrollbar>{viewScreen}</Scrollbar>}
        </aside>
      </Style>
    )
  } else {
    return props.trackLoading ? <Loader /> : null
  }
  // }
}

export default Timemachine