import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { checkPrivileges, getDateTimeFormat } from '../../../Helpers'
import Tooltip from '../../../Components/common/Tooltip'
import DateRangePicker from '../../../Components/common/DateRangeHistoryPicker'
import Button from '../../../Components/common/Button'
import { withLocalize } from 'react-localize-redux'
import moment from 'moment'
import Scrollbar from 'react-scrollbars-custom'
import NotificationSelector from './../../../Components/Devices/NotificationSelector'
import NotificationTypeSelector from './../../../Components/Devices/NotificationTypeSelector'
// import SettingsIcon from '@mui/icons-material/Settings'
import SettingsIcon from '@mui/icons-material/FormatListBulleted';
import AddIcon from '@mui/icons-material/Add';
import FilterIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
import Stepper from './../../../Components/common/Stepper'
import { UserWiseEvents } from '../../../Components/Events/userEvents'
import Loader from '../../Loader'


const Events = (props) => {

  const { ...others } = props;
  return (<aside className='fms-sidebar sidebar-bg-layer fms-notification-inner'>
    <div style={{ padding: 13 }}>
      <h3
        style={{ display: 'flex', alignItems: 'center', margin: 0 }}
      >
        <span>{props.translate('Notifications')}</span>

        {checkPrivileges('notification') ? (
          <Tooltip
            title={props.translate('notificationFilter')}
          >
            <span style={{ marginLeft: 'auto' }}>
              <Button
                style={{ minWidth: 'inherit' }}
                color='inherit'
                onClick={props.toggleFilterForm}
              >
                {!props.showFilterForm ? <FilterIcon style={{ fontSize: 18 }} /> : <CloseIcon style={{ fontSize: 18 }} />}
              </Button></span>
          </Tooltip>
        ) : null}
        {checkPrivileges('notification') ? (
          <Tooltip
            title={props.translate('User Notifications/Create')}
          >
            <span><Button
              style={{ marginLeft: 6, minWidth: 'inherit' }}
              color='inherit'
              onClick={() => props.history.push('/notifications')}
            >
              <SettingsIcon style={{ fontSize: 18 }} />
            </Button></span>
          </Tooltip>
        ) : null}
        {checkPrivileges('notification') ? (
          <Tooltip
            title={props.translate('createUpdateNotification')}
          >
            <span><Button
              style={{ marginLeft: 6, minWidth: 'inherit' }}
              color='inherit'
              onClick={() => props.history.push('/notifications?mode=create')}
            >
              <AddIcon style={{ fontSize: 18 }} />
            </Button></span>
          </Tooltip>
        ) : null}
      </h3>
    </div>
    <div style={{ position: 'relative', height: '100%' }}>
      {props.userEvents && props.userEvents.loading ? <Loader defaultStyle /> : (props.showFilterForm ? <EventFrom {...others} /> : <UserWiseEvents {...props.userEvents} />)}
    </div>
  </aside>)
}

const EventFrom = (props) => {

  const [state, setState] = useState({
    selectedDate: '',
    notificationId: [],
    notificationType: [],
    isProcessCompleted: false,
    selectitem: {},
    type: 'event',
    from: '',
    to: '',
    steps: [{ id: 1, title: 'selectEventTimeControl' }, { id: 2, title: 'selectedTrackers' }],
    activeStep: 0,
    showNotification: false,
    showAllNotification: false
  })


  useEffect(() => {
    if (state.showAllNotification) {
      setState(prevState => ({
        ...prevState,
        notificationType: []
      }))
    }
  }, [state.showAllNotification])

  useEffect(() => {
    if (
      state.notificationType.length &&
      state.selectedDate &&
      state.selectitem.length
    ) {
      setState(prevState => ({
        ...prevState,
        validatedForm: true,
        showNotification: false,
        isEnable: false
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        validatedForm: false,
        showNotification: false,
        isEnable: false
      }))
    }
  }, [state.notificationType, state.selectedDate, state.selectitem])

  useEffect(() => {
    if (
      (state.notificationId.length ||
        state.notificationType.length) &&
      state.selectedDate &&
      state.selectitem.length
    ) {
      setState(prevState => ({
        ...prevState,
        validatedForm: true,
        showNotification: false,
        isEnable: false
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        validatedForm: false,
        showNotification: false,
        isEnable: false
      }))
    }
  }, [state.notificationId, state.notificationType, state.selectedDate, state.selectitem])

  useEffect(() => {
    if (state.isEnable === false,
      state.showNotification === true) {
      props.collapseSidebar()
      props.showNotification({
        isEnable: false,
        showNotification: true,
        selecteditemId: state.selectitem.id,
        to: state.to,
        from: state.from,
        notificationType: state.notificationType,
        notificationId: state.notificationId,
        validatedForm: state.validatedForm
      })
    }
  }, [state.isEnable, state.showNotification])

  useEffect(() => {
    const timezone = props.ServerSetting?.attributes?.timezone || props.logInUser?.attributes?.timezone || 'Asia/Dubai';
    moment.tz.setDefault(timezone);
  }, [props.ServerSetting, props.logInUser]);


  useEffect(() => {
    const shouldValidateForm = (
      (state.notificationType.length || state.notificationId.length || state.showAllNotification) &&
      state.selectedDate &&
      state.selectitem.length
    );

    setState(prevState => ({
      ...prevState,
      validatedForm: shouldValidateForm,
      showNotification: false,
      isEnable: false
    }));
  }, [state.notificationType, state.notificationId, state.showAllNotification, state.selectedDate, state.selectitem]);

  useEffect(() => {
    if (
      (state.notificationType.length ||
        state.notificationId.length ||
        state.showAllNotification) &&
      state.selectedDate &&
      state.selectitem.length
    ) {
      setState(prevState => ({
        ...prevState,
        validatedForm: true,
        showNotification: false,
        isEnable: false
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        validatedForm: false,
        showNotification: false,
        isEnable: false
      }));
    }
  }, [state.notificationType, state.notificationId, state.showAllNotification, state.selectedDate, state.selectitem]);


  // const handleChange = () => event => {
  //   const { target } = event
  //   let value = target.type === 'checkbox' ? target.checked : target.value
  //   setState(prevState =>
  //   ({
  //     ...prevState,
  //     validatedForm: value,
  //     showAllNotification: value,
  //     showNotification: false,
  //     isEnable: false
  //   })
  //   )
  // }


  const selectedNotification = (notificationType) => {
    setState(prevState => ({ ...prevState, notificationType }))
  }


  const handleChangeType = (type) => {
    setState(prevState => ({
      ...prevState,
      type,
      notificationId: [],
      notificationType: [],
      showAllNotification: false
    }))
  }

  const handleNotification = (notificationId) => {
    setState(prevState => ({ ...prevState, notificationId }))
  }

  const showNotification = () => {
    setState(prevState => ({
      ...prevState,
      isEnable: false,
      showNotification: true
    })
    )
  }


  // const getNotificationType = () => {
  //   let array = []
  //   if (props.notificationType.length) {
  //     props.notificationType.map((item) => {
  //       array.push({
  //         name: props.translate('notification.' + item.type),
  //         id: item.type
  //       })
  //       return null
  //     })
  //     return array
  //   } else {
  //     return array
  //   }
  // }

  const showDates = (e, date) => {
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    const timezone = props.ServerSetting?.attributes?.timezone || props.logInUser?.attributes?.timezone || 'Asia/Dubai';
    moment.tz.setDefault(timezone);

    const formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: adjustedEndDate.toISOString(),
      // to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    };

    const timeFormat = getDateTimeFormat();

    setState(prevState => ({
      ...prevState,
      selectedDate: moment(date.startDate).format(timeFormat) + ' - ' + moment(date.endDate).format(timeFormat),
      ...formatedDate
    }));
  };

 const goToNextStep = (step, type, index) => {
    validatedCurrentStep(step, type, index);
  }

 const goToBackStep = () => {
    const currentStep = state.activeStep;
    setState(prevState=>({...prevState, activeStep: currentStep - 1 }))
  }

 const validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let errorMsg = null;
    let validated = false;
    if (step === 1) {
      if (state.selectedDate) {
        if (state.notificationId.length || state.notificationType.length) {
          validated = true
        }
        else {
          errorMsg = <span className="text-warning">{props.translate('pleaseSelectNotification')}</span>
          validated = false
        }
      }
      else {
        errorMsg = <span className="text-warning">{props.translate('pleaseSelectFromTo')}</span>
        validated = false
      }
    }
    if (step === 2) {
      if (state.selectitem.length) {
        showNotification()
      }
      else {
        errorMsg = <span className="text-warning">{props.translate('pleaseSelectUnit')}</span>
      }
    }
    if (validated) {
      setState(prevState=>({...prevState, activeStep: index + 1 }))
    }
    state.steps.map(s => {
      if (s.id === step) {
        steps.push({ ...s, errorMsg })
      }
      else steps.push(s)
      return null
    })
    setState(prevState=>({...prevState, steps }))
  }

 const selectedDevices = (a) => {
    setState(prevState=>({
      ...prevState,
      selectitem: a
    }))
    props.selectedItem(a)
  }

 const setSelectedDevice = () => {
    let selectitem = props.devices2.find(
      item => parseInt(props.match.params.id) === item.id
    )
    setState(prevState=>({
      ...prevState,
      isProcessCompleted: true,
      selectitem: {
        id: selectitem.id,
        key: selectitem.id,
        name: selectitem.label,
        value: selectitem.id,
        label: selectitem.label
      }
    }))
  }


 const getStepContent = (id) => {

    switch (id) {
      case 1:
        return (<div>
          <div>
            <DateRangePicker
              fill
              reportsRanges
              selectedDate={state.selectedDate}
              label={
                props.translate('reportFrom') +
                ' ' +
                props.translate('reportTo')
              }
              onEvent={showDates}
            />
          </div>
          <div style={{ margin: '8px 0' }}>
            <div className='report-sidebar-switch'>
              <Button
                onClick={() => handleChangeType('event')}
                className={
                  state.type === 'event' ? '' : 'button-inactive'
                }
              >
                {props.translate('event')}
              </Button>
              <Button
                onClick={() => handleChangeType('type')}
                className={
                  state.type === 'type' ? '' : 'button-inactive'
                }
              >
                {props.translate('type')}
              </Button>
            </div>
            {state.type === 'type' ? (
              <NotificationTypeSelector
                onChange={selectedNotification}
                value={state.notificationType}
              />
            ) : null}
            {state.type === 'event' ? (
              <NotificationSelector
                onChange={handleNotification}
                value={state.notificationId}
                rows={7}
              />
            ) : null}
          </div>
        </div>)
      case 2:
        return (
          <div
            style={{
              position: 'relative',
              zIndex: 2
            }}
          >
            <DeviceSelector
              value={state.selectitem}
              onChange={selectedDevices}
              hideIcons
              rows={5}
            />
          </div>
        )
      default:
        return null
    }
  }


    if (
      props.devices2.length &&
      !state.isProcessCompleted &&
      props.match.params.id
    ) {
      setSelectedDevice()
    }
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        <>
          <Stepper
            translate={props.translate}
            activeStep={state.activeStep}
            reportType={state.selectitem.length}
            getStepContent={getStepContent}
            steps={state.steps}
            handleNext={goToNextStep}
            handleBack={goToBackStep}
            themecolors={props.themecolors}
          />
        </>
      </form>
    );

    return (
      <>
        {checkPrivileges('device') && (
          <Scrollbar>
            {checkPrivileges('device') ? (
              <div>
                {viewScreen}
                {/* <Button
                    disabled={!this.state.validatedForm}
                    variant='contained'
                    onClick={this.showNotification}
                  >
                    {this.props.translate('reportShow')}
                  </Button> */}
              </div>
            ) : null}
          </Scrollbar>
        )}
      </>
    )
  }

const mapStateToProps = (state) => {
  return {
    devices: state.devices.data,
    devices2: state.devices2,
    groups: state.groups,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    notificationType: state.notificationType
  }
}

export default connect(mapStateToProps)(withLocalize(Events))
