import React from 'react';
import { Dustbin } from './Dustbin';
import { Box } from './Box';
import { ItemTypes } from './ItemTypes';
import Grid from '@mui/material/Grid';
import isEqual from 'react-fast-compare';
import ReactHtmlParser from 'react-html-parser';
import Button from '../../Components/common/Button';
import AddField from './AddField';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Tooltip from '@mui/material/Tooltip';
import { Translate } from 'react-localize-redux';

export class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accept: ItemTypes.CUSTOM,
            lastDroppedItem: [],
            boxes: [],
            custom: [],
            text: "",
            operators: [
                { name: '+', type: ItemTypes.OPERATOR },
                { name: '-', type: ItemTypes.OPERATOR },
                { name: '*', type: ItemTypes.OPERATOR },
                { name: '/', type: ItemTypes.OPERATOR },
                { name: '%', type: ItemTypes.OPERATOR },
                { name: '>', type: ItemTypes.OPERATOR },
                { name: '<', type: ItemTypes.OPERATOR },
                { name: '!', type: ItemTypes.OPERATOR },
                { name: ':', type: ItemTypes.OPERATOR },
                { name: '?', type: ItemTypes.OPERATOR },
                { name: '(', type: ItemTypes.OPERATOR },
                { name: ')', type: ItemTypes.OPERATOR },
                { name: '++', type: ItemTypes.OPERATOR },
                { name: '!=', type: ItemTypes.OPERATOR },
                { name: '>=', type: ItemTypes.OPERATOR },
                { name: '<=', type: ItemTypes.OPERATOR },
                { name: '--', type: ItemTypes.OPERATOR },
                { name: '==', type: ItemTypes.OPERATOR },
                { name: '===', type: ItemTypes.OPERATOR },
                { name: '||', type: ItemTypes.OPERATOR },
                { name: '&&', type: ItemTypes.OPERATOR },
                { name: '+=', type: ItemTypes.OPERATOR },
                { name: '-=', type: ItemTypes.OPERATOR },
                { name: '*=', type: ItemTypes.OPERATOR },
                { name: '/=', type: ItemTypes.OPERATOR },
                { name: '%=', type: ItemTypes.OPERATOR },
            ],
        };
    }

    componentDidMount() {
        this.initializeStateFromProps(this.props);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.selctedDeviceAttributes, this.props.selctedDeviceAttributes)) {
            this.setState({
                boxes: this.props.selctedDeviceAttributes.map(e => ({ name: e, type: ItemTypes.VARIABLE })),
            }, () => {
                this.props.playResult(this.state.lastDroppedItem);
            });
        }

        if (!isEqual(prevProps.form, this.props.form)) {
            this.initializeStateFromProps(this.props);
        }
    }

    initializeStateFromProps = (props) => {
        const { form, selctedDeviceAttributes } = props;
        if (form && form.expression) {
            const list = form.expression.split(' ');
            const operators = this.state.operators.map(e => e.name);
            const custom = list.filter(e => e && !operators.includes(e));

            const lastDroppedItem = list.map(e => ({
                name: e,
                type: operators.includes(e) ? ItemTypes.OPERATOR : ItemTypes.CUSTOM,
            })).filter(item => item.name);

            if (!isEqual(this.state.custom, custom) || !isEqual(this.state.lastDroppedItem, lastDroppedItem)) {
                this.setState({
                    custom: custom.map(e => ({ name: e, type: ItemTypes.CUSTOM })),
                    lastDroppedItem,
                }, () => {
                    this.props.playResult(this.state.lastDroppedItem);
                });
            }
        }

        if (!isEqual(this.state.boxes, selctedDeviceAttributes)) {
            this.setState({
                boxes: selctedDeviceAttributes && selctedDeviceAttributes.length
                    ? selctedDeviceAttributes.map(e => ({ name: e, type: ItemTypes.VARIABLE }))
                    : [],
            });
        }
    }

    handleDrop = (item) => {
        this.setState(prevState => ({
            lastDroppedItem: [...prevState.lastDroppedItem, item],
        }), () => {
            this.props.playResult(this.state.lastDroppedItem);
        });
    }

    reverseLastAction = () => {
        this.setState(prevState => {
            const lastDroppedItem = [...prevState.lastDroppedItem];
            lastDroppedItem.pop();
            return { lastDroppedItem };
        }, () => {
            this.props.playResult(this.state.lastDroppedItem);
        });
    }

    changeText = (event) => {
        this.setState({ text: event.target.value });
    }

    addCustomItem = (event) => {
        event.preventDefault();
        if (this.state.text) {
            this.setState(prevState => ({
                custom: [...prevState.custom, { name: prevState.text, type: ItemTypes.CUSTOM }],
                text: '',
            }));
        }
    }

    render() {
        const { accept, lastDroppedItem, boxes, custom, operators, text } = this.state;
        const { deviceSelector, themecolors, result, form } = this.props;
        console.warn("lastDroppedItem",lastDroppedItem)

        return (
            <>
                <Grid container style={{ alignItems: 'center' }} spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        {deviceSelector}
                    </Grid>
                </Grid>
                <div
                    className="dnd-ebuilder"
                    style={{ background: themecolors?.themeLightColor, color: themecolors?.themeLightInverse }}
                >
                    <div className="dnd-placeholder" style={{ overflow: 'hidden', clear: 'both' }}>
                        {lastDroppedItem.length > 0 && (
                            <Tooltip
                                title="Undo Last action"
                                classes={{
                                    popper: 'menu-popper',
                                    tooltip: 'menu-popper-tooltip',
                                }}
                            >
                                <Button className="action-rollback-btn" onClick={this.reverseLastAction}>
                                    <BackspaceIcon />
                                </Button>
                            </Tooltip>
                        )}

                        {accept && (
                            <Dustbin
                                form={form}
                                accept={accept === ItemTypes.OPERATOR ? [ItemTypes.OPERATOR, ItemTypes.CUSTOM]
                                    : accept === ItemTypes.VARIABLE ? [ItemTypes.VARIABLE, ItemTypes.CUSTOM]
                                    : [ItemTypes.OPERATOR, ItemTypes.VARIABLE, ItemTypes.CUSTOM]}
                                lastDroppedItem={lastDroppedItem}
                                onDrop={this.handleDrop}
                            />
                        )}

                        {result && <p className="text-warning">{ReactHtmlParser(result)}</p>}
                    </div>

                    <div style={{ overflow: 'hidden', clear: 'both' }}>
                        <Grid container>
                            <Grid item sm={12}>
                                {boxes.length > 0 && <h4>Variables</h4>}
                                {boxes.map(({ name, type }, index) => (
                                    <Box themecolors={themecolors} name={name} type={type} key={index} />
                                ))}
                            </Grid>
                            <Grid item sm={12}>
                                <h4><Translate id="operator" /></h4>
                                {operators.map(({ name, type }, index) => (
                                    <Box themecolors={themecolors} name={name} type={type} key={index} />
                                ))}
                            </Grid>
                            <Grid item sm={12}>
                                {custom.length > 0 && <h4><Translate id="reportCustom" /></h4>}
                                {custom.map(({ name, type }, index) => (
                                    <Box themecolors={themecolors} name={name} type={type} key={index} />
                                ))}
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <Grid container>
                    <Grid item sm={12} md={4}>
                        <AddField onSubmit={this.addCustomItem} onChange={this.changeText} value={text} fullWidth themecolors={this.props.themecolors}/>
                    </Grid>
                </Grid>
            </>
        );
    }
}
