import React, { useState,useEffect } from 'react'
import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
import { Grid } from '@mui/material';
import Loader from '../../Layout/Loader';
import VehicleSelector from '../Devices/VehicleSelector'
import AreaSelector from './AreaSelector';
import Scrollbar from 'react-scrollbars-custom'
import MaintenanceSelectors from './MaintenanceSelectors';

const statusTypes = [
  {
    key: 'completed',
    name: 'Completed',
  },
  {
    key: 'required',
    name: 'Due',
  },
  {
    key: 'expired',
    name: 'OverDue',
  },
  {
    key: 'scheduled',
    name: 'Scheduled',
  },
]

let allMaintaince= '&status=scheduled&status=expired&status=required&status=completed'

  const MaintenanceFilter = (props) => {

    const [state,setState] = useState ({
      openMaintenanceModal:false,
      from: '',
      to: '',
      selectedDate: '',
      vehicleId:[],
      maintenanceId:statusTypes.map(type => type.key),
      areaId:[],
      loader:false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId:[],
      selectedMaintenanceId:allMaintaince,
      selectedAreaId:[],
      steps: [{id: 1, title: 'selectServiceTimeControl'}, {id: 2, title: 'selectVehicles'},{id:3, title:'selectAreaIds'}],
      activeStep: 0,
      reportType:0,
      isSubmitBtn:false
    })


 const showDates =(e, date)=> {
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = 'HH:mm'

    if (props.logInUser && props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }

    setState(
      prevState=>(
      {
        ...prevState,
        selectedDate:
          moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate
      }))
  }

  const handleChange = (name, type) => {
    setState((prevState) => {
      const updatedState = { ...prevState };
      if (type === 'area') {
        updatedState.areaId = name;
      } else if (type === 'maintenance') {
        updatedState.maintenanceId = name;
      } else {
        updatedState.vehicleId = name;
      }
      return updatedState;
    });
  };

  useEffect(() => {
    const selectedAreaId = state.areaId.map((value) => (value ? `&areaId=${value}` : '')).join('');
    setState((prevState) => ({ ...prevState, selectedAreaId }));
  }, [state.areaId]);

  useEffect(() => {
    const selectedMaintenanceId = state.maintenanceId.map((value) => (value ? `&status=${value}` : '')).join('');
    setState((prevState) => ({ ...prevState, selectedMaintenanceId }));
  }, [state.maintenanceId]);

  useEffect(() => {
    const selectedvehicleId = state.vehicleId.map((value) => (value ? `&vehicleId=${value}` : '')).join('');
    setState((prevState) => ({ ...prevState, selectedvehicleId }));
  }, [state.vehicleId]);

  const goToNextStep = (step, type, index) => {
    if (step === 3) {
      setState(prevState => ({
      ...prevState, openMaintenanceModal: false 
    }))
    console.warn("State",state)
        props.formSubmit(state);

    } else {
      validatedCurrentStep(step, type, index);
    }
  };

 const goToBackStep = () => {
    const currentStep = state.activeStep;
      setState(prevState=>({...prevState,activeStep: currentStep-1}))
  }

  const validatedCurrentStep = (step, type, index) => {
    let validated = false;
    let errorMsg = null;
    const updatedSteps = [...state.steps]; 

    if (step === 1) {
      if (state.to && state.from && state.selectedMaintenanceId) {
        validated = true;
        errorMsg = null;
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">
            Please select &apos;Date and Maintenance Status&apos; from above
          </span>
        );
      }
    } else if (step === 2) {
      validated = true;
    }

    if (validated) {
      setState(prevState => ({
        ...prevState,
        activeStep: index + 1,
        isSubmitBtn: prevState.activeStep === 1 && index + 1 === 2 ? true : prevState.isSubmitBtn,
      }));
    }

    const updatedStepsWithErrors = updatedSteps.map(s =>
      s.id === step ? { ...s, errorMsg } : s
    );

    setState(prevState => ({
      ...prevState,
      steps: updatedStepsWithErrors,
    }));
  };

 const getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group sidebar-bg-layer'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            error={ props.timeError || false}
            selectedDate={state.selectedDate}
            label={
              props.translate('reportFrom') +
              ' ' +
              props.translate('reportTo')
            }
            onEvent={showDates}
          />
        </Grid>
        <Grid item  xs={12}>
          <MaintenanceSelectors
            api='maintenance'
            fill
            hideIcons
            isClearable
            placeholder={props.translate('searchMaintenance')}
            onChange={handleChange}
            value={state?.maintenanceId||[]}
            maintenanceTypes={statusTypes}
            allPreSelected={(state.maintenanceId?.length||4)===statusTypes?.length}
            sidebar={props.sidebar?true:false}
          />    
        </Grid>
      </Grid>)
    case 2: 
    return (<Grid container className='form-group'>
      <Grid item  xs={12}>
          <VehicleSelector
            api='vehicle'
            fill
            hideIcons
            isClearable
            placeholder={props.translate('searchVehicle')}
            onChange={handleChange}
            value={state?.vehicleId||[]}
          />
      </Grid>
    </Grid>)
    case 3: 
      return (<Grid container className='form-group'>
       <Grid item  xs={12}>
            <AreaSelector
                api='area'
                fill
                hideIcons
                isClearable
                placeholder={props.translate('searchArea')}
                onChange={handleChange}
                value={state?.areaId||[]}
              /></Grid>
    </Grid>)
    default:
        return null
    }
  }
  

    const viewScreen = (
      
        <form style={{ padding: "16px 0 16px 10px" }}>
        {<Grid container className='form-group'>
          <Grid item  xs={props.gridSize?props.gridSize:12}>
            <Stepper 
                translate={props.translate}
                activeStep={state.activeStep} 
                reportType={state.reportType} 
                getStepContent={getStepContent} 
                steps={state.steps}
                handleNext={goToNextStep} 
                handleBack={goToBackStep} 
                isSubmitBtn={state.isSubmitBtn}  
                themecolors={props.themecolors}
                />
          </Grid>
            
          </Grid>
        }
      </form>
    )

    return (
      <div className='geofence-sidebar-list'>
        {!props.loader?(
        <Scrollbar>
          {viewScreen}
        </Scrollbar>):<Loader/>
        }
      </div>
  )
  }

export default MaintenanceFilter;