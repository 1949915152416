import React, { useState, useEffect } from 'react'
// import axios from 'axios';
// import Tooltip from '@material-ui/core/Tooltip'
// import MenuItem from '../common/MenuItem'
// import Checkbox from '../common/Checkbox'
// import { errorHandler } from '../../Helpers';
// import CustomDialog from './Dialog';
import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
// import TextField from './TextField';
import { Grid } from '@material-ui/core';
import Loader from '../../Layout/Loader';
// import Button from './Button';
import DeviceSelector from '../Devices/DeviceSelector'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Menu from '../Menu/CommonMenu'
import CitySelector from './CitySelector';
import Scrollbar from 'react-scrollbars-custom'
import WorkFlowStatusFilter from './WorkFlowStatusFilter';
// import WorkFlow from './Components/WorkFlow'

const statusTypes = [
  {
    key: 'completed',
    name: 'Completed',
  },
  {
    key: 'inProgress',
    name: 'In Progress',
  },
  {
    key: 'cancelled',
    name: 'Cancelled',
  },
  {
    key: 'pending',
    name: 'Scheduled',
  },
]
let allStatuses = '&status=pending&status=completed&status=inprogress&status=cancelled'
const MaintenanceFilter = (props) => {
  const [state, setState] = useState({
    openFilterModal: false,
    from: '',
    to: '',
    selectedDate: '',
    vehicleId: [],
    cityId: [],
    statusId:[],
    loader: false,
    countChecked: 0,
    anchorEl: null,
    selectedvehicleId: [],
    selectedStatusId: allStatuses,
    selectedCityId: '0',
    steps: [{ id: 1, title: 'selectServiceTimeControl' }, { id: 2, title: 'selectVehicles' }, { id: 3, title: 'tripType' }],
    activeStep: 0,
    reportType: 0,
    isSubmitBtn: false,
    isValidSubmit: true
  })

  // const onCloseDueMaintenanceModel = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     openFilterModal: false,
  //     to: '',
  //     from: '',
  //     isSubmitBtn: false,
  //     selectedDate: '',
  //     // from: '',
  //     // to: '',
  //     vehicleId: [],
  //     cityId: [],
  //     countChecked: 0,
  //     anchorEl: null,
  //     activeStep: 0,
  //   }))
  // }



  const showDates = (e, date) => {
    // const duration = moment.duration((moment(state.to)).diff(state.from));
    // const hours = duration.asHours();
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = 'HH:mm'

    if (props.logInUser && props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }
    setState((prevState) => ({
      ...prevState,
      selectedDate:
        moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
        ' - ' +
        moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
      ...formatedDate
    }))
  }

  const handleChange = (name, e) => {
    if (e === 'city') {
      const updatedName = Array.isArray(name) ? name : [...state.cityId, name];
      setState((prevState) => ({
        ...prevState,
        cityId: updatedName
      }))

      let filter = '';
      Object.entries(updatedName).forEach(([key, value]) => {// eslint-disable-line no-unused-vars
        if (value) {
          filter += '&interCity=' + value
          console.error("fileter", filter)
        }
      });
      setState((prevState) => ({
        ...prevState,
        selectedCityId: name?.length === 2 ? "0" : name[0]
      }))
    }
    else if (e === 'status') {
      const updatedStatus = Array.isArray(name) ? name : [...state.statusId, name];
      setState((prevState) => ({
        ...prevState,
        statusId: updatedStatus
      }))
      let filter = '';
      Object.entries(updatedStatus).forEach(([key, value]) => {// eslint-disable-line no-unused-vars
        if (value) {
          filter += '&status=' + value
        }
      });

      setState((prevState) => ({
        ...prevState,
        selectedStatusId: filter
      }))
    }
    else {
      const updatedVehicleId = Array.isArray(name) ? name : [...state.vehicleId, name];
      setState((prevState) => ({
        ...prevState,
        vehicleId: updatedVehicleId
      }))
      let filter = '';
      Object.entries(updatedVehicleId).forEach(([key, value]) => {// eslint-disable-line no-unused-vars
        if (value) {
          filter += '&deviceId=' + value
        }
      });

      setState((prevState) => ({
        ...prevState,
        selectedvehicleId: filter
      }))
    }
  }

  const checkReqFields = () => {
    const { from, to, cityId, selectedvehicleId } = state
    if (from && to && selectedvehicleId && cityId.length) {
      setState((prevState) => ({
        ...prevState,
        isValidSubmit: false
      }))
    }
    else {
      setState((prevState) => ({
        ...prevState,
        isValidSubmit: true
      }))
    }
  }
  useEffect(() => {
    checkReqFields()
  }, [state.form, state.to, state.cityId, state.selectedvehicleId])

  const goToNextStep = (step, type, index) => {
    if (step === 3) {
      setState((prevState) => ({
        ...prevState,
        openFilterModal: false,
      }))
      props.formSubmit(state)
    }
    else {
      validatedCurrentStep(step, type, index);
    }
  }
  const goToBackStep = () => {
    const currentStep = state.activeStep;
    setState((prevState) => ({
      ...prevState,
      activeStep: currentStep - 1
    }))
  }

  const validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;

    if (step === 1) {
      if (state.to && state.from && state.selectedStatusId) {
        validated = true;
        errorMsg = null;
      } else {
        validated = false;
        errorMsg = (
          <span className="text-warning">
            Please select Date and &apos;Status&apos; from above
          </span>
        );
      }
    } else if (step === 2) {
      validated = true;
    }

    if (validated === true) {
      setState((prevState) => ({
        ...prevState,
        activeStep: index + 1,
        isSubmitBtn: prevState.activeStep === 2 ? true : prevState.isSubmitBtn,
      }));
    }

    state.steps.map((s) => {
      if (s.id === step) {
        steps.push({ ...s, errorMsg });
      } else steps.push(s);
      return null;
    });

    setState((prevState) => ({
      ...prevState,
      steps,
    }));
  };

  useEffect(() => {
    if (state.activeStep === 2) {
      setState((prevState) => ({
        ...prevState,
        isSubmitBtn: true,
      }));
    }
  }, [state.activeStep]);

  const submitDownload = (a, type) => {
    // let Accept;
    const { from, to, selectedCityId, selectedvehicleId, selectedStatusId } = state
    let accept = 'application/pdf'
    // let fileName = 'workflows'
    if (type === 'downloadPdf') {
      accept = 'application/pdf'
    }
    else {
      accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    let header = {
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: accept
      })
    }
    fetch(`/api/workflows/export?sample=false${selectedStatusId}${selectedvehicleId}&page=1&limit=50&from=${from}&to=${to}&interCity=${selectedCityId}`, { ...header })
      .then(response1 => {
        if (response1.status === 200) {
          response1.blob().then(response => {
            if (type === 'downloadPdf') {
              saveData(
                response,
                'workFlows.pdf'
              )
            }
            else {
              saveData(
                response,
                'workFlows.xlsx'
              )
            }
          })
        } else {
          throw response1
        }
      })
  }

  const saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        setState((prevState) => ({
          ...prevState,
          loading: false
        }))
      }, 0)
    }
    setState((prevState) => ({
      ...prevState,
      loading: false
    }))
  }
  const getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group sidebar-bg-layer'>
          <Grid item xs={12}>
            <DateRangePicker
              fill
              error={props.timeError || false}
              selectedDate={state.selectedDate}
              label={
                props.translate('reportFrom') +
                ' ' +
                props.translate('reportTo')
              }
              onEvent={showDates}
            />
          </Grid>
          <Grid item xs={12}>
            <WorkFlowStatusFilter
              api='status'
              fill
              hideIcons
              isClearable
              placeholder={props.translate('searchStatus')}
              onChange={handleChange}
              value={state?.statusId || []}
              statusTypes={statusTypes}
              translate={props.translate}
              allPreSelected={(state.statusId?.length||4)===statusTypes?.length}
            />
          </Grid>
        </Grid>)
      case 2:
        return (<Grid container className='form-group'>
          <Grid item xs={12}>
            <DeviceSelector
              api='vehicle'
              fill
              hideIcons
              isClearable
              placeholder={props.translate('searchVehicle')}
              onChange={handleChange}
              value={state?.vehicleId || []}
              workFlow
            />
          </Grid>
        </Grid>)
      case 3:
        return (<Grid container className='form-group'>
          <Grid item xs={12}>
            <CitySelector
              api='city'
              fill
              hideIcons
              isClearable
              placeholder={props.translate('citySelection')}
              onChange={handleChange}
              value={state?.cityId || []}
            /></Grid>
        </Grid>)
      default:
        return null
    }
  }

  const viewScreen = (

    <form style={{ padding: "16px 0 16px 10px" }}>
      {<>
        <Stepper
          translate={props.translate}
          activeStep={state.activeStep}
          reportType={state.reportType}
          getStepContent={getStepContent}
          steps={state.steps}
          handleNext={goToNextStep}
          handleBack={goToBackStep}
          isSubmitBtn={state.isSubmitBtn}
          downloadWorkFlow
          submitDownload={submitDownload}
          isValidSubmit={state.isValidSubmit}
          themecolors={props.themecolors}
          workFlow
        />
      </>
      }
    </form>
  )

  return (
    <div className='geofence-sidebar-list'>
      {!props.loader ? (
        <Scrollbar>
          {viewScreen}
        </Scrollbar>) : <Loader />
      }
    </div>
  )
}
export default MaintenanceFilter

