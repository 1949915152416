import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Style from 'style-it'
import { useSelector } from 'react-redux';


const CustomTextField = (props) => {

	const themecolors = useSelector(state => state.themeColors)

    const { classes } = props

    const p = { ...props }

    delete p.dispatch

    return (
      <Style>
        {`
		.theme-label {
			margin-top: -10px;
			font-size: 12px;
			color: ${themecolors&&themecolors.textColor}
		}

		.text-field-helper-text {
			color: inherit;
		}
		
		.theme-label-shrink {
			margin-top: 0
		}
        .theme-label-focused {
          margin-top: 0;
          color: ${themecolors?.textColor} !important
        }
		.theme-textarea {
			font-size: 14px;
			padding: 0
		}
		.theme-label-error {
			color: ${themecolors&&themecolors.error}
		}
		.theme-notchedOutline-multiline,
		.theme-notchedOutline {
			border-color:  ${themecolors&&themecolors&&themecolors.themeLightColor} !important;
			border-radius: 4px;
			padding: 0;
		}
		.theme-input-error .theme-notchedOutline {
			border-color:  ${themecolors&&themecolors.error} !important;
		}
		.theme-cssFocused .theme-notchedOutline {
			border-color:  ${themecolors&&themecolors&&themecolors.themeLightColor} !important;
		}
		.theme-input-select {
			border-color: ${themecolors&&themecolors&&themecolors.themeLightColor};
			color: ${themecolors&&themecolors.textColor};
			padding: 0;
			display: flex;
            height: auto
		}
		.theme-textarea,
		.theme-input {
			border-color: ${themecolors&&themecolors&&themecolors.themeLightColor};
			color: ${themecolors&&themecolors.textColor};
			font-size: 12px;
			padding: 5px 14px;
			min-height: 18px
		}
		.menu-list-icon {
			color: ${themecolors&&themecolors.textColor};
		}
		.custom-paper {
			background-color: ${themecolors&&themecolors&&themecolors.themeLightColor};
		}
	`}
        <div style={{ display: 'flex', flex: 1 }}>
          <TextField
            margin='dense'
            {...p}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...props.InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: props.multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                multiline: 'theme-notchedOutline-multiline',
                notchedOutline: 'theme-notchedOutline'
              },
            }}
            FormHelperTextProps={{
              classes: { root: 'text-field-helper-text' }
            }}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: 'custom-menu-paper'
                }
              },
              classes: {
                icon: 'menu-list-icon'
              }
            }}
            InputLabelProps={{
              ...props.InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink',
                error: 'theme-label-error'
              }
            }}
          />
        </div>
      </Style>
    )
  }


CustomTextField.propTypes = {
  classes: PropTypes.object
}

 export default CustomTextField;